import { ref, watch, computed, reactive, onMounted } from "vue";
import { defineStore, acceptHMRUpdate, storeToRefs } from "pinia";
import ChatGroup from "@/classes/ChatGroup";
import ChatMessage from "@/classes/ChatMessage";
import { make } from "@/utils/request";
import { replaceItemInStore } from "@/utils/helpers";

import { useCompanies } from "./companies";
import { useCompanyEmployees } from "./companies-employees";
import { useToasts } from "./toasts";
import { useNotifications } from "./notifications";
import { message } from "@/utils/validation/forms/message";

const sortByGroupName = (a, b) => {
  const lowerNameA = a.name.toLowerCase();
  const lowerNameB = b.name.toLowerCase();

  if (lowerNameA < lowerNameB) {
    return -1;
  }

  return lowerNameA > lowerNameB ? 1 : 0;
};

export const useChat = defineStore("chat", () => {
  const companyStore = useCompanies();
  const employeesStore = useCompanyEmployees();
  const toastsStore = useToasts();
  const notificationsStore = useNotifications();

  const { selectedCompany } = storeToRefs(companyStore);
  // const { selectedEmployee } = storeToRefs(employeesStore);
  const selectedEmployee = computed(() => {
    return companyStore?.company?.employee;
  });

  const { addToast } = toastsStore;
  const { addNotification } = notificationsStore;

  const selectedGroupId = ref(null);

  let chatsByGroupId = reactive({});

  const selectedChatGroup = computed(() => {
    if (Object.keys(chatsByGroupId).length === 0 || !chatsByGroupId[selectedGroupId.value]) {
      return null;
    }

    return chatsByGroupId[selectedGroupId.value];
  });

  const allChatGroups = computed(() => {
    return Object.values(chatsByGroupId).sort(sortByGroupName);
  });

  const publicChatGroups = computed(() => {
    return Object.values(chatsByGroupId)
      .filter((chatGroup) => !chatGroup.is_private)
      .sort(sortByGroupName);
  });

  const privateChatGroups = computed(() => {
    return Object.values(chatsByGroupId)
      .filter((chatGroup) => chatGroup.is_private)
      .sort(sortByGroupName);
  });

  const unreadChatMessages = computed(() => {
    return Object.values(chatsByGroupId)
      .map((chatGroup) => chatGroup.unread_count || 0)
      .filter(Boolean)
      .reduce((sum, unreadCount) => {
        return sum + unreadCount;
      }, 0);
  });

  const fetchChatGroups = async (companyId) => {
    const chatGroups = await make({
      name: "getChatGroups",
      data: {
        company_id: companyId || selectedCompany.value.id,
      },
    });

    for (let chatGroup of chatGroups) {
      if (!chatsByGroupId[chatGroup.id]) {
        chatsByGroupId[chatGroup.id] = new ChatGroup(chatGroup);
      } else if (chatsByGroupId[chatGroup.id]) {
        chatsByGroupId[chatGroup.id] = new ChatGroup({
          ...chatGroup,
          messages: chatsByGroupId[chatGroup.id].messages,
        });
      }
    }

    return chatGroups;
  };

  const fetchChatGroupMessages = async (groupId, lastMessage) => {
    let res = await make({
      name: "getChatGroupMessages",
      params: {
        group_id: groupId,
        company_id: selectedCompany.value.id,
      },
      data: {
        last: lastMessage,
      },
    });

    if (!chatsByGroupId[groupId]) {
      // TODO: eventually show some sort of feedback to user
      // here if the chat group doesn't exist. I don't think
      // it should be possible to save a message without first
      // creating the group though
      return null;
    }

    const currentChatGroup = chatsByGroupId[groupId];
    const previousMessages = currentChatGroup.messages || [];
    const newChatMessages = res.map((msg) => new ChatMessage(msg));
    const msgIds = new Set(previousMessages.map((d) => d.id));
    let newMessages = [];
    if (msgIds?.size > 0) {
      newMessages = newChatMessages.filter((newMsg) => {
        return !msgIds.has(newMsg.id);
      });
    } else {
      newMessages = newChatMessages;
    }
    const allMessages = previousMessages.concat(newMessages);

    // update the chat group
    if (chatsByGroupId[currentChatGroup.id]) {
      chatsByGroupId[currentChatGroup.id] = new ChatGroup({
        ...currentChatGroup,
        messages: allMessages,
      });
    }

    return chatsByGroupId[currentChatGroup.id];
  };

  const markMessagesAsRead = async (groupId) => {
    if (!selectedCompany.value?.id) {
      return null;
    }

    const successMessages = await make({
      name: "markChatGroupMessagesRead",
      params: {
        group_id: groupId,
        company_id: selectedCompany.value.id,
      },
    });

    return successMessages;
  };

  const createChatGroupMessage = async (messageDetails) => {
    const message = await make({
      name: "createChatGroupMessage",
      params: {
        group_id: messageDetails.group_id,
        company_id: selectedCompany.value.id,
      },
      data: messageDetails,
    });

    if (!chatsByGroupId[messageDetails.group_id]) {
      // TODO: eventually show some sort of feedback to user
      // here if the chat group doesn't exist. I don't think
      // it should be possible to save a message without first
      // creating the group though
      return null;
    }

    // Just let the Websocket update the list with the new message
    const currentChatGroup = chatsByGroupId[messageDetails.group_id];
    const newMessage = new ChatMessage(message);
    const index = currentChatGroup.messages.findIndex((existingItem) => {
      return existingItem.id === newMessage.id;
    });

    if (index > -1) {
      console.warn("meesage id already in store, ");
    } else {
      currentChatGroup.messages = [newMessage, ...currentChatGroup.messages];
    }

    return newMessage;
  };

  const updateChatGroupMessage = async (messageDetails) => {
    const message = await make({
      name: "updateChatGroupMessage",
      params: {
        group_id: messageDetails.chat_group_id,
        message_id: messageDetails.id,
        company_id: selectedCompany.value.id,
      },
      data: messageDetails,
    });

    if (!chatsByGroupId[messageDetails.chat_group_id]) {
      // TODO: eventually show some sort of feedback to user
      // here if the chat group doesn't exist. I don't think
      // it should be possible to save a message without first
      // creating the group though
      return null;
    }

    const currentChatGroup = chatsByGroupId[messageDetails.chat_group_id];
    const newMessage = new ChatMessage(message);

    replaceItemInStore(newMessage, currentChatGroup.messages);

    return newMessage;
  };

  const deleteChatGroupMessage = async (groupId, messageId) => {
    const response = await make({
      name: "deleteChatGroupMessage",
      params: {
        group_id: groupId,
        message_id: messageId,
        company_id: selectedCompany.value.id,
      },
    });

    const currentChatGroup = chatsByGroupId[groupId];
    currentChatGroup.messages = currentChatGroup.messages.filter(
      (message) => message.id !== messageId
    );

    return response;
  };

  const reactToChatGroupMessage = async (messageDetails) => {
    const message = await make({
      name: "toggleChatGroupMessageReaction",
      params: {
        group_id: messageDetails.group_id,
        message_id: messageDetails.message_id,
        company_id: selectedCompany.value.id,
      },
      data: messageDetails,
    });

    if (!chatsByGroupId[messageDetails.group_id]) {
      // TODO: eventually show some sort of feedback to user
      // here if the chat group doesn't exist. I don't think
      // it should be possible to save a message without first
      // creating the group though
      return null;
    }

    const currentChatGroup = chatsByGroupId[messageDetails.group_id];
    const newMessage = new ChatMessage(message);

    replaceItemInStore(newMessage, currentChatGroup.messages);

    return newMessage;
  };

  const createChatGroup = async (groupDetails) => {
    const queryName = groupDetails.is_private ? "createPrivateChatGroup" : "createChatGroup";

    const group = await make({
      name: queryName,
      data: {
        company_id: selectedCompany.value.id,
        ...groupDetails,
      },
    });

    const newGroup = new ChatGroup(group);
    chatsByGroupId[newGroup.id] = newGroup;
    selectedGroupId.value = newGroup.id;

    return newGroup;
  };

  const updateChatGroup = async (groupDetails) => {
    const group = await make({
      name: "updateChatGroup",
      params: {
        group_id: groupDetails.id,
      },
      data: {
        company_id: selectedCompany.value.id,
        ...groupDetails,
      },
    });

    const updatedGroup = new ChatGroup(group);
    chatsByGroupId[updatedGroup.id] = {
      ...chatsByGroupId[updatedGroup.id],
      ...updatedGroup,
    };
  };

  const deleteChatGroup = async (groupDetails) => {
    const group = await make({
      name: "deleteChatGroup",
      params: {
        group_id: groupDetails.id,
      },
    });
    delete chatsByGroupId[groupDetails.id];
  };

  const sendUpdateNotifications = async ({ group, latest_message, type, initiator }) => {
    await fetchChatGroups(selectedCompany.value.id); // fetch to see if new chat groups were created or deleted
    const chatGroupExists = group?.id && chatsByGroupId[group.id];

    if (!chatGroupExists) {
      return null;
    }

    const incomingMessage = new ChatMessage(latest_message[0]);
    const currentChatGroup = chatsByGroupId[group.id];
    const isCreator = selectedEmployee.value.id === incomingMessage.employee_id;

    if (type === "update" && incomingMessage.deleted_at) {
      currentChatGroup.messages = currentChatGroup.messages.filter(
        (message) => message.id !== incomingMessage.id
      );

      return null;
    }

    // Notify the user of a new message
    if (type === "new" && !isCreator) {
      const title = `New message in ${group.name}.`;
      const notificationContent = incomingMessage.image
        ? `${incomingMessage.employee?.name} shared an image.`
        : `"${incomingMessage.content}" - ${incomingMessage.employee.name}`;

      addToast({
        title,
        message: notificationContent,
      });

      addNotification({
        title,
        content: notificationContent,
        created_at: new Date(),
        type: "CHAT",
        type_id: group.id,
      });
    }
    // Add the new message to state no matter who created it
    const isNewMessage =
      currentChatGroup.messages.findIndex((existingItem) => {
        return existingItem.id === incomingMessage.id;
      }) === -1;

    console.log("is new message push on to messages stack ");
    if (type === "new" && isNewMessage) {
      // Item wasn't created by user on web, need items created from user from mobile and other folks (web and mobile)
      currentChatGroup.messages = [incomingMessage, ...currentChatGroup.messages];
    }

    // Update the message, don't notify user
    if (type === "update") {
      replaceItemInStore(incomingMessage, currentChatGroup.messages);
    }

    // Notify the user of a reaction to their message
    if (type === "reaction" && isCreator) {
      const title = "Reaction to your message.";
      const notificationContent = `${initiator?.name} reacted to your message in ${group.name}.`;

      addToast({
        title,
        message: notificationContent,
      });

      addNotification({
        title,
        content: notificationContent,
        created_at: new Date(),
        type: "CHAT",
        type_id: group.id,
      });
    }

    // Update the message to reflect the change to reactions
    if (type === "reaction") {
      replaceItemInStore(incomingMessage, currentChatGroup.messages);
    }
  };

  const reset = () => {
    selectedGroupId.value = null;
    Object.keys(chatsByGroupId).forEach((groupId) => {
      delete chatsByGroupId[groupId];
    });
  };

  return {
    allChatGroups,
    chatsByGroupId,
    createChatGroup,
    createChatGroupMessage,
    deleteChatGroup,
    deleteChatGroupMessage,
    fetchChatGroupMessages,
    fetchChatGroups,
    privateChatGroups,
    publicChatGroups,
    reactToChatGroupMessage,
    reset,
    selectedChatGroup,
    selectedGroupId,
    sendUpdateNotifications,
    unreadChatMessages,
    updateChatGroup,
    updateChatGroupMessage,
    markMessagesAsRead,
  };
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useChat, import.meta.hot));
}
