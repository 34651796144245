<template>
  <section v-if="isLoading" class="py-2.5 px-1 flex flex-row items-center justify-center">
    <loader-tailspin />
  </section>
  <section v-if="teamInvites.length === 0 && !isLoading">
    <h2>No pending team invitations</h2>
  </section>
  <section v-if="teamInvites.length > 0 && !isLoading">
    <article
      v-for="(teamInvite, idx) in teamInvites"
      :key="teamInvite.id"
      :class="{
        'border-b': idx % 2 === 0 && teamInvites.length !== 1,
      }"
      class="py-2.5 px-1 flex flex-row items-center justify-between border-pewter text-rocks"
    >
      <h2 class="text-lg">{{ teamInvite.employee?.company?.name }}</h2>
      <div class="flex">
        <Btn class="mr-2" size="small" @click="onAcceptInvite(teamInvite.token)">Accept</Btn>
        <Btn variant="primaryOutline" size="small" @click="onIgnoreInvite(teamInvite.token)"
          >Ignore</Btn
        >
      </div>
    </article>
  </section>
</template>
<script>
import { ref } from "vue";
import Btn from "@/components/Btn";
import LoaderTailspin from "@/components/LoaderTailspin";
import { useCompanyInvites } from "@/stores/companies-invites";
import { useAuth } from "@/stores/auth";
import { useCompanyEmployees } from "@/stores/companies-employees";
import { useCompanyLocations } from "@/stores/companies-locations";
import { useCompanyAudiences } from "@/stores/companies-audiences";
import { useChat } from "@/stores/chat";
import router from "@/router";
import constants from "@/utils/constants";
import { storeToRefs } from "pinia";

export default {
  name: "PendingInvitations",
  components: {
    Btn,
    LoaderTailspin,
  },
  props: {
    errors: null,
    teamInvites: {
      type: Array,
      default: () => [],
    },
  },
  emits: ["accept", "ignore"],
  setup(props, { emit }) {
    const authStore = useAuth();
    const employeesStore = useCompanyEmployees();
    const locationsStore = useCompanyLocations();
    const audiencesStore = useCompanyAudiences();
    const chatStore = useChat();

    const { user } = storeToRefs(authStore);

    const { fetchUser } = authStore;
    const { acceptCompanyInvite, ignoreCompanyInvite } = authStore;

    const onAcceptInvite = async (inviteToken) => {
      await acceptCompanyInvite(inviteToken);

      // refetch the user after accepting the invite to ensure that they have
      // companies associated with them and are appropriately routed
      emit("accept");

      // if (user?.value?.companies?.length > 0) {
      //   return router.push({ name: constants.HOME_ROUTE });
      // }
    };

    const onIgnoreInvite = async (inviteToken) => {
      await ignoreCompanyInvite(inviteToken);

      emit("ignore");
      // if (user?.value?.companies?.length > 0) {
      //   return router.push({ name: constants.HOME_ROUTE });
      // }
    };

    const isLoading = ref(true);

    // TODO: This is a hack until loading state is better
    setTimeout(() => {
      // Show loading spinner while data loads in background
      isLoading.value = false;
    }, 750);

    return {
      isLoading,
      onAcceptInvite,
      onIgnoreInvite,
    };
  },
};
</script>
