import { computed } from "vue";
import { defineStore, acceptHMRUpdate, storeToRefs } from "pinia";
import { make } from "@/utils/request";

import { useCompanies } from "./companies";

export const useCompanyCheckins = defineStore("companies-checkins", () => {
  const companyStore = useCompanies();
  const { selectedCompany } = storeToRefs(companyStore);

  // TODO: I think we can actually just remove this
  // in favor of using companyFeatureFlag
  const disabledCompanyCheckins = computed(() => {
    if (!selectedCompany?.value?.flags) {
      return [];
    }

    // TODO: Is this logic correct? Why is it using .enabled?
    const checkinFlag = selectedCompany.value.flags.find(
      (featureFlag) => featureFlag.key === "checkins"
    );

    return checkinFlag.enabled;
  });

  const companyCheckinTopics = computed(() => {
    if (!selectedCompany?.value?.checkin_topics) {
      return [];
    }

    return selectedCompany.value.checkin_topics;
  });

  const createEmployeeCheckin = async (employeeId) => {
    const checkin = await make({
      name: "createEmployeeCheckin",
      params: {
        company_id: selectedCompany.value.id,
        id: employeeId,
      },
    });

    return checkin;
  };

  const fetchCheckins = async () => {
    const checkins = await make({
      name: "getCompanyCheckins",
      params: {
        company_id: selectedCompany.value.id,
      },
    });

    return checkins;
  };

  const createCompanyCheckins = async () => {
    const checkins = await make({
      name: "scheduleCompanyCheckins",
      params: {
        company_id: selectedCompany.value.id,
      },
    });

    return checkins;
  };

  return {
    companyCheckinTopics,
    createCompanyCheckins,
    createEmployeeCheckin,
    disabledCompanyCheckins,
    fetchCheckins,
  };
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useCompanyCheckins, import.meta.hot));
}
