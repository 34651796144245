export const minChars = (minValue) => (value) => {
  if (!value) return true;

  const isString = typeof value === "string";

  return isString && value.length >= minValue;
};

export const maxChars = (maxValue) => (value) => {
  if (!value) return true;

  const isString = typeof value === "string";

  return isString && value.length <= maxValue;
};

export const maxLines = (maxValue) => (value) => {
  if (!value) return true;

  const isString = typeof value === "string";

  return isString && value.split("\n").length <= maxValue;
};

export const maxMarkdown = (testLength) => (value) => {
  const replaced = value
    .replaceAll(/[*]/g, "")
    .replaceAll(/[`]/g, "")
    .replaceAll(/[#]/g, "")
    .replaceAll(/[>]/g, "")
    .replaceAll(/[~]/g, "");
  // .replaceAll(/[\s]{2}/g, "");
  return replaced.length <= testLength;
};
