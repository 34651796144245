<template>
  <div class="flex items-center justify-center relative">
    <button
      @click="showMenu"
      :aria-expanded="showMenu"
      class="menu outline-none focus:outline-none"
      :class="{ badge: notifications && notifications?.length > 0 }"
      aria-label="List of notifications"
    >
      <svg style="width: 32px; height: 32px" viewBox="0 0 24 24">
        <path
          fill="currentColor"
          d="M10 21H14C14 22.1 13.1 23 12 23S10 22.1 10 21M21 19V20H3V19L5 17V11C5 7.9 7 5.2 10 4.3V4C10 2.9 10.9 2 12 2S14 2.9 14 4V4.3C17 5.2 19 7.9 19 11V17L21 19M17 11C17 8.2 14.8 6 12 6S7 8.2 7 11V18H17V11Z"
        />
      </svg>
      <!-- TODO: this could just be turned into styling for a dot on the icon above instead-->
      <!-- <svg style="width: 32px; height: 32px" viewBox="0 0 24 24" v-if="notifications?.length > 0">
        <path
          fill="#D11F9F"
          d="M21,19V20H3V19L5,17V11C5,7.9 7.03,5.17 10,4.29C10,4.19 10,4.1 10,4A2,2 0 0,1 12,2A2,2 0 0,1 14,4C14,4.1 14,4.19 14,4.29C16.97,5.17 19,7.9 19,11V17L21,19M14,21A2,2 0 0,1 12,23A2,2 0 0,1 10,21"
        />
      </svg> -->
    </button>
    <div
      v-if="menuOpened"
      class="
        absolute
        bg-white
        border-gray-100 border-solid border
        z-40
        rounded
        flex flex-col
        w-80
        text-maleficent
        right-0
        top-12
        space-y-2
        shadow
        py-2
        px-4
      "
      id="notificationTray"
    >
      <div class="flex flex-row mx-1 space-between justify-between border-b-2">
        <span class="text-gray-400 font-bold uppercase text-xs py-2">Notifications</span>
        <span
          class="mark-read text-blue text-right cursor-pointer"
          @click.prevent="clearNotifications()"
          >{{ "clear all" }}</span
        >
      </div>
      <div class="notification-title text-blue" v-if="notifications.length === 0">
        No Notifications
      </div>
      <div class="flex-col-reverse divide divide-y">
        <div
          class="py-2 flex flex-col flex-1 m-1"
          :class="{ 'bg-gray': !notification.read }"
          v-for="(notification, index) in notifications"
          :key="index"
          @click.prevent="deepLink(notification)"
        >
          <div class="notification-title text-black font-bold">{{ notification.title }}</div>
          <div class="notification-content text-black italic">{{ notification.content }}</div>
          <div class="flex flex-column justify-between items-center">
            <div class="notification-content text-rocks text-xs" v-if="notification.created_at">
              {{ formatDateTime(notification.created_at) }}
            </div>
            <div
              class="mark-read text-black text-right text-xs cursor-pointer"
              @click.stop.prevent="markNotificationAsRead(notification.id)"
              v-if="!notification.read"
            >
              {{ "mark read" }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import router from "@/router";
import { DateTime } from "luxon";
import { useAuth } from "@/stores/auth";
import { useNotifications } from "@/stores/notifications";
import { storeToRefs } from "pinia";

export default {
  name: "Notifications",
  setup() {
    const authStore = useAuth();
    const notificationsStore = useNotifications();

    const { notifications } = storeToRefs(notificationsStore);

    const { logout } = authStore;
    const { clearNotifications, markNotificationAsRead } = notificationsStore;

    const menuOpened = ref(false);

    const showMenu = (e) => {
      menuOpened.value = !menuOpened.value;
      e.stopPropagation();
      if (menuOpened.value) {
        document.body.addEventListener("click", onClickOutside);
      } else {
        menuOpened.value = false;
        document.body.removeEventListener("click", onClickOutside);
      }
    };

    const onClickOutside = (event) => {
      const isNotificationTray = event.target === document.getElementById("notificationTray");
      const isButton =
        event.target.innerHTML === "mark read" || event.target.innerHTML === "clear all";

      if (!isNotificationTray && !isButton) {
        menuOpened.value = false;
        document.body.removeEventListener("click", onClickOutside);
      }
    };

    const formatDateTime = (timestamp) => {
      return timestamp.toLocaleString(DateTime.DATETIME_MED);
    };

    const deepLink = (notification) => {
      if (notification.type === "CHAT") {
        router.push({ name: "ChatGroupList", query: { id: notification.type_id } });
      } else if (notification.type === "TASK") {
        router.push({ name: "TaskDetail", params: { id: notification.type_id } });
      }
    };

    return {
      showMenu,
      menuOpened,
      notifications,
      logout,
      clearNotifications,
      markNotificationAsRead,
      formatDateTime,
      deepLink,
    };
  },
};
</script>

<style lang="postcss">
.mark-read {
  padding-right: 10px;
  text-decoration: underline;
}

,
.notification-wrapper {
  padding-top: 10px;
  padding-bottom: 10px;
  width: 80%;
}

.notification-title {
  padding-left: 10px;
}

.notification-content {
  padding-left: 10px;
}

.badge::before {
  content: " ";
  background-color: #d11f9f;
  height: 12px;
  width: 12px;
  position: absolute;
  right: 2px;
  top: 2px;
  border-radius: 12px;
}
</style>
