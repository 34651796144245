<template>
  <CompanyLayout v-if="selectedCompany?.id" />
  <div v-else class="h-full w-full flex flex-col items-center justify-center">
    <div>Loading company info ({{ companyId }})</div>
  </div>
  <CompanyContext v-if="selectedCompany?.id" />
  <Loader v-if="loading" />
  <RedirectIfNotAuthedContext />
</template>

<script>
import { onMounted, ref } from "vue";
import { useCompanies } from "@/stores/companies";
import { storeToRefs } from "pinia";
import CompanyLayout from "@/components/Layouts/CompanyLayout";
import CompanyContext from "@/components/Company/CompanyContext.vue";
import Loader from "@/components/Loader";
import { useRouter } from "vue-router";
import auth from "@/store/modules/auth";
import { useAuth } from "@/stores/auth";
import RedirectIfNotAuthedContext from "@/components/Auth/RedirectIfNotAuthedContext.vue";

export default {
  name: "Dashboard",
  components: {
    Loader,
    CompanyLayout,
    CompanyContext,
    RedirectIfNotAuthedContext,
  },
  props: {
    companyId: [String, Number],
  },
  setup(props) {
    const companyStore = useCompanies();

    const router = useRouter();
    const authStore = useAuth();

    const { selectedCompany } = storeToRefs(companyStore);
    const loading = ref(true);

    const version = process?.env?.VUE_APP_PACKAGE_VERSION
      ? `v${process.env.VUE_APP_PACKAGE_VERSION}`
      : "UNKNOWN VERSION";

    onMounted(async () => {
      loading.value = true;
      // await authStore.fetchUser();
      let company = await companyStore.setSelectedCompanyById(parseInt(props.companyId));

      if (!company?.id) {
        router.push({ name: "Selection" });
      }

      loading.value = false;
    });

    return {
      selectedCompany,
      version,
      loading,
    };
  },
};
</script>
