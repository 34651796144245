<script setup>
import { onMounted, onUnmounted } from "vue";
import { useCompanyBilling } from "@/stores/companies-billing";
import { useFiles } from "@/stores/files";
import { useTags } from "@/stores/tags";
import { useWebsockets } from "@/stores/websockets";
import { useCompanies } from "@/stores/companies";
import { useChat } from "@/stores/chat";
import { useCompanyEmployees } from "@/stores/companies-employees";
import { useAuth } from "@/stores/auth";
import { useTasks } from "@/stores/tasks";
import { useForms } from "@/stores/forms";

const filesStore = useFiles();
const billingStore = useCompanyBilling();
const tagsStore = useTags();
const websocketsStore = useWebsockets();
const companyStore = useCompanies();
const chatStore = useChat();
const employeesStore = useCompanyEmployees();
const authStore = useAuth();
const tasksStore = useTasks();
const formsStore = useForms();

onMounted(async () => {
  await tagsStore.fetchTags();
  await websocketsStore.initializeWebsockets(companyStore.selectedCompany.id);
  // Remove the await because the data is not needed at this stage and can load in the background. These requests are causing the most latency
  chatStore.fetchChatGroups();
  await employeesStore.fetchSelfEmployee();
  // Remove the await because the data is not needed at this stage and can load in the background. These requests are causing the most latency
  tasksStore.fetchTasks();
  await formsStore.fetchFormTemplates();

  // if (companyStore.company?.employee?.is_management) {
  await employeesStore.fetchEmployees();
  // }
});

onUnmounted(async () => {
  filesStore.reset();
  chatStore.reset();
  await websocketsStore.disconnectWebsocket(`chat.${companyStore.selectedCompany.id}`);
});

// land on company route (/:company_id)
//// load company billing information (only for admins)
//// load tasks
//// load chats
//// load followups
//// subscribe to websockets for chat (update store when receive)
//// subscribe to websockets for tasks (update store when receive)

// request as needed but cache to prevent rapid requests
//// load audiences for dropdowns
//// load locations for dropdowns
//// load employees for dropdowns
//// fetch chat groups

// request on demand (always fresh data)
//// paginated audiences
//// paginated locations
//// paginated employees
//// paginated tasks
//// tasks detail
//// memo detail
//// chat detail
//// employee/followup detail
</script>

<template><div></div></template>
