import { make } from "@/utils/request";
import Status from "@/classes/Status";

const tasks = {
  namespaced: true,
  state: {
    item: null,
    status: new Status(),
    list: [],
    websocketLatest: null,
  },
  mutations: {
    SET_TASKS(state, payload) {
      state.list = payload;
    },
    SET_ITEM(state, payload) {
      if (payload) {
        state.item = payload;
      } else {
        state.item = null;
      }
    },
    UPDATE_WEBSOCKET(state, payload) {
      if (payload?.id) {
        state.websocketLatest = payload;
      } else {
        state.websocketLatest = null;
      }
    },
    CLEAR_TASKS(state) {
      state.list = [];
    },
    CLEAR_ITEM(state) {
      state.item = null;
    },
    SET_STATUS(state, payload) {
      state.status.value = payload;
    },
  },
  actions: {
    async list({ commit, rootGetters }, data) {
      commit("SET_STATUS", Status.LOADING);

      const req = await make({
        name: "taskList",
        params: {
          company_id: rootGetters["companies/getCompany"].id,
        },
        data,
      });

      commit("SET_ITEM", req);
      commit("SET_TASKS", req.tasks);
      commit("SET_STATUS", Status.LOADED);

      return req;
    },
    async get({ rootGetters }, id) {
      const req = await make({
        name: "taskGet",
        params: {
          company_id: rootGetters["companies/getCompany"].id,
          id,
        },
      });

      return req;
    },
    async create({ rootGetters }, data) {
      const req = await make({
        name: "taskCreate",
        data: {
          company_id: rootGetters["companies/getCompany"].id,
          ...data,
        },
      });

      return req;
    },
    async update({ rootGetters }, data) {
      const req = await make({
        name: "taskUpdate",
        data: {
          company_id: rootGetters["companies/getCompany"].id,
          ...data,
        },
      });

      return req;
    },
    async delete({ rootGetters }, id) {
      const req = await make({
        name: "taskDelete",
        data: {
          company_id: rootGetters["companies/getCompany"].id,
          id,
        },
      });

      return req;
    },
    async view({ rootGetters }, id) {
      const req = await make({
        name: "taskView",
        data: {
          company_id: rootGetters["companies/getCompany"].id,
          id,
        },
      });

      return req;
    },
    async assign({ rootGetters }, data) {
      const req = await make({
        name: "taskAssign",
        data: {
          company_id: rootGetters["companies/getCompany"].id,
          ...data,
        },
      });

      return req;
    },
    async start({ rootGetters }, id) {
      const req = await make({
        name: "taskStart",
        data: {
          company_id: rootGetters["companies/getCompany"].id,
          id,
        },
      });

      return req;
    },
    async complete({ rootGetters }, id) {
      const req = await make({
        name: "taskComplete",
        data: {
          company_id: rootGetters["companies/getCompany"].id,
          id,
        },
      });

      return req;
    },
    async reopen({ rootGetters }, id) {
      const req = await make({
        name: "taskReopen",
        data: {
          company_id: rootGetters["companies/getCompany"].id,
          id,
        },
      });

      return req;
    },
    async comment({ rootGetters }, data) {
      const req = await make({
        name: "taskComment",
        data: {
          company_id: rootGetters["companies/getCompany"].id,
          ...data,
        },
      });

      return req;
    },
    async commentEdit({ rootGetters }, data) {
      const req = await make({
        name: "taskCommentEdit",
        data: {
          company_id: rootGetters["companies/getCompany"].id,
          ...data,
        },
      });

      return req;
    },
    async commentDelete({ rootGetters }, data) {
      const req = await make({
        name: "taskCommentDelete",
        data: {
          company_id: rootGetters["companies/getCompany"].id,
          ...data,
        },
      });

      return req;
    },
    async updateWebsocket({ dispatch, commit, rootGetters }, { task, type, initiator }) {
      const employee = rootGetters["companies/getEmployee"];
      const employeeId = employee.id;

      let shouldProcessTask = false;
      let empHasLocation = null;
      let empHasAudience = null;
      let empIsRecipient = null;

      // only drop into this if block if mode is segmentation
      if (task?.mode === "segments") {
        if (task?.location_ids?.length === 0 && task?.audience_ids?.length === 0) {
          // process if there are no locations and audiences
          shouldProcessTask = true;
        } else {
          // there is segmentation
          if (task.location_ids.length > 0) {
            empHasLocation = null; // reset for falsy
            empHasLocation = task.location_ids.find((locationId) => {
              return employee.locations.find((empLocation) => {
                return empLocation.id === locationId;
              });
            });
          }

          if (task.audience_ids.length > 0) {
            empHasAudience = null; // reset for falsy
            empHasAudience = task.audience_ids.find((audienceId) => {
              return employee.audiences.find((empAudience) => {
                return empAudience.id === audienceId;
              });
            });
          }

          if (empHasLocation && empHasAudience) {
            // only process if they have both locations and audiences?
            shouldProcessTask = true;
          }
        }
      }

      if (task?.recipient_ids.length > 0) {
        empIsRecipient = task.recipient_ids.find((recipientId) => {
          return recipientId === employee.id;
        });
        if (empIsRecipient) {
          shouldProcessTask = true;
        }
      }

      if (!shouldProcessTask) {
        return;
      }

      let dispatchNotifications = initiator === employeeId ? false : true;
      let titlePrefix = "Task";
      if (type === "CREATED") {
        titlePrefix += " Created";
        if (task.created_by === employeeId) {
          dispatchNotifications = false;
        }
      } else if (type === "UPDATED") {
        titlePrefix += " Updated";
        if (task.updated_by === employeeId) {
          dispatchNotifications = false;
        }
      } else if (type === "DELETED") {
        titlePrefix += " Deleted";
        if (task.deleted_by === employeeId) {
          dispatchNotifications = false;
        }
      } else if (type === "ASSIGNED") {
        titlePrefix += " Assigned";
        if (task.assigned_by === employeeId) {
          dispatchNotifications = false;
        }
      } else if (type === "STARTED") {
        titlePrefix += " Started";
        if (task.started_by === employeeId) {
          dispatchNotifications = false;
        }
      } else if (type === "COMPLETED") {
        titlePrefix += " Completed";
        if (task.completed_by === employeeId) {
          dispatchNotifications = false;
        }
      } else if (type?.indexOf("COMMENT") > -1) {
        // TODO pull in commentor id
        titlePrefix += " Comment";
      } else if (type === "REOPENED") {
        titlePrefix += " Reopened";
        if (task.updated_by === employeeId) {
          dispatchNotifications = false;
        }
      }

      // Dont dispatch notifications for the person modifying the task
      if (dispatchNotifications) {
        dispatch(
          "toasts/add",
          {
            title: `${titlePrefix} ${task?.title}`,
            message: `${task?.content}`,
          },
          { root: true }
        );
        dispatch(
          "notifications/add",
          {
            title: `${titlePrefix}: ${task?.title}`,
            content: `${task?.content}`,
            created_at: new Date(),
            type: "TASK",
            type_id: task?.id,
          },
          { root: true }
        );
      }

      commit("UPDATE_WEBSOCKET", task);

      //update tasks
      await dispatch("list");
    },
    clearItem({ commit }) {
      commit("CLEAR_ITEM");
      commit("CLEAR_TASKS");
    },
  },
  getters: {
    isLoading(state) {
      return state.status.isLoading || state.status.isUpdating;
    },
    openTasks: (state, _rootGetters, rootState) => {
      const employee_id = rootState.auth.user?.default_employee_id;

      if (!employee_id || state.list.length === 0) {
        return 0;
      }

      const totalOpen = state.list.reduce((cnt, task) => {
        // If its not completed and assigned to me
        if (!task.completed_at && task.assignee_id === employee_id) {
          return cnt + 1;
        }
        // Or if its not completed and unassigned
        else if (!task.completed_at && task.assignee_id === null) {
          return cnt + 1;
        } else {
          // otherwise ignore if a task is closed -OR- assigned to someone else
          return cnt;
        }
      }, 0);

      return totalOpen;
    },
    taskPages: (state) => {
      return state.item.total_pages;
    },
  },
};

export default tasks;
