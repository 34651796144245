import { ref, watch, onMounted } from "vue";
import { defineStore, acceptHMRUpdate, storeToRefs } from "pinia";
import { make } from "@/utils/request";
import { useAppConfig } from "./app-config";
import { useCompanies } from "./companies";

export const useCompanyBilling = defineStore("companies-billing", () => {
  const companyStore = useCompanies();
  const appConfigStore = useAppConfig();
  const { setShowAppBanner } = appConfigStore;

  const { selectedCompany, isCompanyAdmin } = storeToRefs(companyStore);
  const { refreshSelectedCompany } = companyStore;

  const billingSummary = ref(null);
  const billingPortalUrl = ref("");
  const billingCheckoutUrl = ref("");
  const billingStatus = ref("");
  const billingCancels = ref("");

  const fetchBillingSummary = async () => {
    if (!selectedCompany.value) {
      return null;
    }

    const companyBilling = await make({
      name: "getBillingSummary",
      data: { company_id: selectedCompany.value.id },
    });

    billingSummary.value = companyBilling;

    return companyBilling;
  };

  const acknowledgeSubscription = async (company_id) => {
    const response = await make({
      name: "acknowledgeSubscription",
      params: {
        company_id,
      },
    });
    return response;
  }

  const fetchBillingUrls = async () => {
    try {
      const billingUrls = await make({
        name: "getBillingPortalUrl",
        data: {
          company_id: selectedCompany.value.id,
        },
      });

      billingPortalUrl.value = billingUrls.url;
      billingCheckoutUrl.value = billingUrls.checkout_url;
      billingStatus.value = billingUrls.status;
      billingCancels.value = billingUrls.cancel_at;

      await refreshSelectedCompany();
      setShowAppBanner(null, selectedCompany.value);
      return billingUrls;
    } catch (err) {
      return null;
    }
  };

  const updateBilling = async (data) => {
    await make({
      name: "updateBilling",
      data: {
        company_id: selectedCompany.value.id,
        ...data
      },
    });
    await refreshSelectedCompany();
    setShowAppBanner(null, selectedCompany.value);
  }

  const fetchPrice = async () => {
    const response = await make({
      name: "getPrice",
      data: {
        company_id: selectedCompany.value.id,
      },
    });
    return response;
  }

  const reset = () => {
    billingSummary.value = null;
    billingPortalUrl.value = "";
    billingCheckoutUrl.value = "";
  };

  // watch(selectedCompany, async (newCompany, oldCompany) => {
  //   const hasChangedCompany = newCompany && newCompany?.id !== oldCompany?.id;
  //   if (isCompanyAdmin.value && hasChangedCompany) {
  //     await fetchBillingSummary();
  //     await fetchBillingUrls();
  //   }

  //   // company has been cleared, so reset bililng values
  //   if (!newCompany && oldCompany) {
  //     billingSummary.value = null;
  //     billingPortalUrl.value = "";
  //     billingCheckoutUrl.value = "";
  //   }
  // });

  // onMounted(async () => {
  //   if (isCompanyAdmin.value && selectedCompany.value && !billingSummary.value) {
  //     await fetchBillingSummary();
  //     await fetchBillingUrls();
  //   }
  // });

  return {
    billingCheckoutUrl,
    billingPortalUrl,
    billingSummary,
    fetchBillingSummary,
    fetchBillingUrls,
    updateBilling,
    reset,
    fetchPrice,
    acknowledgeSubscription,
    billingStatus,
    billingCancels,
  };
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useCompanyBilling, import.meta.hot));
}
