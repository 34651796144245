<template>
  <div
    class="
      w-6
      h-6
      flex
      text-white
      shadow
      items-center
      justify-center
      relative
      bg-blue
      cursor-pointer
    "
    :class="avatarClasses"
    @click="showMenu"
  >
    <img
      v-if="$attrs.src"
      class="w-full h-full overflow-hidden cursor-pointer"
      :class="roundClasses"
      v-bind="$attrs"
    />
    <span v-else class="text-white uppercase font-medium cursor-pointer">
      <slot>{{ avatarText }}</slot>
    </span>
    <div
      v-if="menuOpened"
      class="
        absolute
        bg-white
        border-gray-100 border-solid border
        z-40
        rounded
        flex flex-col
        w-56
        text-maleficent
        right-0
        top-14
        space-y-2
        shadow
        py-2
      "
      id="menu"
    >
      <div class="flex flex-col">
        <span class="text-gray-400 font-bold uppercase text-xs p-2 px-4">Team Settings</span>
        <router-link
          class="content-center hover:bg-gray cursor-pointer w-full px-4 py-1"
          :to="{ name: 'Selection', query: { show: 'yes' } }"
        >
          Change Team
        </router-link>
        <router-link
          v-if="selectedCompany"
          class="content-center hover:bg-gray cursor-pointer w-full px-4 py-1"
          :to="{ name: 'Subscription' }"
        >
          Manage Subscription
        </router-link>
        <router-link
          v-if="selectedCompany"
          class="content-center hover:bg-gray cursor-pointer w-full px-4 py-1"
          :to="{ name: 'SettingsTeamInvitations' }"
        >
          Team Invitations
        </router-link>
      </div>
      <div class="flex flex-col mt-2">
        <span class="text-gray-400 font-bold uppercase text-xs p-2 px-4">Account Settings</span>
        <router-link
          class="content-center hover:bg-gray cursor-pointer w-full px-4 py-1"
          :to="{ name: 'Profile' }"
        >
          User Account
        </router-link>
        <a class="content-center hover:bg-gray cursor-pointer w-full px-4 py-1" @click="logout"
          >Log out</a
        >
      </div>
    </div>
  </div>
</template>
<script>
import { computed, ref } from "vue";
import { useCompanies } from "@/stores/companies";
import { useAuth } from "@/stores/auth";
import { storeToRefs } from "pinia";

export default {
  name: "Avatar",
  props: {
    name: {
      type: String,
      default: "",
    },
    color: {
      type: String,
      default: "white",
    },
    size: {
      type: String,
      default: "sm",
    },
    round: {
      type: Boolean,
      default: false,
    },
    hasManyCompanies: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const companyStore = useCompanies();
    const authStore = useAuth();

    const { selectedCompany } = storeToRefs(companyStore);

    const { logout } = authStore;

    const menuOpened = ref(false);

    const sizeClasses = computed(() => {
      const sizeMappings = {
        sm: `h-12 w-12`,
        md: `h-16 w-16 text-xl`,
        lg: `text-lg h-24 w-24 text-2xl`,
      };
      return sizeMappings[props.size] || sizeMappings.md;
    });
    const roundClasses = computed(() => {
      return props.round ? "rounded-full" : "rounded";
    });
    const avatarClasses = computed(() => {
      return `bg-${props.color} ${roundClasses.value} ${sizeClasses.value}`;
    });
    const avatarText = computed(() => {
      if (!props.name || typeof props.name !== "string" || !props.name.length) {
        return "";
      }
      return props.name.charAt(0);
    });

    const showMenu = (e) => {
      menuOpened.value = !menuOpened.value;
      e.stopPropagation();
      if (menuOpened.value) {
        document.body.addEventListener("click", onClickOutside);
      } else {
        menuOpened.value = false;
        document.body.removeEventListener("click", onClickOutside);
      }
    };

    const onClickOutside = (event) => {
      if (event.target !== document.getElementById("menu")) {
        menuOpened.value = false;
        document.body.removeEventListener("click", onClickOutside);
      }
    };

    return {
      selectedCompany,
      showMenu,
      menuOpened,
      logout,
      sizeClasses,
      roundClasses,
      avatarClasses,
      avatarText,
    };
  },
};
</script>

<style lang="postcss" scoped></style>
