import { make } from "@/utils/request";
import Status from "@/classes/Status";

const celebrations = {
  namespaced: true,
  state: {
    item: null,
    status: new Status(),
    list: [],
  },
  mutations: {
    SET_CELEBRATIONS(state, payload) {
      state.list = payload;
    },
    SET_ITEM(state, payload) {
      if (payload?.id) {
        state.item = payload;
      } else {
        state.item = null;
      }
    },
    CLEAR_CELEBRATIONS(state) {
      state.list = [];
    },
    CLEAR_ITEM(state) {
      state.item = null;
    },
    SET_STATUS(state, payload) {
      state.status.value = payload;
    },
  },
  actions: {
    async fetch({ commit, rootGetters }, data) {
      commit("SET_STATUS", Status.LOADING);

      const req = await make({
        name: "getCelebrations",
        params: {
          company_id: rootGetters["companies/getCompany"].id,
          ...data,
        },
      });

      commit("SET_CELEBRATIONS", req.celebrations);
      commit("SET_STATUS", Status.LOADED);

      return req;
    },
    setItem({ commit }, celebration) {
      if (celebration) {
        commit("SET_ITEM", celebration);
      } else {
        commit("CLEAR_ITEM");
      }
    },
    clearItem({ commit }) {
      commit("CLEAR_ITEM");
    },
    async fetchCelebration({ rootGetters }, id) {
      const req = await make({
        name: "getCelebration",
        params: {
          company_id: rootGetters["companies/getCompany"].id,
          id,
        },
      });

      return req;
    },
    async expireCelebration({ rootGetters }, id) {
      const req = await make({
        name: "expireCelebration",
        params: {
          company_id: rootGetters["companies/getCompany"].id,
          id,
        },
      });

      return req;
    },
    async deleteCelebration({ rootGetters }, id) {
      const req = await make({
        name: "deleteCelebration",
        params: {
          company_id: rootGetters["companies/getCompany"].id,
          id,
        },
      });

      return req;
    },
    async createCelebration({ rootGetters }, data) {
      const req = await make({
        name: "createCelebration",
        data: {
          ...data,
          company_id: rootGetters["companies/getCompany"].id,
        },
      });

      return req;
    },
    async editCelebration({ rootGetters }, data) {
      const req = await make({
        name: "editCelebration",
        data: {
          ...data,
          company_id: rootGetters["companies/getCompany"].id,
        },
      });

      return req;
    },
    async attachMediaToCelebration({ rootGetters }, { id, media_id }) {
      const req = await make({
        name: "attachMediaToCelebration",
        data: {
          company_id: rootGetters["companies/getCompany"].id,
          id,
          media_id,
        },
      });

      return req;
    },
    async detachMediaFromCelebration({ rootGetters }, { id, media_id }) {
      const req = await make({
        name: "detachMediaFromCelebration",
        data: {
          company_id: rootGetters["companies/getCompany"].id,
          id,
          media_id,
        },
      });

      return req;
    },
  },
  getters: {
    isLoading(state) {
      return state.status.isLoading || state.status.isUpdating;
    },
    get(state) {
      return state.list;
    },
    getCelebration(state) {
      return state.item;
    },
    getCategories: (_state, _getters, rootState) => {
      return (
        rootState.companies?.item?.celebration_categories?.map((c) => ({ label: c, value: c })) ??
        []
      );
    },
    getTypes() {
      return [
        { label: "Default", value: "other" },
        { label: "Birthday", value: "birthday" },
        { label: "Anniversary", value: "anniversary" },
      ];
    },
  },
};

export default celebrations;
