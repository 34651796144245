import { req } from "./shared";

const required = (value) => {
  if (typeof value === "string") {
    return req(value.trim());
  }
  return req(value);
};

export { required };
