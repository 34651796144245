import { DateTime } from "luxon";

class RaisedHand {
  constructor({
    acknowledged_at = null,
    admin_notify_path = null,
    created_at = null,
    employee_id = null,
    id = null,
    is_awaiting_employee = false,
    is_awaiting_manager = false,
    is_urgent = false,
    manager_acknowledged_at = null,
    manager_id = null,
    manager_note = null,
    time_ago = null,
    updated_at = null,
  } = {}) {
    this.acknowledged_at = acknowledged_at ? DateTime.fromISO(acknowledged_at) : null;
    this.admin_notify_path = admin_notify_path;
    this.created_at = created_at ? DateTime.fromISO(created_at) : null;
    this.employee_id = employee_id;
    this.id = id;
    this.is_awaiting_employee = is_awaiting_employee;
    this.is_awaiting_manager = is_awaiting_manager;
    this.is_urgent = is_urgent;
    this.manager_acknowledged_at = manager_acknowledged_at
      ? DateTime.fromISO(manager_acknowledged_at)
      : null;
    this.manager_id = manager_id;
    this.manager_note = manager_note;
    this.time_ago = time_ago;
    this.updated_at = updated_at ? DateTime.fromISO(updated_at) : null;
  }
}

export default RaisedHand;
