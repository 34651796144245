import { remove as lsRemove, set as lsSet } from "local-storage";
import Status from "@/classes/Status";
import { make } from "@/utils/request";

const compareName = (a, b) => {
  if (a.name > b.name) return 1;
  if (b.name > a.name) return -1;

  return 0;
};

const companyIdCacheKey = "companyId";

const companies = {
  namespaced: true,
  state: {
    loading: false,
    item: null,
    itemEmployee: null,
    status: new Status(),
    list: [],
    invitations: [],
    assignedLocations: [],
  },
  mutations: {
    TOGGLE_LOADING(state) {
      state.loading = !state.loading;
    },
    SET_COMPANIES(state, payload) {
      state.list = payload;
    },
    SET_TEAM_INVITATIONS(state, payload) {
      state.invitations = payload;
    },
    SET_ITEM(state, payload) {
      if (payload?.id) {
        lsSet(companyIdCacheKey, payload.id);
        state.item = payload;
      } else {
        state.item = null;
      }
    },
    SET_ITEM_EMPLOYEE(state, payload) {
      if (payload?.id) {
        state.itemEmployee = payload;
      } else {
        state.item = null;
      }
    },
    CLEAR_COMPANIES(state) {
      state.item = null;
      state.list = [];
      state.invitations = [];
    },
    CLEAR_ITEM(state) {
      lsRemove(companyIdCacheKey);
      state.item = null;
    },
    SET_STATUS(state, payload) {
      state.status.value = payload;
    },
    SET_ASSIGNED_LOCATIONS(state, payload) {
      state.assignedLocations = payload;
    },
  },
  actions: {
    async fetch({ commit, state }) {
      if (state.loading) {
        // Don't re-fetch if already loading
        return null;
      }

      commit("TOGGLE_LOADING");

      const response = await make({ name: "settingsMe" });

      if (!response || !response.companies) {
        return null;
      }

      commit("SET_COMPANIES", response.companies);
      const hasSelectedCompany = response.company?.id;
      const hasCreatedCompany = response.companies.length === 1;

      if (hasSelectedCompany) {
        commit("SET_ITEM", response.company);
      }

      if (!hasSelectedCompany && hasCreatedCompany) {
        commit("SET_ITEM", response.companies[0]);
      }

      if (response.employee?.id) {
        commit("SET_ITEM_EMPLOYEE", response.employee);

        const companyLocations = state.itemEmployee.assigned_locations.length
          ? state.itemEmployee.assigned_locations
          : response.company.locations;

        const assignedLocations = companyLocations.map((location) => ({
          label: location.name,
          value: location.id,
        }));

        commit("SET_ASSIGNED_LOCATIONS", assignedLocations);
      }

      commit("TOGGLE_LOADING");
      return { companies: state.list, currentCompany: state.item };
    },
    async createTeam({ dispatch }, data) {
      const { newCompany } = await make({ name: "createTeam", data });
      await dispatch("fetch");

      return newCompany;
    },
    async getTeamInvites({ commit }, user) {
      let invites = [];
      if (user.phone) {
        invites = await make({ name: "getTeamInvites", params: { phone: user.phone } });
      }

      commit("SET_TEAM_INVITATIONS", invites);

      return invites;
    },
    async acceptTeamInvitation({ dispatch }, data) {
      await make({ name: "acceptInvitation", data });
      return await dispatch("fetch");
    },
    async ignoreTeamInvitation({ dispatch }, data) {
      await make({ name: "ignoreInvitation", data });
      return await dispatch("fetch");
    },
    async getManagers({ state }) {
      const req = await make({
        name: "getManagers",
        params: {
          company_id: state.item.id,
        },
      });
      return req;
    },
    async createEmployeeCheckin({ state }, id) {
      const req = await make({
        name: "createEmployeeCheckin",
        params: {
          company_id: state.item.id,
          id,
        },
      });
      return req;
    },
    async getEmployees({ state }, params) {
      const req = await make({
        name: "getEmployees",
        params: {
          company_id: state.item.id,
          ...params,
        },
      });
      return req;
    },
    async getEmployeesList({ state }) {
      const req = await make({
        name: "getEmployeesList",
        params: {
          company_id: state.item.id,
        },
      });
      return req;
    },
    async getEmployee({ state }, id) {
      const req = await make({
        name: "getEmployee",
        params: {
          company_id: state.item.id,
          id,
        },
      });
      return req;
    },
    async createEmployee({ state }, data) {
      const req = await make({
        name: "createEmployee",
        data: {
          company_id: state.item.id,
          ...data,
        },
      });
      return req;
    },
    async editEmployee({ state }, data) {
      const req = await make({
        name: "editEmployee",
        data: {
          company_id: state.item.id,
          ...data,
        },
      });
      return req;
    },
    async deleteEmployee({ state }, id) {
      const req = await make({
        name: "deleteEmployee",
        data: {
          company_id: state.item.id,
          id,
        },
      });
      return req;
    },
    async inviteEmployee({ state }, data) {
      const req = await make({
        name: "inviteEmployee",
        data: {
          company_id: state.item.id,
          ...data,
        },
      });
      return req;
    },
    async requestEmployee({ state }, id) {
      const req = await make({
        name: "requestEmployee",
        params: {
          company_id: state.item.id,
          id: id,
        },
      });
      return req;
    },
    async unrequestEmployee({ state }, id) {
      const req = await make({
        name: "unrequestEmployee",
        params: {
          company_id: state.item.id,
          id: id,
        },
      });
      return req;
    },
    async bulkUpdateEmployees({ state }, data) {
      const req = await make({
        name: "bulkUpdateEmployees",
        data: {
          ...data,
          company_id: state.item.id,
        },
      });
      return req;
    },
    async getEmployeesCompletedMessages({ state }) {
      const req = await make({
        name: "getEmployeesCompletedMessages",
        params: {
          company_id: state.item.id,
        },
      });
      return req;
    },
    async getCheckins({ state }) {
      const req = await make({
        name: "getCompanyCheckins",
        params: {
          company_id: state.item.id,
        },
      });
      return req;
    },
    async scheduleCheckins({ state }) {
      const req = await make({
        name: "scheduleCompanyCheckins",
        params: {
          company_id: state.item.id,
        },
      });
      return req;
    },
    async translateStrings({ state }, data) {
      return await make({
        name: "translateStrings",
        params: {
          company_id: state.item.id,
        },
        data,
      });
    },
    async uploadEmployeesPreview({ state }, data) {
      return await make({
        name: "uploadEmployeesPreview",
        data: {
          ...data,
          company_id: state.item.id,
        },
      });
    },
    async uploadEmployeesRun({ state }, data) {
      return await make({
        name: "uploadEmployeesRun",
        data: {
          ...data,
          company_id: state.item.id,
        },
      });
    },
    async uploadManagerEmployeesPreview({ state }, data) {
      return await make({
        name: "uploadManagerEmployeesPreview",
        data: {
          ...data,
          company_id: state.item.id,
        },
      });
    },
    async uploadManagerEmployeesRun({ state }, data) {
      return await make({
        name: "uploadManagerEmployeesRun",
        data: {
          ...data,
          company_id: state.item.id,
        },
      });
    },
    async getEmployeeSummary({ state }) {
      return await make({
        name: "getEmployeeSummary",
        data: {
          company_id: state.item.id,
        },
        options: {
          responseType: "blob",
        },
      });
    },
    async getEmployeeManagerSummary({ state }) {
      return await make({
        name: "getEmployeeManagerSummary",
        data: {
          company_id: state.item.id,
        },
        options: {
          responseType: "blob",
        },
      });
    },
    // audiences
    async getAudiences({ state }) {
      const req = await make({
        name: "getCompanyAudiencesList",
        params: {
          company_id: state.item.id,
        },
      });
      return req;
    },
    async getAudience({ state }, id) {
      const req = await make({
        name: "getCompanyAudience",
        params: {
          company_id: state.item.id,
          id,
        },
      });
      return req;
    },
    async createAudience({ state, dispatch }, data) {
      let req;
      try {
        req = await make({
          name: "createCompanyAudience",
          data: {
            company_id: state.item.id,
            ...data,
          },
        });
      } catch (e) {
        console.error("cannot create audience");
        throw e;
      }

      await dispatch("fetch");
      return req;
    },
    async editAudience({ state, dispatch }, data) {
      let req;
      try {
        req = await make({
          name: "editCompanyAudience",
          data: {
            company_id: state.item.id,
            ...data,
          },
        });
      } catch (e) {
        console.error("cannot update audience");
        throw e;
      }

      await dispatch("fetch");
      return req;
    },
    async deleteAudience({ state, dispatch }, id) {
      let req;
      try {
        req = await make({
          name: "deleteCompanyAudience",
          data: {
            company_id: state.item.id,
            id,
          },
        });
      } catch (e) {
        console.error("cannot delete audience");
        throw e;
      }

      await dispatch("fetch");
      return req;
    },
    // locations
    async getLocations({ state }) {
      const req = await make({
        name: "getCompanyLocationsList",
        params: {
          company_id: state.item.id,
        },
      });
      return req;
    },
    async getLocation({ state }, id) {
      const req = await make({
        name: "getCompanyLocation",
        params: {
          company_id: state.item.id,
          id,
        },
      });
      return req;
    },
    async createLocation({ state, dispatch }, data) {
      let req;
      try {
        req = await make({
          name: "createCompanyLocation",
          data: {
            company_id: state.item.id,
            ...data,
          },
        });
      } catch (e) {
        console.error("cannot create location");
        throw e;
      }

      await dispatch("fetch");
      return req;
    },
    async editLocation({ state, dispatch }, data) {
      let req;
      try {
        req = await make({
          name: "editCompanyLocation",
          data: {
            company_id: state.item.id,
            ...data,
          },
        });
      } catch (e) {
        console.error("cannot update location");
        throw e;
      }

      await dispatch("fetch");
      return req;
    },
    async deleteLocation({ state, dispatch }, id) {
      let req;
      try {
        req = await make({
          name: "deleteCompanyLocation",
          data: {
            company_id: state.item.id,
            id,
          },
        });
      } catch (e) {
        console.error("cannot delete location");
        throw e;
      }

      await dispatch("fetch");
      return req;
    },
    async getBillingPortalUrl({ state }, data) {
      return await make({
        name: "getBillingPortalUrl",
        data: {
          ...data,
          company_id: state.item.id,
        },
      });
    },
    async getBillingSummary({ state }, data) {
      return await make({
        name: "getBillingSummary",
        data: {
          ...data,
          company_id: state.item.id,
        },
      });
    },
    async getHomeData({ state }, data) {
      return await make({
        name: "getHomePageData",
        params: {
          ...data,
          company_id: state.item.id,
        },
      });
    },

    async getSelfEmployeeData({ commit, state }) {
      const employee = await make({
        name: "getSelfEmployee",
        params: {
          company_id: state.item.id,
        },
      });

      commit("SET_ITEM_EMPLOYEE", employee);
      return employee;
    },
    async setItem({ state, dispatch }, id) {
      // Needs to set default compmany on user record to properly fetch from the API
      const comp = state.list.find((c) => c.id === id);
      if (comp) {
        // TODO: This is currently not used - should it be removed or shoudl the code be updated?
        const _req = await make({
          name: "setDefaultCompany",
          data: {
            company_id: comp.id,
          },
        });
        // commit("SET_ITEM", comp);

        // setting the default employee above with return "company" on the fetch action above
        await dispatch("fetch");
      }
    },
    clearItem({ commit }) {
      commit("CLEAR_ITEM");
    },
    clearCompanies({ commit }) {
      commit("CLEAR_COMPANIES");
    },
  },
  getters: {
    isLoading: (state) => {
      return state.status.isLoading || state.status.isUpdating;
    },
    get: (state) => {
      return state.list;
    },
    getCompany: (state) => {
      return state?.item?.id ? state.item : null;
    },
    getEmployee: (state) => {
      return state?.itemEmployee?.id ? state.itemEmployee : null;
    },
    getCompanyRole: (state) => {
      return state?.item?.pivot?.role ? state?.item?.pivot?.role : null;
    },
    getCompanyIsAdmin: (state) => {
      return state?.item?.pivot?.role ? state?.item?.pivot?.role === "admin" : false;
    },
    hasMany: (state) => {
      return state.list.length > 1;
    },
    getEmployeeTypes: (state) => {
      return state?.item?.employee_types?.map((type) => ({ label: type, value: type })) ?? [];
    },
    getLocations: (state) => {
      return state?.item?.locations?.map((loc) => ({ label: loc.name, value: loc.id })) ?? [];
    },
    getAudiences: (state) => {
      return state?.item?.audiences?.map((aud) => ({ label: aud.name, value: aud.id })) ?? [];
    },
    getManagers: (state) => {
      return (
        state?.item?.managers
          ?.sort(compareName)
          ?.map((man) => ({ label: man.name, value: man.id })) ?? []
      );
    },
    getDefaultSurveyOptions: (state) => {
      return (
        [
          ...state?.item?.default_survey_options?.map((opt) => ({
            label: opt.label,
            value: opt.key,
            options: opt.options,
          })),
          { label: "Custom", value: "custom", options: [""] },
        ] ?? []
      );
    },
    getCheckinTopics: (state) => {
      return state?.item?.checkin_topics ?? [];
    },
    getDisabledCheckins: (state) => {
      return !state?.item?.flags?.find((o) => o.key === "checkins")?.enabled;
    },
    getFlag: (state) => (key) => {
      return state?.item?.flags?.find((o) => o.key === key)?.enabled ?? false;
    },
  },
};

export default companies;
