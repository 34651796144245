<template>
  <div class="banner-container w-full min-h-10 relative" :class="{ 'cursor-pointer': showCursor }">
    <div :class="bannerClass" class="text-white font-bold text-center">
      {{ label }}
    </div>
  </div>
</template>

<script>
import { computed } from "vue";

export const BANNER_ALERT_LEVELS = {
  INFO: "info",
  ALARM: "alarm",
  WARN: "warn",
};

export default {
  name: "Banner",
  props: {
    label: String,
    alertLevel: String, //info, warn, alarm
    cursor: Boolean,
  },
  setup(props) {
    const bannerClass = computed(() => {
      if (props.alertLevel === "alarm") {
        return "bg-elmo-dark";
      }
      if (props.alertLevel === "warn") {
        return "bg-yellow-500";
      }
      return "bg-gray text-blue";
    });

    const showCursor = computed(() => props.cursor);

    return {
      bannerClass,
      showCursor,
    };
  },
};
</script>
