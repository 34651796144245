import { make } from "@/utils/request";
import Status from "@/classes/Status";

const messages = {
  namespaced: true,
  state: {
    item: null,
    status: new Status(),
    list: [],
  },
  mutations: {
    SET_MESSAGES(state, payload) {
      state.list = payload;
    },
    SET_ITEM(state, payload) {
      if (payload?.id) {
        state.item = payload;
      } else {
        state.item = null;
      }
    },
    CLEAR_MESSAGES(state) {
      state.list = [];
    },
    CLEAR_ITEM(state) {
      state.item = null;
    },
    SET_STATUS(state, payload) {
      state.status.value = payload;
    },
  },
  actions: {
    async fetch({ commit, rootGetters }, data) {
      commit("SET_STATUS", Status.LOADING);

      const req = await make({
        name: "getMessages",
        params: {
          company_id: rootGetters["companies/getCompany"].id,
          ...data,
        },
      });

      commit("SET_MESSAGES", req.messages);
      commit("SET_STATUS", Status.LOADED);

      return req;
    },
    setItem({ commit }, message) {
      if (message) {
        commit("SET_ITEM", message);
      } else {
        commit("CLEAR_ITEM");
      }
    },
    clearItem({ commit }) {
      commit("CLEAR_ITEM");
    },
    async fetchMessage({ rootGetters }, id) {
      const req = await make({
        name: "getMessage",
        params: {
          company_id: rootGetters["companies/getCompany"].id,
          id,
        },
      });

      return req;
    },
    async expireMessage({ rootGetters }, id) {
      const req = await make({
        name: "expireMessage",
        params: {
          company_id: rootGetters["companies/getCompany"].id,
          id,
        },
      });

      return req;
    },
    async archiveMessage({ rootGetters }, id) {
      const req = await make({
        name: "archiveMessage",
        params: {
          company_id: rootGetters["companies/getCompany"].id,
          id,
        },
      });

      return req;
    },
    async deleteMessage({ rootGetters }, id) {
      const req = await make({
        name: "deleteMessage",
        params: {
          company_id: rootGetters["companies/getCompany"].id,
          id,
        },
      });

      return req;
    },
    async createMessage({ rootGetters }, data) {
      const req = await make({
        name: "createMessage",
        data: {
          ...data,
          company_id: rootGetters["companies/getCompany"].id,
        },
      });

      return req;
    },
    async editMessage({ rootGetters }, data) {
      const req = await make({
        name: "editMessage",
        data: {
          ...data,
          company_id: rootGetters["companies/getCompany"].id,
        },
      });

      return req;
    },
    async getFlaggedMessages({ rootGetters }) {
      const req = await make({
        name: "getFlaggedMessages",
        params: {
          company_id: rootGetters["companies/getCompany"].id,
        },
      });

      return req;
    },
    async getFlaggedMessage({ rootGetters }, id) {
      const req = await make({
        name: "getFlaggedMessage",
        params: {
          company_id: rootGetters["companies/getCompany"].id,
          id,
        },
      });

      return req;
    },
    async acknowledgeFlaggedMessage({ rootGetters }, id) {
      const req = await make({
        name: "acknowledgeFlaggedMessage",
        data: {
          company_id: rootGetters["companies/getCompany"].id,
          id,
        },
      });

      return req;
    },
    async getCheckin({ rootGetters }, id) {
      const req = await make({
        name: "getCheckin",
        params: {
          company_id: rootGetters["companies/getCompany"].id,
          id,
        },
      });

      return req;
    },
    async managerAcknowledgeCheckin({ rootGetters }, { id, manager_acknowledgement_data }) {
      const req = await make({
        name: "managerAcknowledgeCheckin",
        data: {
          company_id: rootGetters["companies/getCompany"].id,
          id,
          manager_acknowledgement_data,
        },
      });

      return req;
    },
    async deferCheckin({ rootGetters }, id) {
      const req = await make({
        name: "deferEmployeeCheckin",
        data: {
          company_id: rootGetters["companies/getCompany"].id,
          id,
        },
      });

      return req;
    },
    async getRaisedHand({ rootGetters }, id) {
      const req = await make({
        name: "getRaisedHand",
        params: {
          company_id: rootGetters["companies/getCompany"].id,
          id,
        },
      });

      return req;
    },
    async acknowledgeRaisedHand({ rootGetters }, id) {
      const req = await make({
        name: "acknowledgeRaisedHand",
        data: {
          company_id: rootGetters["companies/getCompany"].id,
          id,
        },
      });

      return req;
    },
    async attachMediaToMessage({ rootGetters }, { id, media_id }) {
      const req = await make({
        name: "attachMediaToMessage",
        data: {
          company_id: rootGetters["companies/getCompany"].id,
          id,
          media_id,
        },
      });

      return req;
    },
    async detachMediaFromMessage({ rootGetters }, { id, media_id }) {
      const req = await make({
        name: "detachMediaFromMessage",
        data: {
          company_id: rootGetters["companies/getCompany"].id,
          id,
          media_id,
        },
      });

      return req;
    },
  },
  getters: {
    isLoading(state) {
      return state.status.isLoading || state.status.isUpdating;
    },
    get(state) {
      return state.list;
    },
    getMessage(state) {
      return state.item;
    },
    getCategories: (state, getters, rootState) => {
      return (
        rootState.companies?.item?.message_categories?.map((c) => ({ label: c, value: c })) ?? []
      );
    },
    getTypes() {
      return [
        { label: "Flaggable 🚩", value: "standard" },
        { label: "Non-flaggable", value: "nonflaggable" },
      ];
    },
  },
};

export default messages;
