import { ref, computed, onMounted, watch } from "vue";
import { defineStore, acceptHMRUpdate, storeToRefs } from "pinia";
import { make } from "@/utils/request";
import { mapEntityToSelect } from "@/utils/helpers";

import { useCompanies } from "./companies";

export const useTags = defineStore("tags", () => {
  const companyStore = useCompanies();
  const { selectedCompany } = storeToRefs(companyStore);

  const rawTags = ref([]);
  const tags = computed(() => {
    if (!rawTags.value || !rawTags?.value.length) {
      return [];
    }

    return rawTags.value.map(mapEntityToSelect("title", "id"));
  });

  const fetchTags = async () => {
    const responseTags = await make({
      name: "tagList",
      params: {
        company_id: selectedCompany.value.id,
      },
    });

    rawTags.value = responseTags;

    return fetchTags;
  };

  const createTag = async (tagDetails) => {
    const tag = await make({
      name: "tagCreate",
      data: {
        company_id: selectedCompany.value.id,
        ...tagDetails,
      },
    });

    rawTags.value.push(tag);

    return tag;
  };

  return { createTag, fetchTags, tags };
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useTags, import.meta.hot));
}
