import {
  isPhoneNumber,
  isAuthCode,
  isNumberString,
  isRequired,
  isEmail,
} from "@/utils/validation/validators";

export const phoneValidation = { phone: { isRequired, isPhoneNumber, isNumberString } };
export const emailValidation = { email: { isRequired, isEmail }, password: { isRequired } };
export const authCodeValidation = { authCode: { isRequired, isAuthCode } };
