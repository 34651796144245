import Toast from "@/classes/Toast";
// import Vue from "vue";

const toasts = {
  namespaced: true,
  state: {
    stack: [],
    index: 1,
  },
  mutations: {
    ADD_TOAST(state, payload) {
      state.index += 1;
      // Vue.set(state, "stack", [...state.stack, payload]);
      state.stack = [...state.stack, payload];
    },
    REMOVE_TOAST(state, payload) {
      let newStack = state.stack.slice();
      const foundIndex = payload.id
        ? newStack.findIndex((val) => val.id === payload.id)
        : newStack.findIndex((val) => val.message === payload.message);
      newStack.splice(foundIndex, 1);
      // Vue.set(state, "stack", [...newStack]);
      state.stack = [...newStack];
    },
    CLEAR_ALL(state) {
      state.stack = [];
      // Vue.set(state, "stack", []);
    },
  },
  actions: {
    add({ commit, state }, payload) {
      let added = payload;
      if (!(payload instanceof Toast)) {
        added = new Toast({ ...payload, id: state.index });
      }

      commit("ADD_TOAST", added);
    },
    remove({ commit }, payload) {
      let removed = payload;
      if (!(payload instanceof Toast)) {
        removed = new Toast(payload);
      }

      commit("REMOVE_TOAST", removed);
    },
  },
  getters: {
    getStack: (state) => state.stack,
  },
};

export default toasts;
