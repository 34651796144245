<template>
  <div>
    <button
      :type="type"
      class="btn"
      :class="[buttonType, buttonSize, { loading, disabled, rounded }]"
      :disabled="disabledComp"
      @click.stop="click"
    >
      <LoaderTailspin v-if="loading" class="mr-2" width="16" height="16" />
      <slot>{{ text }}</slot>
    </button>
  </div>
</template>

<script>
import { computed } from "vue";
import LoaderTailspin from "@/components/LoaderTailspin";

export default {
  name: "Btn",
  components: {
    LoaderTailspin,
  },
  props: {
    type: {
      type: String,
      default: "button",
    },
    text: String,
    variant: {
      type: String,
      default: "primary",
    },
    size: {
      type: String,
      default: null,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    rounded: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const typeList = {
      primary: "btn-primary",
      primaryOutline: "btn-primary-outline",
      secondary: "btn-secondary",
      secondaryOutline: "btn-secondary-outline",
      success: "btn-success",
      successOutline: "btn-outline-success",
      danger: "btn-danger",
      dangerOutline: "btn-danger-outline",
      warning: "btn-warning",
      warningOutline: "btn-outline-warning",
      info: "btn-info",
      infoOutline: "btn-outline-info",
      light: "btn-light",
      lightOutline: "btn-outline-light",
      dark: "btn-dark",
      darkOutline: "btn-outline-dark",
      link: "btn-link",
    };
    const buttonType = computed(() => {
      if (!typeList[props.variant]) {
        return typeList.primaryOutline;
      }

      return typeList[props.variant];
    });
    const sizeList = {
      small: "btn-sm",
      large: "btn-lg",
      block: "btn-block",
    };
    const buttonSize = computed(() => {
      return sizeList[props.size];
    });
    const disabledComp = computed(() => {
      return props.loading || props.disabled;
    });

    const click = (obj) => {
      emit("click", obj);
    };

    return {
      buttonType,
      buttonSize,
      disabledComp,
      click,
    };
  },
};
</script>

<style lang="pcss">
@layer components {
  .btn {
    @apply w-full border bg-pewter text-white flex flex-row justify-center items-center py-2 px-4 outline-none focus:outline-none rounded-sm;
  }

  .btn-primary {
    @apply bg-grimace border-grimace text-white;
  }

  .btn-primary.loading,
  .btn-primary.disabled {
    @apply bg-grimace-light border-grimace-light;
  }

  .btn-primary-outline {
    @apply bg-white text-grimace border-grimace;
  }

  .btn-primary-outline.loading,
  .btn-primary-outline.disabled {
    @apply text-grimace-light border-grimace-light;
  }

  .btn-outline-info {
    @apply bg-white text-black border-black;
  }

  .btn-outline-info.loading,
  .btn-outline-info.disabled {
    @apply text-black-light border-black-light;
  }

  .btn-sm {
    @apply text-sm;
  }

  .btn-danger {
    @apply text-white bg-red-600 border-red-600;
  }

  .btn-danger.loading,
  .btn-danger.disabled {
    @apply text-white border-red-600 bg-red-400;
  }

  .btn-danger-outline {
    @apply bg-white text-red-600 border-red-600;
  }

  .btn-danger-outline.loading,
  .btn-danger-outline.disabled {
    @apply text-red-300 border-red-300;
  }

  .btn-secondary-outline {
    @apply bg-white text-yellow-500 border-yellow-500;
  }

  .btn-secondary-outline.loading,
  .btn-secondary-outline.disabled {
    @apply text-yellow-300 border-yellow-300;
  }
}

/* primary: "btn-primary",
      primaryOutline: "btn-outline-primary",
      secondary: "btn-secondary",
      secondaryOutline: "btn-outline-secondary",
      success: "btn-success",
      successOutline: "btn-outline-success",
      danger: "btn-danger",
      dangerOutline: "btn-outline-danger",
      warning: "btn-warning",
      warningOutline: "btn-outline-warning",
      info: "btn-info",
      infoOutline: "btn-outline-info",
      light: "btn-light",
      lightOutline: "btn-outline-light",
      dark: "btn-dark",
      darkOutline: "btn-outline-dark",
      link: "btn-link", */
</style>
