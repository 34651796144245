import { make } from "@/utils/request";
import Status from "@/classes/Status";

const media = {
  namespaced: true,
  state: {
    item: null,
    status: new Status(),
    list: [],
  },
  mutations: {},
  actions: {
    async getMedia({ rootGetters }, data) {
      const req = await make({
        name: "getMedia",
        params: {
          ...data,
          company_id: rootGetters["companies/getCompany"].id,
        },
      });

      return req;
    },
    async uploadFile({ rootGetters }, data) {
      const req = await make({
        name: "uploadFile",
        data: {
          ...data,
          company_id: rootGetters["companies/getCompany"].id,
        },
      });

      return req;
    },
  },
  getters: {},
};

export default media;
