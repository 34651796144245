<template>
  <base-input :name="name" :errors="errors" :label="label">
    <input
      v-if="type === 'auth-code'"
      v-maska
      class="form-control"
      type="text"
      :data-maska="'******'"
      :class="{ 'invalid-input': errors && errors.length > 0, 'font-bold': bold }"
      :name="name"
      placeholder=""
      :disabled="disabled"
      autocomplete="off"
      :value="modelValue"
      @input="updateInputValue"
    />
    <input
      v-else-if="type === 'tel'"
      v-maska
      class="form-control"
      :type="type"
      :data-maska="inputMask"
      :class="{ 'invalid-input': errors && errors.length > 0, 'font-bold': bold }"
      :name="name"
      :placeholder="placeholder"
      :disabled="disabled"
      :autocomplete="autocomplete"
      :value="modelValue"
      @input="updateInputValue"
    />
    <input
      v-else
      class="form-control"
      :type="type"
      :class="{ 'invalid-input': errors && errors.length > 0, 'font-bold': bold }"
      :name="name"
      :placeholder="placeholder"
      :disabled="disabled"
      :autocomplete="autocomplete"
      :value="modelValue"
      @input="updateInputValue"
    />
  </base-input>
</template>

<script>
import BaseInput from "@/components/Form/BaseInput";
import constants from "@/utils/constants";

const { HTML_TEXT_INPUT_TYPES, CUSTOM_TEXT_INPUT_TYPES } = constants;

const determineInputMask = (inputType) => {
  switch (inputType) {
    case "tel":
      return "(###)-###-####";
    case "auth-code":
      return "******";
    default:
      return null;
  }
};

export default {
  name: "TextInput",
  components: {
    BaseInput,
  },
  props: {
    autocomplete: {
      type: String,
      default: "on",
      validator: (autocompleteValue) => ["on", "off"].includes(autocompleteValue),
    },
    bold: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    errors: {
      type: Array,
      default: () => [],
    },
    label: {
      type: String,
    },
    modelValue: {
      type: [String, Number],
      default: "",
    },
    name: {
      type: String,
      validator: (inputName) => inputName && inputName.length > 0,
    },
    placeholder: {
      type: String,
    },
    type: {
      type: String,
      default: "text",
      validator: (inputType) =>
        HTML_TEXT_INPUT_TYPES.concat(CUSTOM_TEXT_INPUT_TYPES).includes(inputType),
    },
  },
  emits: ["update:modelValue"],
  setup(props, { emit }) {
    const inputMask = determineInputMask(props.type);

    const updateInputValue = (evt) => {
      const inputValue =
        props.type === "auth-code" ? evt.target.value.toUpperCase() : evt.target.value;
      emit("update:modelValue", inputValue);
    };

    return { inputMask, updateInputValue };
  },
};
</script>

<style lang="postcss" scoped>
@layer components {
  .invalid-input {
    @apply text-elmo;
  }
}
</style>
