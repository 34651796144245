import { DateTime } from "luxon";
import { uniqBy, capitalize, set, isEmpty } from "lodash-es";

// https://daringfireball.net/2010/07/improved_regex_for_matching_urls
const URL_REGEX =
  "\\b((?:[a-z][\\w-]+:(?:\\/{1,3}|[a-z0-9%])|www\\d{0,3}[.]|[a-z0-9.\\-]+[.][a-z]{2,4}\\/)(?:[^\\s()<>]+|\\(([^\\s()<>]+|(\\([^\\s()<>]+\\)))*\\))+(?:\\(([^\\s()<>]+|(\\([^\\s()<>]+\\)))*\\)|[^\\s`!()\\[\\]{};:'\"]))";
const PHONE_REGEX = "(\\([0-9]{3}\\)\\s|[0-9]{3}-)?[0-9]{3}-[0-9]{4}";

export const replaceNonDigitChars = (str) => str.replace(/[^\d]/g, "");
export const replaceNonWordChars = (str) => str.replace(/\W/g, "");

export const formatPhone = (phone) => {
  // if input value is falsy return
  if (!phone) {
    return phone;
  }

  // clean the input for any non-digit values.
  const phoneNumber = replaceNonDigitChars(phone);

  // phoneNumberLength is used to know when to apply our formatting for the phone number
  const phoneNumberLength = phoneNumber.length;

  // we need to return the value with no formatting if its less then four digits
  // this is to avoid weird behavior that occurs if you  format the area code to early

  if (phoneNumberLength < 4) {
    return phoneNumber;
  }

  // if phoneNumberLength is greater than 4 and less the 7 we start to return
  // the formatted number
  if (phoneNumberLength < 7) {
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
  }

  // finally, if the phoneNumberLength is greater then seven, we add the last
  // bit of formatting and return it.
  return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`;
};

export const formatDates = (
  date,
  timezone = "America/Chicago",
  format = DateTime.DATETIME_SHORT
) => {
  // previous format: format = "yyyy-LL-dd h:mm a"
  return date ? DateTime.fromISO(date).setZone(timezone).toLocaleString(format) : null;
};

export const getFirstAndLastName = (fullName) => {
  if (!fullName || typeof fullName !== "string") {
    return "";
  }

  const [firstName, ...remaining] = fullName.split(" ") || [];
  const lastName = remaining.join(" ");

  return { firstName, lastName };
};

export const generateUserCompanyName = (firstName = "") => {
  return firstName ? `${firstName}'s Team` : "My Team";
};

export const findAllInString = (matchTest, str, matches = []) => {
  const match = matchTest.exec(str);

  if (!match) {
    return matches;
  }

  const currentMatch = {
    matchText: match[0],
  };

  return findAllInString(matchTest, str, [...matches, currentMatch]);
};

// Finds all dynamic content matchers in message content
// and returns them grouped by which expression was matched
export const findDynamicContent = (messageContent) => {
  const matchers = [
    {
      type: "url",
      test: new RegExp(URL_REGEX, "gi"),
      replacer: (matchStr) =>
        `<a hrel="noopener noreferrer" target="_blank" href="https://${matchStr}">${matchStr}</a>`,
    },
    {
      type: "telephone",
      test: new RegExp(PHONE_REGEX, "g"),
      replacer: (matchStr) => `<a href="tel:${replaceNonWordChars(matchStr)}">${matchStr}</a>`,
    },
  ];
  const regex = /https?:\/\//i;
  return matchers
    .map((matcher) => {
      const allMatches = findAllInString(matcher.test, messageContent).map((match) => {
        // Strip out the protocol from all matches to avoid replacing content twice
        // when two urls are used (i.e. https://www.google.com and www.google.com)
        if (matcher.type === "url" && match.matchText.match(regex)) {
          return { ...match, matchText: match.matchText.replace(regex, "") };
        }
        return match;
      });

      return {
        ...matcher,
        matches: uniqBy(allMatches, "matchText"),
      };
    })
    .filter((matcher) => matcher.matches.length > 0);
};

const processContentMatcher = (replacer) => (matchContent, matcher) => {
  const { matchText } = matcher;
  return matchContent.replaceAll(matchText, replacer);
};

export const replaceDynamicMessageContent = (messageContent) => {
  const groupedDynamicContent = findDynamicContent(messageContent);

  if (!groupedDynamicContent.length) {
    return messageContent;
  }
  const regex = /https?:\/\//gi;
  return groupedDynamicContent.reduce((newMessageContent, contentMatches) => {
    return contentMatches.matches.reduce(
      processContentMatcher(contentMatches.replacer),
      newMessageContent
    );
  }, messageContent.replaceAll(regex, ""));
};

export const mapEntityToSelect = (labelProp, valueProp) => (entity) => {
  return {
    label: entity[labelProp],
    value: entity[valueProp],
  };
};

export const generateTagPlaceholder = (tagType) => (tagList) =>
  tagList?.length ? `Search for ${tagType}` : `All ${capitalize(tagType)}s`;

// need this to be "management"
export const employeeIsManagement = (employee) => employee?.value?.role !== "employee";
export const employeeIsManager = (employee) => employee?.value?.role === "manager";
export const employeeIsAdmin = (employee) => employee?.value?.role === "admin";

// Replaces an item in a list of items to preserve reactivity
export const replaceItemInStore = (newItem, itemList) => {
  const itemIdx = itemList.findIndex((existingItem) => existingItem.id === newItem.id);
  itemList.splice(itemIdx, 1, newItem);

  return itemList;
};

export const convertDateToISO = (date, timezone = "America/Chicago", format = "yyyy-LL-dd") => {
  if (!date) {
    return null;
  }

  return DateTime.fromFormat(date, format, { zone: timezone }).endOf("day").setZone("UTC").toISO();
};

export const convertISOToDisplayDate = (
  date,
  timezone = "America/Chicago",
  format = "yyyy-LL-dd"
) => {
  if (!date) {
    return null;
  }

  return DateTime.fromISO(date).setZone(timezone).toFormat(format);
};

export const delay = async (timeout = 100) => {
  await new Promise((resolve) => setTimeout(resolve, timeout));
};

export const stripHtmlInString = (str) => {
  if (!str) {
    return null;
  }

  return str.trim().replace(/<(.|\n)*?>/g, "");
};

export const compareEntityByName = (firstEntity, secondEntity) => {
  if (firstEntity.name > secondEntity.name) {
    return 1;
  }

  if (secondEntity.name > firstEntity.name) {
    return -1;
  }

  return 0;
};

/*
📣 These utils will be part of json-schema-form soon
*/

/**
 * Convert Form values to JSON values
 * Otherwise it will cause unexpected errors, such as
 * - number fields: { age: "5" } -> The value "5" must be a number.
 * - empty number fields: { age: "" } -> The value "" must be a number.
 * etc....
 */
export function formValuesToJsonValues(values, fields) {
  const fieldTypeTransform = {
    number: (val) => (val === "" ? val : +val),
    text: (val) => val,
    "group-array": (val) =>
      val.map((v) => {
        const keyValPairs = Object.entries(v).filter(([key, value], index) => {
          return value !== "";
        });
        return Object.fromEntries(keyValPairs);
      }),
    // TODO support all types
  };

  const jsonValues = {};

  fields.forEach(({ name, type, isVisible }) => {
    const formValue = values[name];

    const transformedValue = fieldTypeTransform[type]
      ? fieldTypeTransform[type](formValue)
      : formValue;

    if (
      transformedValue === "" ||
      transformedValue === null ||
      transformedValue === undefined ||
      isEmpty(transformedValue)
    ) {
      // Omit empty fields from payload to avoid type error.
      // eg { team_size: "" } -> The value ("") must be a number.
    } // else if (!isVisible) { I dont currently care about visisbility of items on the forms
    //   // Omit invisible (conditional) fields to avoid erro:
    //   // eg { account: "personal", team_size: 3 } -> The "team_size" is invalid
    // }
    else {
      set(jsonValues, name, transformedValue);
    }
  });

  return jsonValues;
}

/**
 * Set the initial values for the UI (controlled) components
 * based on the JSON Schema structure
 */
export function getDefaultValuesFromFields(fields) {
  return fields.reduce((acc, cur) => {
    return {
      ...acc,
      [cur.name]: cur.default || "",
    };
  }, {});
}

export const downloadCSV = (csv, filename) => {
  const blob = new Blob([csv], { type: "text/csv" });
  const url = URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.setAttribute("href", url);
  a.setAttribute("download", filename);
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};
