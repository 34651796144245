import { make } from "@/utils/request";
import Status from "@/classes/Status";

const surveys = {
  namespaced: true,
  state: {
    item: null,
    status: new Status(),
    list: [],
  },
  mutations: {
    SET_SURVEYS(state, payload) {
      state.list = payload;
    },
    SET_ITEM(state, payload) {
      if (payload?.id) {
        state.item = payload;
      } else {
        state.item = null;
      }
    },
    CLEAR_SURVEYS(state) {
      state.list = [];
    },
    CLEAR_ITEM(state) {
      state.item = null;
    },
    SET_STATUS(state, payload) {
      state.status.value = payload;
    },
  },
  actions: {
    async fetch({ commit, rootGetters }, data) {
      commit("SET_STATUS", Status.LOADING);

      const req = await make({
        name: "getSurveys",
        params: {
          company_id: rootGetters["companies/getCompany"].id,
          ...data,
        },
      });

      commit("SET_SURVEYS", req.surveys);
      commit("SET_STATUS", Status.LOADED);

      return req;
    },
    setItem({ commit }, survey) {
      if (survey) {
        commit("SET_ITEM", survey);
      } else {
        commit("CLEAR_ITEM");
      }
    },
    clearItem({ commit }) {
      commit("CLEAR_ITEM");
    },
    async fetchSurvey({ rootGetters }, id) {
      const req = await make({
        name: "getSurvey",
        params: {
          company_id: rootGetters["companies/getCompany"].id,
          id,
        },
      });

      return req;
    },
    async expireSurvey({ rootGetters }, id) {
      const req = await make({
        name: "expireSurvey",
        params: {
          company_id: rootGetters["companies/getCompany"].id,
          id,
        },
      });

      return req;
    },
    async deleteSurvey({ rootGetters }, id) {
      const req = await make({
        name: "deleteSurvey",
        params: {
          company_id: rootGetters["companies/getCompany"].id,
          id,
        },
      });

      return req;
    },
    async createSurvey({ rootGetters }, data) {
      const req = await make({
        name: "createSurvey",
        data: {
          ...data,
          company_id: rootGetters["companies/getCompany"].id,
        },
      });

      return req;
    },
    async editSurvey({ rootGetters }, data) {
      const req = await make({
        name: "editSurvey",
        data: {
          ...data,
          company_id: rootGetters["companies/getCompany"].id,
        },
      });

      return req;
    },
    async getSurveySummary({ rootGetters }, id) {
      const req = await make({
        name: "getSurveySummary",
        data: {
          id,
          company_id: rootGetters["companies/getCompany"].id,
        },
        options: {
          responseType: "blob",
        },
      });

      return req;
    },
    async getSurveyResponses({ rootGetters }, id) {
      const req = await make({
        name: "getSurveyResponses",
        data: {
          id,
          company_id: rootGetters["companies/getCompany"].id,
        },
        options: {
          responseType: "blob",
        },
      });

      return req;
    },
    async attachMediaToSurvey({ rootGetters }, { id, media_id }) {
      const req = await make({
        name: "attachMediaToSurvey",
        data: {
          company_id: rootGetters["companies/getCompany"].id,
          id,
          media_id,
        },
      });

      return req;
    },
    async detachMediaFromSurvey({ rootGetters }, { id, media_id }) {
      const req = await make({
        name: "detachMediaFromSurvey",
        data: {
          company_id: rootGetters["companies/getCompany"].id,
          id,
          media_id,
        },
      });

      return req;
    },
  },
  getters: {
    isLoading(state) {
      return state.status.isLoading || state.status.isUpdating;
    },
    get(state) {
      return state.list;
    },
    getSurvey(state) {
      return state.item;
    },
    getCategories: (_state, _getters, rootState) => {
      return (
        rootState.companies?.item?.survey_categories?.map((c) => ({ label: c, value: c })) ?? []
      );
    },
    getTypes() {
      return [{ label: "Default", value: "standard" }];
    },
  },
};

export default surveys;
