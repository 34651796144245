import { ref, computed } from "vue";
import { defineStore, acceptHMRUpdate } from "pinia";
import { make } from "@/utils/request";
import { useAppConfig } from "./app-config";
import { useAuth } from "./auth";

export const useCompanies = defineStore("companies", () => {
  const appConfigStore = useAppConfig();
  const authStore = useAuth();
  const celebrations = ref(null);

  const { setShowAppBanner } = appConfigStore;

  const selectedCompany = ref(null);
  const company = ref(null);
  const companies = ref([]);
  const companyEmployeeId = ref(null);

  const isCompanyAdmin = computed(() => selectedCompany?.value?.pivot?.role === "admin" || false);
  const isCompanyManager = computed(() => {
    return (selectedCompany?.value && selectedCompany?.value?.pivot?.role !== "employee") || false;
  });
  const isCompanyManagement = computed(() =>
    Boolean(isCompanyAdmin?.value || isCompanyManager?.value)
  );
  const companySurveyOptions = computed(() => {
    if (!selectedCompany?.value.default_survey_options.length > 0) {
      return [];
    }

    const selectSurveyOptions = selectedCompany.value.default_survey_options.map(
      (surveyOption) => ({
        label: surveyOption.label,
        value: surveyOption.key,
        options: surveyOption.options,
      })
    );

    return [...selectSurveyOptions, { label: "Custom", value: "custom", options: [""] }];
  });
  const hasManyCompanies = computed(() => companies.value.length > 1);
  const companyFeatureFlag = computed(() => (flagName) => {
    if (!selectedCompany?.value) {
      return false;
    }

    return selectedCompany.value.flags?.find((flag) => flag.key === flagName)?.enabled || false;
  });

  const setSelectedCompanyById = async (companyId) => {
    const respCompanies = await make({
      name: "getCompanyDetails",
      params: { company_id: companyId },
    });

    const newCompany = respCompanies.company;

    if (!newCompany) {
      return null;
    }

    selectedCompany.value = newCompany;
    company.value = respCompanies;

    // setup app banner
    setShowAppBanner(authStore.user, selectedCompany.value, authStore.minAppVersion);

    return newCompany;
  };

  const refreshSelectedCompany = async () => {
    if (!selectedCompany.value) {
      return null;
    }

    const respCompanies = await make({
      name: "getCompanyDetails",
      params: { company_id: selectedCompany.value.id },
    });

    const newCompany = respCompanies.company;

    if (!newCompany) {
      return null;
    }

    selectedCompany.value = newCompany;
    company.value = respCompanies;

    return newCompany;
  };

  const fetchCompanies = async () => {
    const response = await make({ name: "settingsMe" });

    if (!response || !response.companies) {
      return null;
    }

    if (response.companies) {
      companies.value = response.companies;
    }

    return response.companies;
  };

  const translateStrings = async (translationData) => {
    const translatedStrings = await make({
      name: "translateStrings",
      params: { company_id: selectedCompany.value.id },
      data: translationData,
    });

    return translatedStrings;
  };

  const createCompany = async (companyDetails) => {
    const { newCompany } = await make({ name: "createTeam", data: companyDetails });

    // We have to re-fetch here because the data we get back from the create API
    // call is inconsistent with the fetch list :/
    // TODO: we should update all API calls to return the same entities
    await fetchCompanies();

    return newCompany;
  };

  const updateCompanyFeatureFlags = async (featureFlags) => {
    const updatedFlags = await make({
      name: "settingsFlagsUpdate",
      params: {
        company_id: selectedCompany.value.id,
      },
      data: {
        flags: featureFlags,
      },
    });

    selectedCompany.value.flags = updatedFlags;

    return updatedFlags;
  };

  const getCompanyLocationsByEmployee = async () => {
    const locations = await make({
      name: "getCompanyLocationsByEmployee",
      params: {
        company_id: selectedCompany.value?.id,
      },
    });

    return locations;
  };

  const getCompanyEmployeesCelebrations = async () => {
    const celebrations = await make({
      name: "getEmployeesCelebrations",
      data: {
        company_id: selectedCompany.value?.id
      },
    });

    celebrations.value = celebrations;

    return celebrations;
  }

  const reset = () => {
    companies.value = [];
    company.value = null;
    selectedCompany.value = null;
  };

  return {
    companies,
    companyEmployeeId,
    companyFeatureFlag,
    companySurveyOptions,
    createCompany,
    fetchCompanies,
    hasManyCompanies,
    isCompanyAdmin,
    isCompanyManagement,
    isCompanyManager,
    selectedCompany,
    translateStrings,
    updateCompanyFeatureFlags,
    setSelectedCompanyById,
    getCompanyLocationsByEmployee,
    reset,
    company,
    refreshSelectedCompany,
    celebrations,
    getCompanyEmployeesCelebrations,
  };
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useCompanies, import.meta.hot));
}
