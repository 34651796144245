<template>
  <div class="toast-container" aria-live="polite" aria-atomic="true">
    <Toast v-for="toast in toasts" :key="toast.id" :toast="toast" />
  </div>
</template>

<script>
import Toast from "@/components/Toast";
import { useToasts } from "@/stores/toasts";
import { storeToRefs } from "pinia";

export default {
  name: "ToastService",
  components: {
    Toast,
  },
  setup() {
    const toastsStore = useToasts();

    const { toasts } = storeToRefs(toastsStore);

    return {
      toasts,
    };
  },
};
</script>

<style lang="postcss">
.toast-container {
  position: fixed;
  overflow: visible;
  bottom: 2rem;
  z-index: 2060;
  right: 2rem;
  width: 300px;
}
</style>
