import { defineStore, acceptHMRUpdate, storeToRefs } from "pinia";
import { make } from "@/utils/request";
import { useCompanies } from "./companies";
import Audience from "@/classes/Audience";
import { computed } from "vue";
import { mapEntityToSelect } from "@/utils/helpers";

export const useCompanyAudiences = defineStore("companies-audiences", () => {
  const companyStore = useCompanies();
  const { selectedCompany, company } = storeToRefs(companyStore);

  const companyAudienceSelectOptions = computed(() => {
    return company.value?.audiences?.map(mapEntityToSelect("name", "id"));
  });

  const createAudience = async (audienceDetails) => {
    const audience = await make({
      name: "createCompanyAudience",
      data: {
        company_id: selectedCompany.value.id,
        ...audienceDetails,
      },
    });

    const updatedAudience = audience ? new Audience(audience) : null;

    return updatedAudience;
  };

  const deleteAudience = async (audienceId) => {
    const response = await make({
      name: "deleteCompanyAudience",
      data: {
        company_id: selectedCompany.value.id,
        id: audienceId,
      },
    });

    return response;
  };

  const fetchAudience = async (audienceId) => {
    const response = await make({
      name: "getCompanyAudience",
      params: {
        company_id: selectedCompany.value.id,
        id: audienceId,
      },
    });

    return response;
  };

  const fetchAudiences = async () => {
    const audiences = await make({
      name: "getCompanyAudiencesList",
      params: {
        company_id: selectedCompany.value.id,
      },
    });

    return audiences;
  };

  const updateAudience = async (audienceDetails) => {
    const audience = await make({
      name: "editCompanyAudience",
      data: {
        company_id: selectedCompany.value.id,
        ...audienceDetails,
      },
    });

    const updatedAudience = audience ? new Audience(audience) : null;

    return updatedAudience;
  };

  return {
    createAudience,
    deleteAudience,
    fetchAudience,
    fetchAudiences,
    updateAudience,
    companyAudienceSelectOptions,
  };
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useCompanyAudiences, import.meta.hot));
}
