import { v4 as uuidv4 } from "uuid";
import { ref } from "vue";
import { defineStore, acceptHMRUpdate } from "pinia";
import Toast from "@/classes/Toast";

export const useToasts = defineStore("toasts", () => {
  const toasts = ref([]);

  const addToast = (toast) => {
    const isToast = toast instanceof Toast;

    const newToast = isToast ? toast : new Toast({ ...toast, id: uuidv4() });

    toasts.value.push(newToast);
  };

  const deleteToast = (toastId) => {
    toasts.value = toasts.value.filter((toast) => toast.id !== toastId);
  };

  return { addToast, deleteToast, toasts };
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useToasts, import.meta.hot));
}
