import { DateTime } from "luxon";

const sortById = (a, b) => b.id - a.id;

class ChatGroup {
  constructor(data = {}) {
    this.id = data?.id;
    this.name = data?.name;
    this.icon = data?.icon;
    this.created_at = data?.created_at;
    this.updated_at = data?.updated_at;
    this.unread_count = data?.unread_count;
    this.latest_message = data?.latest_message ? data.latest_message : null;
    this.audience_ids = data?.audience_ids ?? [];
    this.location_ids = data?.location_ids ?? [];
    this.recipient_ids = data?.recipient_ids ?? [];
    this.created_at_display = data?.created_at
      ? DateTime.fromISO(data.created_at.replace(" ", "T")).toLocaleString(DateTime.DATE_SHORT)
      : null;

    this.messages = (data?.messages || []).sort(sortById);
    this.is_private = data?.is_private;
    this.is_notifications_active = data?.is_notifications_active;
  }
}

export default ChatGroup;
