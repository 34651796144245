import { make } from "@/utils/request";
import Status from "@/classes/Status";

const memos = {
  namespaced: true,
  state: {
    item: null,
    status: new Status(),
    list: [],
    websocketLatest: null,
  },
  mutations: {
    SET_MEMOS(state, payload) {
      state.list = payload;
    },
    SET_ITEM(state, payload) {
      if (payload?.id) {
        state.item = payload;
      } else {
        state.item = null;
      }
    },
    UPDATE_WEBSOCKET(state, payload) {
      if (payload?.id) {
        state.websocketLatest = payload;
      } else {
        state.websocketLatest = null;
      }
    },
    CLEAR_MEMOS(state) {
      state.list = [];
    },
    CLEAR_ITEM(state) {
      state.item = null;
    },
    SET_STATUS(state, payload) {
      state.status.value = payload;
    },
  },
  actions: {
    async list({ commit, rootGetters }, data) {
      commit("SET_STATUS", Status.LOADING);

      const req = await make({
        name: "memoList",
        params: {
          company_id: rootGetters["companies/getCompany"].id,
        },
        data,
      });

      commit("SET_MEMOS", req.memos);
      commit("SET_STATUS", Status.LOADED);

      return req;
    },
    async get({ rootGetters }, id) {
      const req = await make({
        name: "memoGet",
        params: {
          company_id: rootGetters["companies/getCompany"].id,
          id,
        },
      });

      return req;
    },
    async create({ rootGetters }, data) {
      const req = await make({
        name: "memoCreate",
        data: {
          company_id: rootGetters["companies/getCompany"].id,
          ...data,
        },
      });

      return req;
    },
    async update({ rootGetters }, data) {
      const req = await make({
        name: "memoUpdate",
        data: {
          company_id: rootGetters["companies/getCompany"].id,
          ...data,
        },
      });

      return req;
    },
    async delete({ rootGetters }, id) {
      const req = await make({
        name: "memoDelete",
        data: {
          company_id: rootGetters["companies/getCompany"].id,
          id,
        },
      });

      return req;
    },
    async view({ rootGetters }, id) {
      const req = await make({
        name: "memoView",
        data: {
          company_id: rootGetters["companies/getCompany"].id,
          id,
        },
      });

      return req;
    },
    async recipients({ rootGetters }, data) {
      const req = await make({
        name: "memoRecipients",
        data: {
          company_id: rootGetters["companies/getCompany"].id,
          ...data,
        },
      });

      return req;
    },
    updateWebsocket({ commit }, data) {
      commit("UPDATE_WEBSOCKET", data);
    },
    clearItem({ commit }) {
      commit("CLEAR_ITEM");
    },
  },
  getters: {
    isLoading(state) {
      return state.status.isLoading || state.status.isUpdating;
    },
  },
};

export default memos;
