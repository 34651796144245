import { make } from "@/utils/request";

const settings = {
  namespaced: true,
  state: {},
  mutations: {},
  actions: {
    async me({ rootGetters }, data) {
      const req = await make({
        name: "settingsMe",
        params: {
          company_id: rootGetters["companies/getCompany"].id,
          ...data,
        },
      });

      return req;
    },
    async audiences({ rootGetters }, data) {
      const req = await make({
        name: "settingsAudiences",
        params: {
          company_id: rootGetters["companies/getCompany"].id,
          ...data,
        },
      });

      return req;
    },
    async locations({ rootGetters }, data) {
      const req = await make({
        name: "settingsLocations",
        params: {
          company_id: rootGetters["companies/getCompany"].id,
          ...data,
        },
      });

      return req;
    },
    async tags({ rootGetters }, data) {
      const req = await make({
        name: "settingsTags",
        params: {
          company_id: rootGetters["companies/getCompany"].id,
          ...data,
        },
      });

      return req;
    },
    async employees({ rootGetters }, data) {
      const req = await make({
        name: "settingsEmployees",
        params: {
          company_id: rootGetters["companies/getCompany"].id,
          ...data,
        },
      });

      return req;
    },
    async flagsUpdate({ rootGetters }, data) {
      const req = await make({
        name: "settingsFlagsUpdate",
        params: {
          company_id: rootGetters["companies/getCompany"].id,
        },
        data,
      });

      return req;
    },
  },
  getters: {},
};

export default settings;
