import { make } from "@/utils/request";
import Status from "@/classes/Status";
import ChatGroup from "@/classes/ChatGroup";
import ChatMessage from "@/classes/ChatMessage";

const chat = {
  namespaced: true,
  state: {
    item: null,
    status: new Status(),
    list: [],
  },
  mutations: {
    SET_GROUPS(state, payload) {
      state.list = payload?.length
        ? payload.map((grp) => {
            const prevGroup = state.list?.find((stgrp) => grp.id == stgrp.id) ?? {};
            const messages = payload?.clear ? [] : prevGroup.messages;
            return new ChatGroup({ ...grp, messages });
          })
        : [];
    },
    SET_ITEM(state, payload) {
      if (payload?.id) {
        state.item = payload;
      } else {
        state.item = null;
      }
    },
    SET_MESSAGES(state, payload) {
      const prevMessages = state.list?.find((grp) => grp.id == payload.id)?.messages ?? [];
      const newMessages = payload?.messages?.map((msg) => new ChatMessage(msg)) ?? [];
      const msgIds = new Set(prevMessages.map((d) => d.id));

      const allMsgs = [
        ...prevMessages.map((msg) => {
          let foundMsg = newMessages.find((mg) => mg.id == msg.id);
          return foundMsg ? new ChatMessage(foundMsg) : msg;
        }),
        ...newMessages.filter((d) => !msgIds.has(d.id)),
      ];

      state.list = [
        ...state.list.map((grp) => {
          if (grp.id == payload.id) {
            return new ChatGroup({ ...grp, messages: allMsgs });
          }

          return new ChatGroup(grp);
        }),
      ];
    },
    DELETE_MESSAGE(state, payload) {
      const oldMessages = state.list?.find((grp) => grp.id == payload.id)?.messages ?? [];
      const allMessages = oldMessages.filter((msg) => msg.id != payload.messageId);
      state.list = state.list.map((grp) => {
        if (grp.id === payload.id) {
          return new ChatGroup({ ...grp, messages: allMessages });
        }

        return new ChatGroup(grp);
      });
    },
    CLEAR_ITEM(state) {
      state.item = null;
    },
    SET_STATUS(state, payload) {
      state.status.value = payload;
    },
  },
  actions: {
    async getGroups({ commit, rootGetters }) {
      commit("SET_STATUS", Status.LOADING);

      let req;
      try {
        req = await make({
          name: "getChatGroups",
          data: {
            company_id: rootGetters["companies/getCompany"].id,
          },
        });
      } catch (e) {
        console.error(e);
      }

      commit("SET_GROUPS", req);
      commit("SET_STATUS", Status.LOADED);

      return req;
    },
    async create({ rootGetters }, data) {
      let req;
      if (data.is_private) {
        req = await make({
          name: "createPrivateChatGroup",
          data: {
            company_id: rootGetters["companies/getCompany"].id,
            ...data,
          },
        });
      } else {
        req = await make({
          name: "createChatGroup",
          data: {
            company_id: rootGetters["companies/getCompany"].id,
            ...data,
          },
        });
      }

      return req;
    },
    async update({ rootGetters }, data) {
      let req;

      req = await make({
        name: "updateChatGroup",
        params: {
          group_id: data?.id,
        },
        data: {
          company_id: rootGetters["companies/getCompany"].id,
          ...data,
        },
      });

      return req;
    },
    async getGroupMessages({ commit, rootGetters }, { id, last }) {
      // {
      //     "errors": [],
      //     "successes": [],
      //     "content": {
      //         "messages": [
      //             {
      //                 "id": 28,
      //                 "chat_group_id": 8,
      //                 "employee_id": 14,
      //                 "content": "Test message",
      //                 "message_id": null,
      //                 "survey_id": null,
      //                 "celebration_id": null,
      //                 "filtered_at": null,
      //                 "created_at": "2023-03-27T18:04:15.000000Z",
      //                 "updated_at": "2023-03-27T18:04:15.000000Z",
      //                 "chat_message_id": null,
      //                 "image_path": null,
      //                 "deleted_at": null,
      //                 "memo_id": null,
      //                 "image_path_min": null,
      //                 "image_path_thumb": null,
      //                 "pinned_at": null,
      //                 "pinned_by": null,
      //                 "image": null,
      //                 "reactions": [],
      //                 "primary": null,
      //                 "receipts": [
      //                     {
      //                         "id": 30,
      //                         "chat_message_id": 28,
      //                         "employee_id": 14,
      //                         "reactions": null,
      //                         "created_at": "2023-03-27T18:04:15.000000Z",
      //                         "updated_at": "2023-03-27T18:04:15.000000Z"
      //                     }
      //                 ],
      //                 "employee": {
      //                     "id": 14,
      //                     "company_id": 3,
      //                     "name": "Blake Johnston",
      //                     "name_short": "Blake",
      //                     "name_first": "Blake",
      //                     "name_last": "Johnston",
      //                     "phone": "4797996368",
      //                     "phone_country_code": "1",
      //                     "email": "johnstonbl01@gmail.com",
      //                     "deleted_at": null,
      //                     "role": "admin",
      //                     "time_employed": null,
      //                     "default_locale_label": null,
      //                     "is_management": true,
      //                     "is_admin": true,
      //                     "display_name": "Blake Johnston"
      //                 }
      //             }
      //         ]
      //     },
      //     "token": null,
      //     "min_app_version": {
      //         "kiosk": "1.9.5",
      //         "mobile": "1.12.0",
      //         "web": "1.8.0"
      //     }
      // }
      let grp;
      try {
        grp = await make({
          name: "getChatGroupMessages",
          params: {
            group_id: id,
            company_id: rootGetters["companies/getCompany"].id,
          },
          data: {
            last,
          },
        });
      } catch (e) {
        console.error(e);
      }
      commit("SET_MESSAGES", { id, messages: grp });
    },
    async markChatGroupMessagesRead({ dispatch, rootGetters }, { id }) {
      // TODO: This code is not used - should it be modified or removed?
      let _grp;
      try {
        _grp = await make({
          name: "markChatGroupMessagesRead",
          params: {
            group_id: id,
            company_id: rootGetters["companies/getCompany"].id,
          },
        });
      } catch (e) {
        console.error(e);
      }
      dispatch("getGroups");
    },
    async createGroupMessage({ commit, dispatch, rootGetters }, data) {
      let msg;
      try {
        msg = await make({
          name: "createChatGroupMessage",
          params: {
            group_id: data.group_id,
            company_id: rootGetters["companies/getCompany"].id,
          },
          data,
        });
      } catch (e) {
        const keys = Object.keys(e);
        dispatch(
          "toasts/add",
          {
            title: `Send error`,
            message: e?.[keys?.[0]]?.[0] ?? "Something went wrong",
            variant: "error",
            timeout: 5000,
          },
          { root: true }
        );

        return false;
      }

      commit("SET_MESSAGES", { id: data.group_id, messages: [msg] });

      await dispatch("getGroupMessages", { id: data.group_id });

      return true;
    },
    async updateGroupMessage({ commit, dispatch, rootGetters }, data) {
      let msg;

      try {
        msg = await make({
          name: "updateChatGroupMessage",
          params: {
            group_id: data.chat_group_id,
            message_id: data.id,
            company_id: rootGetters["companies/getCompany"].id,
          },
          data,
        });
      } catch (e) {
        console.error(e);
      }

      commit("SET_MESSAGES", { id: data.chat_group_id, messages: [msg] });

      await dispatch("getGroupMessages", { id: data.chat_group_id });
    },
    async deleteChatGroupMessage({ commit, dispatch, rootGetters }, data) {
      // TODO: This code is not used - should it be modified or removed?
      let _msg;
      try {
        _msg = await make({
          name: "deleteChatGroupMessage",
          params: {
            group_id: data.group_id,
            message_id: data.message_id,
            company_id: rootGetters["companies/getCompany"].id,
          },
        });
      } catch (e) {
        console.error(e);
      }

      commit("DELETE_MESSAGE", { id: data?.group_id, messageId: data?.message_id });

      await dispatch("getGroupMessages", { id: data.group_id });
    },
    async reactChatGroupMessage({ dispatch, rootGetters }, data) {
      // TODO: This code is not used - should it be modified or removed?
      let _msg;
      try {
        _msg = await make({
          name: "toggleChatGroupMessageReaction",
          params: {
            group_id: data.group_id,
            message_id: data.message_id,
            company_id: rootGetters["companies/getCompany"].id,
          },
          data,
        });
      } catch (e) {
        console.error(e);
      }
      await dispatch("getGroupMessages", { id: data.group_id });
    },

    async websocketUpdate({ dispatch, commit, rootGetters, state }, data) {
      const employeeId = rootGetters["auth/getDefaultEmployee"].id;
      let dispatchNotifications = true;
      const shouldProcessChatGroupMessage = state.list.find((chatGroup) => {
        return chatGroup.id === data?.group?.id;
      })
        ? true
        : false;

      if (shouldProcessChatGroupMessage && data?.group?.id && data?.latest_message[0]?.id) {
        const incommingMsg = new ChatMessage(data.latest_message[0]);

        if (data?.type === "update" || data?.type === "new") {
          // dont dispatch notifications for the person that created the chat
          if (employeeId === incommingMsg.employee_id) {
            dispatchNotifications = false;
          }
          if (data?.type === "update") {
            dispatchNotifications = false;
          }

          if (incommingMsg.deleted_at) {
            commit("DELETE_MESSAGE", {
              id: data?.group?.id,
              messageId: incommingMsg.id,
            });
          } else {
            if (dispatchNotifications) {
              const title = `Chat in ${data?.group?.name}`;
              const content = data?.latest_message[0]?.image
                ? `${data?.latest_message[0].employee?.name} shared an image.`
                : `"${data?.latest_message[0].content}" - ${data?.latest_message[0].employee?.name}`;
              dispatch(
                "toasts/add",
                {
                  title: title,
                  message: content,
                },
                { root: true }
              );
              dispatch(
                "notifications/add",
                {
                  title: title,
                  content: content,
                  created_at: new Date(),
                  type: "CHAT",
                  type_id: data?.group?.id,
                },
                { root: true }
              );
            }

            commit("SET_MESSAGES", {
              id: data?.group?.id,
              messages: [incommingMsg],
            });
          }
        } else if (data?.type === "reaction") {
          // only dispatch reaction notifications for the person that created the chat
          if (employeeId !== incommingMsg.employee_id) {
            dispatchNotifications = false;
          }
          if (dispatchNotifications) {
            const title = `Reaction to your message.`;
            const content = `${data?.initiator?.name} reacted to your message in ${data?.group?.name}.`;

            dispatch(
              "toasts/add",
              {
                title: title,
                message: content,
              },
              { root: true }
            );
            dispatch(
              "notifications/add",
              {
                title: title,
                content: content,
                created_at: new Date(),
                type: "CHAT",
                type_id: data?.group?.id,
              },
              { root: true }
            );
          }
          commit("SET_MESSAGES", {
            id: data?.group?.id,
            messages: [incommingMsg],
          });
        } else {
          commit("SET_MESSAGES", {
            id: data?.group?.id,
            messages: [incommingMsg],
          });
        }
      }

      //update groups
      await dispatch("getGroups");

      return true;
    },
    clearItem({ commit }) {
      commit("CLEAR_ITEM");
    },
  },
  getters: {
    isLoading(state) {
      return state.status.isLoading || state.status.isUpdating;
    },
    unreadChats: (state) => {
      if (state.list.length > 0) {
        const totalUnread = state.list.reduce((cnt, group) => {
          return cnt + group.unread_count;
        }, 0);
        return totalUnread;
      } else {
        return 0;
      }
    },
  },
};

export default chat;
