import { ref, watch, reactive, onMounted, computed } from "vue";
import { isEqual } from "lodash-es";
import { defineStore, acceptHMRUpdate, storeToRefs } from "pinia";
import { make } from "@/utils/request";
import ResponseMemos from "@/classes/ResponseMemos";
import Memo from "@/classes/Memo";
import Employee from "@/classes/Employee";
import { replaceItemInStore } from "@/utils/helpers";
import { useCompanies } from "./companies";

export const useMemos = defineStore("memos", () => {
  const companyStore = useCompanies();

  const { selectedCompany } = storeToRefs(companyStore);

  const memos = ref([]);

  const fetchPageParams = reactive({
    currentPage: null,
    totalPages: null,
    sortBy: null,
    sortDirection: null,
    terms: null,
  });

  const fetchFilters = reactive({
    audiences: [],
    archivedAt: "default",
    locations: [],
    displayType: "all",
    tags: [],
  });

  const fetchMemos = async () => {
    // const isInitialFetch = !fetchFilters.audiences.length && !fetchFilters.locations.length;
    const pageParams = {
      filters: {
        audiences:
          fetchFilters.audiences.length === 0
            ? fetchFilters.audiences
            : fetchFilters.audiences.map((audiencedId) => ({ id: audiencedId, selected: true })),
        archived_at: fetchFilters.archivedAt,
        display_type: fetchFilters.displayType,
        locations:
          fetchFilters.locations.length === 0
            ? fetchFilters.locations
            : fetchFilters.locations.map((locationId) => ({ id: locationId, selected: true })),
        tags:
          fetchFilters.tags.length === 0
            ? fetchFilters.tags
            : fetchFilters.tags.map((tag) => ({ id: tag, selected: true })),
      },
      page: fetchPageParams.currentPage,
      sort_by: fetchPageParams.sortBy,
      sort_direction: fetchPageParams.sortDirection,
      term: fetchPageParams.terms,
    };

    const response = await make({
      name: "memoList",
      params: {
        company_id: selectedCompany?.value?.id,
      },
      data: pageParams,
    });

    if (response?.memos) {
      memos.value = response.memos.map((memo) => new Memo(memo));

      fetchPageParams.currentPage = response.current_page;
      fetchPageParams.totalPages = response.total_pages;
      fetchPageParams.sortBy = response.sort_by;
      fetchPageParams.sortDirection = response.sort_direction;
    }

    return memos.value;
  };

  const fetchMemo = async (memoId) => {
    const res = await make({
      name: "memoGet",
      params: {
        company_id: selectedCompany.value.id,
        id: memoId,
      },
    });

    const updatedMemo = new Memo(res);

    return updatedMemo;
  };

  const createMemo = async (memoDetails) => {
    const memo = await make({
      name: "memoCreate",
      data: {
        company_id: selectedCompany.value.id,
        ...memoDetails,
      },
    });

    const newMemo = new Memo(memo);

    return newMemo;
  };

  const updateMemo = async (memoDetails) => {
    const memo = await make({
      name: "memoUpdate",
      data: {
        company_id: selectedCompany.value.id,
        ...memoDetails,
      },
    });

    const updatedMemo = new Memo(memo);

    return updatedMemo;
  };

  const deleteMemo = async (memoId) => {
    const response = await make({
      name: "memoDelete",
      data: {
        company_id: selectedCompany.value.id,
        id: memoId,
      },
    });

    return response;
  };

  const deleteRecipients = async (memoId) => {
    const response = await make({
      name: "memoDeleteRecipients",
      data: {
        company_id: selectedCompany.value.id,
        memo_template_send_id: memoId,
      },
    });

    return response;
  };

  const expireMemo = async (memoId) => {
    const res = await make({
      name: "expireMessage",
      params: {
        company_id: selectedCompany.value.id,
        id: memoId,
      },
    });

    const updatedMemo = new Memo(res);

    return updatedMemo;
  };

  const archiveMemo = async (memoId) => {
    const res = await make({
      name: "archiveMessage",
      params: {
        company_id: selectedCompany.value.id,
        id: memoId,
      },
    });
    const updatedMemo = new Memo(res);

    return updatedMemo;
  };

  // get the list of recipients for a memo based on the memo details
  // used for displaying a count/list of recipients on the memo details page
  const fetchMemoRecipients = async (memoDetails) => {
    const recipientDetails = await make({
      name: "memoRecipients",
      data: {
        company_id: selectedCompany.value.id,
        ...memoDetails,
      },
    });

    return recipientDetails;
  };

  const fetchSurveySummary = async (surveyId) => {
    const summary = await make({
      name: "getSurveySummary",
      data: {
        id: surveyId,
        company_id: selectedCompany.value.id,
      },
      options: {
        responseType: "blob",
      },
    });

    return summary;
  };

  const fetchSurveyResponses = async (surveyId) => {
    const responses = await make({
      name: "getSurveyResponses",
      data: {
        company_id: selectedCompany.value.id,
        id: surveyId,
      },
      options: {
        responseType: "blob",
      },
    });

    return responses;
  };

  const resetFetchParams = () => {
    fetchPageParams.currentPage = null;
    fetchPageParams.totalPages = null;
    fetchPageParams.sortBy = null;
    fetchPageParams.sortDirection = null;
    fetchPageParams.terms = null;
  };

  const resetFetchFilters = () => {
    fetchFilters.archivedAt = "default";
    fetchFilters.displayType = "all";
    fetchFilters.locations = [];
    fetchFilters.audiences = [];
    fetchFilters.tags = [];
  };

  const reset = () => {
    resetFetchParams();
    resetFetchFilters();
  };

  return {
    archiveMemo,
    createMemo,
    deleteMemo,
    deleteRecipients,
    expireMemo,
    fetchFilters,
    fetchMemo,
    fetchMemoRecipients,
    fetchMemos,
    fetchPageParams,
    fetchSurveyResponses,
    fetchSurveySummary,
    memos,
    reset,
    updateMemo,
  };
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useMemos, import.meta.hot));
}
