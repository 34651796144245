<template>
  <router-view />
  <toast-service />
</template>

<script>
import { onMounted, ref } from "vue";
import axios from "axios";
import ToastService from "@/components/ToastService.vue";
import { useAuth } from "@/stores/auth";
import router from "./router";

export default {
  components: { ToastService },
  setup() {
    const authStore = useAuth();

    onMounted(() => {
      // Intercept and log out unauthorized calls
      axios.interceptors.response.use(null, (error) => {
        if (authStore.isLoggedIn && error?.response?.status === 401) {
          authStore.logout();
        }

        return Promise.reject(error);
      });
    });
  },
};
</script>
