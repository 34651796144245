<template>
  <div class="toast relative bg-white mb-4 flex flex-col shadow" style="max-width: 300px" role="alert"
    aria-live="assertive" aria-atomic="true">
    <div class="toast-timer h-1" :class="'toast-timer-variant-' + toast.variant"
      :style="{ width: toast.timeout ? `${(timer / toast.timeout) * 100}%` : '100%' }"></div>
    <div class="toast-header flex flex-col px-4 py-2" :class="'toast-header-variant-' + toast.variant">
      <div class="font-semibold" v-if="toast.title">{{ toast.title }}</div>
      <div class="mr-auto toast-message" v-for="(message, m) in toast.messages" :key="m">
        {{ message }}
      </div>
      <div class="w-full self-stretch">
        <div class="pt-2 underline cursor-pointer text-right text-sm" @click="close">close</div>
      </div>
    </div>
  </div>
</template>

<script>
import { onBeforeUnmount, onMounted, ref } from "vue";
import Toast from "@/classes/Toast";
import { useToasts } from "@/stores/toasts";

export default {
  name: "Toast",
  props: {
    toast: Toast,
  },
  setup(props) {
    const toastsStore = useToasts();

    const { deleteToast } = toastsStore;

    const timer = ref(props.toast?.timeout);
    const interval = ref(null);
    const countdownAmount = 25;
    const close = () => {
      if (props.toast.dismissible) {
        deleteToast(props.toast.id);
      }
    };
    onMounted(() => {
      if (timer.value && timer.value > 0) {
        interval.value = setInterval(() => {
          if (timer.value <= 0) {
            close();
          }
          timer.value -= countdownAmount;
        }, countdownAmount);
      }
    });
    onBeforeUnmount(() => {
      if (interval.value) {
        clearInterval(interval.value);
      }
    });
    return {
      close,
      timer,
    };
  },
};
</script>
<style lang="postcss">
.toast-timer-variant-default {
  @apply bg-blue;
}

.toast-timer-variant-success {
  @apply bg-green-400;
}

.toast-timer-variant-error {
  @apply bg-red-500;
}

.toast-header-variant-default {
  @apply bg-white;
}

.toast-header-variant-success {
  @apply bg-green-100;
}

.toast-header-variant-error {
  @apply bg-red-100;
}

/* .toast {
  opacity: 1;
  position: relative;
  @apply bg-white border p-4 flex flex-col;
}
  .toast-header {
    padding: 0.875rem 0.875rem;
    position: relative;
    z-index: 100;
  }
  .toast-header-variant-info,
  .toast-header-variant-error,
  .toast-header-variant-success,
  .toast-header-variant-warning {
    border-left-width: 4px;
    border-left-style: solid;
  }
  .toast-timer {
    position: absolute;
    width: 100%;
    height: 3px;
    bottom: 0;
    left: 0;
    opacity: 0.5;
    z-index: 150;
  }
  .toast-icon {
    margin-right: 1.2rem;
  }
  .toast-message {
    margin-left: 1rem;
    padding-right: 1rem;
  }
  .close {
    position: absolute;
    right: 14px;
    top: 14px;
  }
  .icon.size-20 {
    width: auto;
  } */
</style>
