<template>
  <div class="w-screen h-screen bg-gray overflow-auto flex flex-col items-center justify-center">
    Logging out
  </div>
</template>

<script>
import { reactive, ref, computed, onMounted } from "vue";
import { useAuth } from "@/stores/auth";
import { useRouter } from "vue-router";

export default {
  name: "Login",
  components: {},
  props: {
    redirect: String,
    query: String,
    reason: String,
    token: String,
  },
  setup(props) {
    const authStore = useAuth();
    const router = useRouter();

    onMounted(async () => {
      await authStore.logout();
      router.push({ name: "Login" });
    });

    return {};
  },
};
</script>

<style lang="postcss">
.login-box {
  width: 100%;
  max-width: 400px;
}

.form {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  border-top-style: solid;
  border-top-width: 1px;
  border-top-color: gray-400;
  width: 100%;
}

.tab {
  top: 1px;
  position: relative;
}

.unselected-tab {
  background-color: white;
  border-bottom-width: 1px;
  border-bottom-color: gray-400;
  color: gray;
}

.selected-tab {
  background-color: white;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  border-color: gray-400;
  border-style: solid;
  border-width: 1px;
  border-bottom: none;
  color: gray;
}
</style>
