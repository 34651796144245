import { v4 as uuidv4 } from "uuid";
class Notification {
  constructor({
    title = null,
    content = null,
    type = null,
    read = false,
    created_at = null,
    type_id = null,
  } = {}) {
    this.id = uuidv4();
    this.title = title;
    this.content = content;
    this.type = type;
    this.read = read;
    this.created_at = created_at;
    this.type_id = type_id;
  }
}

export default Notification;
