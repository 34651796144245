import { ref, watch, onMounted } from "vue";
import { defineStore, acceptHMRUpdate, storeToRefs } from "pinia";
import { make } from "@/utils/request";

import { useCompanies } from "./companies";

export const useCompanyInvites = defineStore("companies-invites", () => {
  const companyStore = useCompanies();
  const { selectedCompany } = storeToRefs(companyStore);
  // const { fetchCompanies } = companyStore;

  // const companyInvites = ref([]);

  // const fetchCompanyInvites = async (user) => {
  //   if (user?.phone) {
  //     const invites = await make({ name: "getTeamInvites", params: { phone: user.phone } });
  //     companyInvites.value = invites;

  //     return invites;
  //   } else {
  //     console.warn("User doesnt have an associated phone number can't fetch invites.");
  //     return [];
  //   }
  // };

  const acceptCompanyInvite = async (inviteCode) => {
    // TODO: what does this return? Do we have to refetch?
    const response = await make({ name: "acceptInvitation", data: { token: inviteCode } });

    // await fetchCompanies();

    return response;
  };

  // const ignoreCompanyInvite = async (inviteCode) => {
  //   // TODO: what does this return? Do we have to refetch?
  //   const response = await make({ name: "ignoreInvitation", data: { token: inviteCode } });

  //   // await fetchCompanies();

  //   return response;
  // };

  const inviteEmployeeToCompany = async (employeeDetails) => {
    console.log("inviteEmployeeToCompany", employeeDetails);
    const invitation = await make({
      name: "inviteEmployee",
      data: {
        company_id: selectedCompany.value.id,
        ...employeeDetails,
      },
    });

    return invitation;
  };

  // onMounted(async () => {
  //   if (selectedCompany?.value?.id && !companyInvites.value.length) {
  //     await fetchCompanyInvites();
  //   }
  // });

  // watch(selectedCompany, async (newCompany, oldCompany) => {
  //   if (!newCompany && oldCompany) {
  //     // clear value son logout
  //     companyInvites.value = [];
  //   }
  // });

  return {
    acceptCompanyInvite,
    // fetchCompanyInvites,
    // ignoreCompanyInvite,
    // companyInvites,
    inviteEmployeeToCompany,
  };
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useCompanyInvites, import.meta.hot));
}
