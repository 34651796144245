import { DateTime } from "luxon";
import Employee from "./Employee";

class File {
  constructor({
    audience_ids = [],
    audiences = [],
    company_id = null,
    content = null,
    created_at = null,
    creator = null,
    deleted_at = null,
    filename = null,
    filename_min = null,
    filename_thumb = null,
    filepath = null,
    filepath_min = null,
    filepath_thumb = null,
    id = null,
    location_ids = [],
    locations = [],
    marked_viewable_at = null,
    mode = "segments",
    name = null,
    original_filename = null,
    recipient_ids = [],
    recipients = [],
    title = null,
    type = null,
    type_category = null,
    updated_at = null,
    updater = null,
  } = {}) {
    this.audience_ids = audience_ids;
    this.audiences = audiences;
    this.company_id = company_id;
    this.content = content;
    this.created_at = created_at ? DateTime.fromISO(created_at) : null;
    this.creator = creator?.id ? new Employee(creator) : null;
    this.deleted_at = deleted_at ? DateTime.fromISO(deleted_at) : null;
    this.filename = filename;
    this.filename_min = filename_min;
    this.filename_thumb = filename_thumb;
    this.filepath = filepath;
    this.filepath_thumb = filepath_thumb;
    this.filepath_min = filepath_min;
    this.id = id;
    this.location_ids = location_ids;
    this.locations = locations;
    this.marked_viewable_at = marked_viewable_at ? DateTime.fromISO(marked_viewable_at) : null;
    this.mode = mode;
    this.name = name;
    this.original_filename = original_filename;
    this.recipient_ids = recipient_ids;
    this.recipients = recipients?.length ? recipients.map((e) => new Employee(e)) : [];
    this.title = title;
    this.type = type;
    this.type_category = type_category;
    this.updated_at = updated_at ? DateTime.fromISO(updated_at) : null;
    this.updater = updater?.id ? new Employee(updater) : null;
  }
}

export default File;
