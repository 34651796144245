import Notification from "@/classes/Notification";

const notifications = {
  namespaced: true,
  state: {
    stack: [],
    index: 1,
  },
  mutations: {
    ADD_NOTIFICATION(state, payload) {
      state.index += 1;
      // Vue.set(state, "stack", [...state.stack, payload]);
      state.stack = [...state.stack, payload];
    },
    REMOVE_NOTIFICATION(state, payload) {
      let newStack = state.stack.slice();
      const foundIndex = payload.id
        ? newStack.findIndex((val) => val.id === payload.id)
        : newStack.findIndex((val) => val.message === payload.message);
      newStack.splice(foundIndex, 1);
      // Vue.set(state, "stack", [...newStack]);
      state.stack = [...newStack];
    },
    READ_NOTIFICATION(state, payload) {
      let newStack = state.stack.slice();
      const foundIndex = payload.id
        ? newStack.findIndex((val) => val.id === payload.id)
        : newStack.findIndex((val) => val.message === payload.message);
      newStack[foundIndex] = payload;
      // Vue.set(state, "stack", [...newStack]);
      state.stack = [...newStack];
    },
    CLEAR_ALL(state) {
      state.stack = [];
      // Vue.set(state, "stack", []);
    },
  },
  actions: {
    add({ commit, state }, payload) {
      let added = payload;
      if (!(payload instanceof Notification)) {
        added = new Notification({ ...payload, id: state.index });
      }

      commit("ADD_NOTIFICATION", added);

      // playing with the chime sound
      // path to file
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties

      // NOTE: This will not always run on page load, browsers prevent audio/video from auto playing if a user hasnt interacted with the site
      // Audio clip sourced from - https://pixabay.com/sound-effects/ringtone-1-46486/
      var audio = new Audio(
        "https://pushkin-public.nyc3.cdn.digitaloceanspaces.com/ringtone-1-46486.mp3"
      );
      audio.addEventListener("canplay", () => {
        /* the audio is now playable; play it if permissions allow */
        audio.play();
      });
    },
    remove({ commit }, payload) {
      let removed = payload;
      if (!(payload instanceof Notification)) {
        removed = new Notification(payload);
      }

      commit("REMOVE_NOTIFICATION", removed);
    },
    markRead({ commit }, payload) {
      let updated = payload;
      if (!(payload instanceof Notification)) {
        updated = new Notification(payload);
      }
      updated.read = true;
      commit("READ_NOTIFICATION", updated);
    },
    clear({ commit }) {
      commit("CLEAR_ALL");
    },
  },
  getters: {
    getNotificationsStack: (state) => state.stack.sort((a, b) => b.created_at - a.created_at),
    hasNewChats: (state) =>
      state.stack.filter((notification) => notification.type === "CHAT").length > 0,
  },
};

export default notifications;
