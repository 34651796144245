import { ref, reactive, computed, watch, onMounted } from "vue";
import { isEqual, capitalize } from "lodash-es";
import { defineStore, acceptHMRUpdate, storeToRefs } from "pinia";
import { make } from "@/utils/request";
import { replaceItemInStore } from "@/utils/helpers";
import Task from "@/classes/Task";

import { useCompanies } from "./companies";
import { useCompanyEmployees } from "./companies-employees";
import { useNotifications } from "./notifications";
import { useToasts } from "./toasts";

const filterListsHaveChanged = (newList, oldList) => {
  // filters have only changed if both lists have values
  // otherwises refetches are handled elsewhere

  // ^ that above seems incorrect making changes, new list could be length of zero
  if (!newList || !oldList) {
    return true; // if either list is falsey return true to enforce change occured and refetch
  }
  return !isEqual(newList, oldList);
};

export const useTasks = defineStore("tasks", () => {
  const companyStore = useCompanies();
  const employeesStore = useCompanyEmployees();
  const notificationsStore = useNotifications();
  const toastsStore = useToasts();

  const { selectedCompany } = storeToRefs(companyStore);
  const { selectedEmployee } = storeToRefs(employeesStore);

  const { addNotification } = notificationsStore;
  const { addToast } = toastsStore;

  const tasks = ref([]);
  const websocketTask = ref(null);

  const openTasks = computed(() => {
    if (!selectedEmployee?.value?.id || tasks?.value?.length === 0) {
      return 0;
    }

    return tasks.value.filter((task) => {
      // Is not completed and assigned to me
      const isAssignedToMe = !task.completed_at && task.assignee_id === selectedEmployee.value.id;
      // Is not completed and unassigned
      const IsNotAssigned = !task.completed_at && !task.assignee_id;

      return isAssignedToMe || IsNotAssigned;
    }).length;
  });

  const fetchPageParams = reactive({
    currentPage: null,
    totalPages: null,
    sortBy: null,
    sortDirection: null,
    terms: null,
  });

  const fetchFilters = reactive({
    audiences: [],
    completedAt: "default",
    locations: [],
    tags: [],
  });

  const fetchTask = async (taskId) => {
    const refreshedTask = await make({
      name: "taskGet",
      params: {
        company_id: selectedCompany.value.id,
        id: taskId,
      },
    });

    const task = new Task(refreshedTask);

    replaceItemInStore(task, tasks.value);

    return task;
  };

  const createTask = async (taskDetails) => {
    const newTask = await make({
      name: "taskCreate",
      data: {
        company_id: selectedCompany.value.id,
        ...taskDetails,
      },
    });

    const task = new Task(newTask);

    tasks.value = [task, ...tasks.value]; // push new item to the top of the list

    return task;
  };

  const fetchTasks = async () => {
    const pageParams = {
      filters: {
        audiences:
          fetchFilters.audiences.length === 0
            ? fetchFilters.audiences
            : fetchFilters.audiences.map((audiencedId) => ({ id: audiencedId, selected: true })),
        completed_at: fetchFilters.completedAt,
        locations:
          fetchFilters.locations.length === 0
            ? fetchFilters.locations
            : fetchFilters.locations.map((locationId) => ({ id: locationId, selected: true })),
        tags:
          fetchFilters.tags.length === 0
            ? fetchFilters.tags
            : fetchFilters.tags.map((tag) => ({ id: tag, selected: true })),
      },
      page: fetchPageParams.currentPage,
      sort_by: fetchPageParams.sortBy,
      sort_direction: fetchPageParams.sortDirection,
      term: fetchPageParams.terms,
    };

    const response = await make({
      name: "taskList",
      params: {
        company_id: selectedCompany.value.id,
      },
      data: pageParams,
    });

    // if tasks comes back and is empty the filtered list should be empty
    if (response?.tasks) {
      tasks.value = response.tasks.map((task) => new Task(task));

      fetchPageParams.currentPage = response.current_page;
      fetchPageParams.totalPages = response.total_pages;
      fetchPageParams.sortBy = response.sort_by;
      fetchPageParams.sortDirection = response.sort_direction;

      // Setting these retrigger another fetch, that doesn't maintain the filter
      // fetchFilters.completedAt = response.filters?.completed_at;
      // fetchFilters.locations = response.filters?.locations.map((location) => location.id);
      // fetchFilters.audiences = response.filters?.audiences.map((audience) => audience.id);
    }

    return response.tasks;
  };

  const updateTask = async (taskDetails) => {
    const updatedTask = await make({
      name: "taskUpdate",
      data: {
        company_id: selectedCompany.value.id,
        ...taskDetails,
      },
    });

    const task = new Task(updatedTask);

    replaceItemInStore(task, tasks.value);

    return updatedTask;
  };

  const deleteTask = async (taskId) => {
    const response = await make({
      name: "taskDelete",
      data: {
        company_id: selectedCompany.value.id,
        id: taskId,
      },
    });

    tasks.value = tasks.value.filter((task) => task.id !== taskId);

    return response;
  };

  const viewTask = async (taskId) => {
    const response = await make({
      name: "taskView",
      data: {
        company_id: selectedCompany.value.id,
        id: taskId,
      },
    });

    return response;
  };

  const assignTask = async (taskId, assigneeId) => {
    const updatedTask = await make({
      name: "taskAssign",
      data: {
        company_id: selectedCompany.value.id,
        id: taskId,
        assignee_id: assigneeId,
      },
    });

    const task = new Task(updatedTask);
    replaceItemInStore(task, tasks.value);

    return updatedTask;
  };

  const startTask = async (taskId) => {
    const updatedTask = await make({
      name: "taskStart",
      data: {
        company_id: selectedCompany.value.id,
        id: taskId,
      },
    });
    const task = new Task(updatedTask);
    replaceItemInStore(task, tasks.value);

    return updatedTask;
  };

  const completeTask = async (taskId) => {
    const updatedTask = await make({
      name: "taskComplete",
      data: {
        company_id: selectedCompany.value.id,
        id: taskId,
      },
    });
    const task = new Task(updatedTask);
    replaceItemInStore(task, tasks.value);

    return updatedTask;
  };

  const reopenTask = async (taskId) => {
    const updatedTask = await make({
      name: "taskReopen",
      data: {
        company_id: selectedCompany.value.id,
        id: taskId,
      },
    });

    const task = new Task(updatedTask);
    replaceItemInStore(task, tasks.value);

    return updatedTask;
  };

  const approveTask = async (taskId) => {
    const updatedTask = await make({
      name: "taskApprove",
      data: {
        company_id: selectedCompany.value.id,
        id: taskId,
      },
    });
    const task = new Task(updatedTask);
    replaceItemInStore(task, tasks.value);

    return updatedTask;
  };

  const shouldNotifyUser = (task) => {
    let shouldProcessTask = false;
    let empHasLocation = null;
    let empHasAudience = null;
    let empIsRecipient = null;

    // only drop into this if block if mode is segmentation
    if (task?.mode === "segments") {
      if (task?.location_ids?.length === 0 && task?.audience_ids?.length === 0) {
        // process if there are no locations and audiences
        shouldProcessTask = true;
      } else {
        // there is segmentation
        if (task.location_ids.length > 0) {
          empHasLocation = null; // reset for falsy
          empHasLocation = task.location_ids.find((locationId) => {
            return selectedEmployee?.value.locations.find((empLocation) => {
              return empLocation.id === locationId;
            });
          });
        } else {
          empHasLocation = true;
        }

        if (task.audience_ids.length > 0) {
          empHasAudience = null; // reset for falsy
          empHasAudience = task.audience_ids.find((audienceId) => {
            return selectedEmployee?.value.audiences.find((empAudience) => {
              return empAudience.id === audienceId;
            });
          });
        } else {
          empHasAudience = true;
        }

        if (empHasLocation && empHasAudience) {
          // only process if they have both locations and audiences?
          shouldProcessTask = true;
        }

        // assignee_id is a string here, WTF
        if (task.assignee_id == selectedEmployee?.value.id) {
          shouldProcessTask = true;
        }
      }
    }

    if (task?.recipient_ids.length > 0) {
      empIsRecipient = task.recipient_ids.find((recipientId) => {
        return recipientId === selectedEmployee?.value.id;
      });
      if (empIsRecipient) {
        shouldProcessTask = true;
      }
    }
    return shouldProcessTask;
  };

  const sendUpdateNotifications = async ({ updatedTask, type, initiator }) => {
    if (!updatedTask) {
      return null;
    }
    const task = new Task(updatedTask);

    const isCommentUpdate = type?.indexOf("COMMENT") >= 0;
    const shouldNotify = initiator !== selectedEmployee?.value.id && shouldNotifyUser(task);
    const titleUpdateType = isCommentUpdate ? "Comment" : capitalize(type);
    const messageTitle = `Task ${titleUpdateType}`;

    if (shouldNotify) {
      addToast({
        title: `${messageTitle} ${task.title}`,
        message: `${task.content}`,
      });

      addNotification({
        title: `${messageTitle}: ${task.title}`,
        content: `${task.content}`,
        created_at: new Date(),
        type: "TASK",
        type_id: task?.id,
      });

      replaceItemInStore(task, tasks.value);
      websocketTask.value = task;
    }
  };

  const resetFetchParams = () => {
    fetchPageParams.currentPage = null;
    fetchPageParams.totalPages = null;
    fetchPageParams.sortBy = null;
    fetchPageParams.sortDirection = null;
    fetchPageParams.terms = null;
  };

  const resetFetchFilters = () => {
    fetchFilters.completedAt = "default";
    fetchFilters.locations = [];
    fetchFilters.audiences = [];
    fetchFilters.tags = [];
  };

  // watch(selectedCompany, async (newCompany, oldCompany) => {
  //   if (!newCompany && oldCompany) {
  //     tasks.value = [];
  //   }

  //   if (newCompany && newCompany?.id !== oldCompany?.id) {
  //     // refetch tasks automatically if the
  //     // selected company changes
  //     resetFetchParams();
  //     resetFetchFilters();
  //     await fetchTasks();
  //   }
  // });

  // watch(
  //   () => fetchFilters.completedAt,
  //   (newFilter, oldFilter) => {
  //     if (newFilter && newFilter !== oldFilter) {
  //       fetchTasks();
  //     }
  //   }
  // );

  // watch(
  //   () => fetchFilters.locations,
  //   (newLocations, oldLocations) => {
  //     if (filterListsHaveChanged(newLocations, oldLocations)) {
  //       fetchTasks();
  //     }
  //   },
  //   { deep: true }
  // );

  // watch(
  //   () => fetchFilters.audiences,
  //   (newAudiences, oldAudiences) => {
  //     if (filterListsHaveChanged(newAudiences, oldAudiences)) {
  //       fetchTasks();
  //     }
  //   },
  //   { deep: true }
  // );

  // watch(websocketTask, (newTask, oldTask) => {
  //   // details changed, unless we are going to do a deep compare just re-fetch the task that changed
  //   fetchTask(newTask.id);
  // });

  // onMounted(async () => {
  //   if (!tasks?.value?.length && selectedCompany?.value?.id) {
  //     await fetchTasks();
  //   }
  // });

  return {
    approveTask,
    assignTask,
    completeTask,
    createTask,
    deleteTask,
    fetchPageParams,
    fetchFilters,
    fetchTask,
    fetchTasks,
    openTasks,
    reopenTask,
    sendUpdateNotifications,
    startTask,
    tasks,
    updateTask,
    viewTask,
  };
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useTasks, import.meta.hot));
}
