import { required } from "../rules/required";
import { maxChars } from "@/utils/validation/rules/text";

export const message = {
  title: {
    required,
    maxChars: maxChars(50),
  },
  content: { required },
  location_ids: {},
  audience_ids: {},
  start_at: {},
  end_at: {},
  category: {},
  type: { required },
  author: {
    maxChars: maxChars(50),
  },
  author_description: {
    maxChars: maxChars(50),
  },
};

export const newmessage = {
  title: {
    required,
    maxChars: maxChars(50),
  },
};
