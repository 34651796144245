import { defineStore, acceptHMRUpdate, storeToRefs } from "pinia";
import { make } from "@/utils/request";
import { useCompanies } from "./companies";
import Location from "@/classes/Location";
import { computed } from "vue";
import { mapEntityToSelect } from "@/utils/helpers";
import { useAppConfig, BANNER_TYPES } from "@/stores/app-config";

export const useCompanyLocations = defineStore("companies-locations", () => {
  const companyStore = useCompanies();
  const appConfigStore = useAppConfig();
  const { setShowAppBanner } = appConfigStore;
  const { selectedCompany, company } = storeToRefs(companyStore);
  const { refreshSelectedCompany } = companyStore;

  const companyLocationSelectOptions = computed(() => {
    return company.value?.locations?.map(mapEntityToSelect("name", "id"));
  });

  const createLocation = async (locationDetails) => {
    const location = await make({
      name: "createCompanyLocation",
      data: {
        company_id: selectedCompany.value.id,
        ...locationDetails,
      },
    });

    await refreshSelectedCompany();

    setShowAppBanner(null,selectedCompany.value);

    const updatedLocation = location ? new Location(location) : null;

    return updatedLocation;
  };

  const deleteLocation = async (locationId) => {
    const response = await make({
      name: "deleteCompanyLocation",
      data: {
        company_id: selectedCompany.value.id,
        id: locationId,
      },
    });

    await refreshSelectedCompany();
    setShowAppBanner(null,selectedCompany.value);

    return response;
  };

  const fetchLocation = async (locationId) => {
    const response = await make({
      name: "getCompanyLocation",
      params: {
        company_id: selectedCompany.value.id,
        id: locationId,
      },
    });

    return response;
  };

  const fetchLocations = async () => {
    const locations = await make({
      name: "getCompanyLocationsList",
      params: {
        company_id: selectedCompany.value.id,
      },
    });

    return locations;
  };

  const updateLocation = async (locationDetails) => {
    const location = await make({
      name: "editCompanyLocation",
      data: {
        company_id: selectedCompany.value.id,
        ...locationDetails,
      },
    });

    await refreshSelectedCompany();
    setShowAppBanner(null,selectedCompany.value);

    const updatedLocation = location ? new Location(location) : null;

    return updatedLocation;
  };

  return {
    createLocation,
    deleteLocation,
    fetchLocation,
    fetchLocations,
    updateLocation,
    companyLocationSelectOptions,
  };
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useCompanyLocations, import.meta.hot));
}
