import "./styles/index.css";
// import "@toast-ui/editor/dist/toastui-editor.css";
// import "codemirror/lib/codemirror.css";
import { createApp } from "vue";
import { setBaseUrl } from "@/utils/request";
import { createPinia } from "pinia";
import { vMaska } from "maska";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "@vuepic/vue-datepicker/dist/main.css";
import { OktaAuth } from '@okta/okta-auth-js'
import OktaVue from '@okta/okta-vue'


if (process.env.VUE_APP_API_URL) {
  setBaseUrl(process.env.VUE_APP_API_URL);
}

const oktaAuth = new OktaAuth({
  issuer: process.env.VUE_APP_OKTA_ISSUER_URL,
  clientId: process.env.VUE_APP_OKTA_CLIENT_ID,
  redirectUri: process.env.VUE_APP_OKTA_REDIRECT_URL,
  scopes: ["offline_access", "team-member-api:read"],
  // Use authorization_code flow
  responseType: 'code',
  pkce: false
})

const pinia = createPinia();

createApp(App).use(pinia).use(store).directive("maska", vMaska).use(router).use(OktaVue, { oktaAuth }).mount("#app");
