import { DateTime } from "luxon";

class MemoTranslation {
  constructor({
    title = null,
    content = null,
    recipient_title = null,
    recipient_content = null,
    memo_id = null,
    locale_code = "es",
    options = [],
    created_at = null,
    updated_at = null,
  } = {}) {
    this.title = title;
    this.content = content;
    this.recipient_title = recipient_title;
    this.recipient_content = recipient_content;
    this.memo_id = memo_id;
    this.locale_code = locale_code;
    this.options = options?.length ? options : [];
    this.created_at = created_at ? DateTime.fromISO(created_at) : null;
    this.updated_at = updated_at ? DateTime.fromISO(updated_at) : null;
  }
}

export default MemoTranslation;
