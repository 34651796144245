import { DateTime } from "luxon";
import Employee from "./Employee";

class FormResponse {
  constructor({
    id = null,
    company_id = null,
    form_id = null,
    employee = null,
    employee_id = null,
    response = null,
    created_at = null,
    updated_at = null,
  } = {}) {
    this.id = id;
    this.company_id = company_id;
    this.form_id = form_id;
    this.employee = employee?.id ? new Employee(this.employee) : null;
    this.employee_id = employee_id;
    this.response = response;
    this.created_at = created_at ? DateTime.fromISO(created_at) : null;
    this.updated_at = updated_at ? DateTime.fromISO(updated_at) : null;
  }
}

export default FormResponse;
