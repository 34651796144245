import { DateTime } from "luxon";
import Employee from "./Employee";

class Form {
  constructor({
    audience_ids = [],
    audiences = [],
    company_id = null,
    created_at = null,
    creator = null,
    deleted_at = null,
    description = null,
    fields = null,
    id = null,
    location_ids = [],
    locations = [],
    mode = "segments",
    name = null,
    recipient_ids = [],
    recipients = [],
    template_id = null,
    updated_at = null,
    updater = null,
  } = {}) {
    this.audience_ids = audience_ids;
    this.audiences = audiences;
    this.company_id = company_id;
    this.fields = fields;
    this.created_at = created_at ? DateTime.fromISO(created_at) : null;
    this.creator = creator?.id ? new Employee(creator) : null;
    this.deleted_at = deleted_at ? DateTime.fromISO(deleted_at) : null;
    this.description = description;
    this.fields = fields;
    this.id = id;
    this.location_ids = location_ids;
    this.locations = locations;
    this.mode = mode;
    this.name = name;
    this.recipient_ids = recipient_ids;
    this.recipients = recipients?.length ? recipients.map((e) => new Employee(e)) : [];
    this.template_id = template_id;
    this.updated_at = updated_at ? DateTime.fromISO(updated_at) : null;
    this.updater = updater?.id ? new Employee(updater) : null;
  }
}

export default Form;
