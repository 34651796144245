import { DateTime } from "luxon";
import Employee from "./Employee";
import TaskComment from "./TaskComment";

class Task {
  constructor({
    assigned_at = null,
    assignee = null,
    assignee_id = null,
    assigner = null,
    audience_ids = [],
    audiences = [],
    comments = [],
    completed_at = null,
    completer = null,
    content = null,
    created_at = null,
    creator = null,
    deleted_at = null,
    due_at = null,
    id = null,
    location_ids = [],
    locations = [],
    mode = "segments",
    recipient_ids = [],
    recipients = [],
    started_at = null,
    starter = null,
    status = null,
    tags = [],
    title = null,
    updated_at = null,
    updater = null,
    form = null,
    form_id = null,
    approved_at = null,
    approved_by = null,
    approver = null,
    approver_id = null,
    approvee = null,
  } = {}) {
    this.assigned_at = assigned_at ? DateTime.fromISO(assigned_at) : null;
    this.assignee = assignee?.id ? new Employee(assignee) : null;
    this.assignee_id = assignee_id;
    this.assigner = assigner?.id ? new Employee(assigner) : null;
    this.audience_ids = audience_ids;
    this.audiences = audiences;
    this.comments = comments?.length ? comments.map((c) => new TaskComment(c)) : [];
    this.completed_at = completed_at ? DateTime.fromISO(completed_at) : null;
    this.completer = completer?.id ? new Employee(completer) : null;
    this.content = content;
    this.created_at = created_at ? DateTime.fromISO(created_at) : null;
    this.creator = creator?.id ? new Employee(creator) : null;
    this.deleted_at = deleted_at ? DateTime.fromISO(deleted_at) : null;
    this.due_at = due_at ? DateTime.fromISO(due_at) : null;
    this.id = id;
    this.location_ids = location_ids;
    this.locations = locations;
    this.mode = mode;
    this.recipient_ids = recipient_ids;
    this.recipients = recipients?.length ? recipients.map((e) => new Employee(e)) : [];
    this.started_at = started_at ? DateTime.fromISO(started_at) : null;
    this.starter = starter?.id ? new Employee(starter) : null;
    this.status = status;
    this.tag_ids = tags?.length ? tags.map((t) => t.id) : [];
    this.tags = tags;
    this.title = title;
    this.updated_at = updated_at ? DateTime.fromISO(updated_at) : null;
    this.updater = updater?.id ? new Employee(updater) : null;
    this.approved_at = approved_at ? DateTime.fromISO(approved_at) : null;
    this.approver_id = approver_id;
    this.approved_by = approved_by;
    this.approver = approver?.id ? new Employee(approver) : null;
    this.approvee = approvee?.id ? new Employee(approvee) : null;
    this.form_id = form_id;
    this.form = form;
  }
}

export default Task;
