import { DateTime } from "luxon";
import Employee from "./Employee";
import EmployeeMemo from "./EmployeeMemo";
import MemoTranslation from "./MemoTranslation";
class Memo {
  constructor({
    audience_ids = [],
    audiences = [],
    author = null,
    author_description = null,
    can_archive = false,
    can_expire = false,
    category = null,
    celebration_options = [],
    content = null,
    created_at = null,
    creator = null,
    deleted_at = null,
    display_type = null,
    display_type_ui = null,
    distribution = [],
    employee_memos = [],
    end_at = null,
    id = null,
    location_ids = [],
    locations = [],
    media = [],
    mode = "segments",
    options = [],
    options_key = "custom",
    published_at = null,
    publisher = null,
    recipient = null,
    recipient_content = "",
    recipient_id,
    recipient_ids = [],
    recipient_title = "",
    recipients = [],
    response_rate = 0,
    reveal_at = null,
    start_at = null,
    due_at = null,
    status = null,
    tags = [],
    title = null,
    translations = [],
    type = "nonflaggable",
    updated_at = null,
    updater = null,
    employee_response = null,
    priority = null,
    is_past_due = false,
    signature = false,
  } = {}) {
    this.audience_ids = audience_ids ?? [];
    this.audiences = audiences;
    this.author = author;
    this.author_description = author_description;
    this.can_archive = can_archive;
    this.can_expire = can_expire;
    this.category = category;
    this.celebration_options = celebration_options;
    this.content = content;
    this.created_at = created_at ? DateTime.fromISO(created_at) : null;
    this.creator = creator?.id ? new Employee(creator) : null;
    this.deleted_at = deleted_at ? DateTime.fromISO(deleted_at) : null;
    this.display_type = display_type;
    this.distribution = distribution?.length ? distribution.map((e) => new Employee(e)) : [];
    this.employee_memos = employee_memos;
    this.end_at = end_at ? DateTime.fromISO(end_at) : null;
    this.id = id;
    this.location_ids = location_ids ?? [];
    this.locations = locations;
    this.media = media;
    this.mode = mode;
    this.options = options;
    this.options_key = options_key;
    this.published_at = published_at ? DateTime.fromISO(published_at) : null;
    this.publisher = publisher?.id ? new Employee(publisher) : null;
    this.recipient = recipient ? new Employee(recipient) : null;
    this.recipient_content = recipient_content;
    this.recipient_id = recipient_id;
    this.recipient_ids = recipient_ids ?? [];
    this.recipient_title = recipient_title;
    this.recipients = recipients?.length ? recipients.map((e) => new Employee(e)) : [];
    this.response_rate = (response_rate * 100).toFixed(0);
    this.reveal_at = reveal_at ? DateTime.fromISO(reveal_at) : null;
    this.start_at = start_at ? DateTime.fromISO(start_at) : null;
    this.due_at = due_at ? DateTime.fromISO(due_at) : null;
    this.display_start = this.start_at ?? this.published_at;
    this.status = status;
    this.tag_ids = tags?.length ? tags.map((t) => t.id) : [];
    this.tags = tags;
    this.title = title;
    this.translations = translations?.length ? translations.map((t) => new MemoTranslation(t)) : [];
    this.type = type ?? "nonflaggable";
    this.updated_at = updated_at ? DateTime.fromISO(updated_at) : null;
    this.updater = updater?.id ? new Employee(updater) : null;
    this.employee_response = employee_response?.id ? new EmployeeMemo(employee_response) : null;
    this.priority = priority;
    this.is_past_due = is_past_due;

    this.display_type_ui = display_type_ui; // message, survey, celebration, celebrated, checkin, message_flagged
    this.signature = signature; 
  }
}

export default Memo;
