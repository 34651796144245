import { v4 as uuidv4 } from "uuid";
import { ref, computed, reactive } from "vue";
import { defineStore, acceptHMRUpdate } from "pinia";
import Notification from "@/classes/Notification";

const NEW_NOTIFICATION_URL =
  "https://pushkin-public.nyc3.cdn.digitaloceanspaces.com/ringtone-1-46486.mp3";

const sortByNewest = (a, b) => b.created_at - a.created_at;

const audioIsReady = (audioHTMLElement) => audioHTMLElement.readyState >= 2;

export const useNotifications = defineStore("notifications", () => {
  // making this an object so that it can be expanded for
  // more sounds in the future
  const sounds = reactive({ new: new Audio(NEW_NOTIFICATION_URL) });

  const unsortedNotifications = ref([]);
  const notifications = computed(() => unsortedNotifications.value.sort(sortByNewest));

  const addNotification = (notification) => {
    const isNotification = notification instanceof Notification;

    const newNotification = isNotification
      ? notification
      : new Notification({ ...notification, id: uuidv4() });

    unsortedNotifications.value.push(newNotification);

    if (audioIsReady(sounds.new)) {
      sounds.new.play();
    }
  };

  const deleteNotification = (notificationId) => {
    unsortedNotifications.value = unsortedNotifications.value.filter(
      (notification) => notification.id !== notificationId
    );
  };

  const markNotificationAsRead = (notificationId) => {
    unsortedNotifications.value = unsortedNotifications.value.map((notification) => {
      if (notification.id !== notificationId) {
        return notification;
      }

      return { ...notification, read: true };
    });
  };

  const clearNotifications = () => {
    unsortedNotifications.value = [];
  };

  return {
    addNotification,
    clearNotifications,
    deleteNotification,
    markNotificationAsRead,
    notifications,
  };
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useNotifications, import.meta.hot));
}
