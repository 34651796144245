import { ref, onMounted, reactive } from "vue";
import { compareVersions } from "compare-versions";
import { defineStore, acceptHMRUpdate, storeToRefs } from "pinia";
import { GiphyFetch } from "@giphy/js-fetch-api";
import { BANNER_ALERT_LEVELS } from "@/components/Banner";

// import { useCompanies } from "./companies";

export const BANNER_TYPES = {
  APP_VERSION: "app-version",
  INCOMPLETE_PROFILE: "incomplete-profile",
  SUBSCRIPTION_BANNER: "subscription-banner",
};

const createSubscriptionBannerlabel = (selectedCompany) => {
  if (selectedCompany.is_subscribed && !selectedCompany.all_locations_subscribed) {
    return `You need to add more locations to your subscription - click here to manage subscription.`;
  }

  if (
    selectedCompany?.trial_ends_at &&
    !selectedCompany.is_on_trial &&
    !selectedCompany.is_subscribed
  ) {
    const trialEndsAt = selectedCompany?.trial_ends_at?.split("T")[0] || null;
    return `Your free trial of PushKin ended on ${trialEndsAt}.`;
  }

  if (!selectedCompany.is_subscribed) {
    return `In order to use PushKin you will need an active subscription - click here to manage subscription.`;
  }

  return "";
};

const determineAppBannerDetails = (bannerType, selectedCompany) => {
  switch (bannerType) {
    case BANNER_TYPES.APP_VERSION: {
      return {
        label: "A new version of Pushkin is available. Click here to refresh.",
        alertLevel: BANNER_ALERT_LEVELS.INFO,
      };
    }
    case BANNER_TYPES.SUBSCRIPTION_BANNER: {
      return {
        label: createSubscriptionBannerlabel(selectedCompany),
        alertLevel: !selectedCompany.trial_days_left
          ? BANNER_ALERT_LEVELS.WARN
          : BANNER_ALERT_LEVELS.ALARM,
      };
    }
    case BANNER_TYPES.INCOMPLETE_PROFILE: {
      return {
        label: "Incomplete Profile. Click here to complete user profile.",
        alertLevel: BANNER_ALERT_LEVELS.INFO,
      };
    }
    default: {
      return {
        label: "",
        alertLevel: BANNER_ALERT_LEVELS.INFO,
      };
    }
  }
};

export const useAppConfig = defineStore("app-config", () => {
  // const companyStore = useCompanies();
  // const { isCompanyAdmin } = storeToRefs(companyStore);
  const showBanner = ref(null);
  const appVersion = ref(process?.env?.VUE_APP_PACKAGE_VERSION);
  const giphyKey = ref(process?.env?.VUE_APP_GIPHY_KEY);
  const giphyFetch = ref(
    process?.env?.VUE_APP_GIPHY_KEY ? new GiphyFetch(process?.env?.VUE_APP_GIPHY_KEY) : null
  );

  const appBanner = reactive({
    type: "",
    label: "",
    alertLevel: BANNER_ALERT_LEVELS.INFO,
  });

  const appVersionBanner = (minAppVersion) => {
    if (!appVersion.value || !minAppVersion) {
      // We can't compare versions if there is not one
      return null;
    }

    const versionComparison = compareVersions(appVersion.value, minAppVersion);

    // if app version is an older version than
    // the min app version, show the app refresh banner
    return versionComparison < 0 ? BANNER_TYPES.APP_VERSION : null;
  };

  const incompleteProfileBanner = (user) => {
    if (!user) {
      return null;
    }

    return !user.name ? BANNER_TYPES.INCOMPLETE_PROFILE : null;
  };

  const subscriptionBanner = (selectedCompany) => {
    if (!selectedCompany || selectedCompany?.pivot.role !== "admin") {
      return null;
    }
    return !selectedCompany.is_subscribed ||
      selectedCompany.is_on_trial ||
      !selectedCompany.all_locations_subscribed
      ? BANNER_TYPES.SUBSCRIPTION_BANNER
      : null;
  };

  const setShowAppBanner = (user, selectedCompany, minAppVersion) => {
    // These are listed in order of priority
    const shouldShowBanners = [
      appVersionBanner(minAppVersion),
      incompleteProfileBanner(user),
      subscriptionBanner(selectedCompany),
    ].filter(Boolean);

    if (shouldShowBanners.length > 0) {
      appBanner.type = shouldShowBanners[0];
      const bannerDetails = determineAppBannerDetails(appBanner.type, selectedCompany);

      appBanner.label = bannerDetails.label;
      appBanner.alertLevel = bannerDetails.alertLevel;

      showBanner.value = true;
    } else {
      showBanner.value = false;
    }
  };

  return { appBanner, giphyFetch, giphyKey, setShowAppBanner, showBanner };
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useAppConfig, import.meta.hot));
}
