import { helpers, required, email } from "@vuelidate/validators";
import { replaceNonDigitChars } from "@/utils/helpers";

const isOnlyNumbers = (value) => !helpers.req(value) || /^\d+$/.test(replaceNonDigitChars(value));

export const isLength = (reqLength) => (value) =>
  !helpers.req(value) || helpers.len(value) === reqLength;

export const isPhoneNumber = helpers.withMessage(
  "Phone numbers must be 14 digits long",
  isLength(14)
);

export const isAuthCode = helpers.withMessage("The auth code must be 6 digits long", isLength(6));

export const isNumberString = helpers.withMessage(
  "Please use only numbers for this field",
  isOnlyNumbers
);

export const isRequired = helpers.withMessage("This field is required", required);

export const isEmail = helpers.withMessage("Please use a valid e-mail address", email);
