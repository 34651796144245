<template>
  <div
    class="
      translation-edit
      fixed
      w-full
      h-full
      top-0
      left-0
      flex flex-col
      justify-center
      items-center
      z-50
    "
  >
    <div
      class="underlay absolute top-0 bottom-0 left-0 right-0 bg-black bg-opacity-30"
      @click="clickOverlay"
    ></div>
    <div
      class="
        relative
        h-full
        w-full
        p-4
        overflow-auto
        flex flex-col
        items-center
        justify-center
        text-center
      "
    >
      <div class="modal relative w-1/4 bg-white p-4 shadow-xl">
        <div class="mb-4">Loading...</div>
        <div class="flex justify-center items-center mb-4">
          <div class="w-8 h-8 border-4 rounded-full border-blue border-b-0 animate-spin"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, onUnmounted } from "vue";
export default {
  name: "Loader",
  components: {},
  setup(_props, { emit }) {
    const clickOverlay = () => {
      emit("overlay");
    };

    const clickClose = () => {
      emit("close");
    };

    const clickConfirm = () => {
      emit("confirm");
    };

    onMounted(() => {
      document.body.classList.add("overflow-hidden");
    });

    onUnmounted(() => {
      document.body.classList.remove("overflow-hidden");
    });

    return {
      clickOverlay,
      clickClose,
      clickConfirm,
    };
  },
};
</script>
