<template>
  <article class="flex flex-col">
    <label
      v-if="label"
      :for="name"
      :class="{
        'invalid-text': errors && errors.length,
      }"
      class="mb-1 text-rocks text-sm"
      >{{ label }}</label
    >
    <div class="input-wrapper flex flex-row" :class="{ invalidinput: errors && errors.length > 0 }">
      <slot></slot>
    </div>
    <section v-if="errors && errors.length > 0" class="invalid-feedback invalid-text">
      <div v-for="error in errors" :key="error.$uid">
        <span>{{ error.$message }}</span>
      </div>
    </section>
  </article>
</template>

<script>
export default {
  name: "BaseInput",
  props: {
    errors: {
      type: Array,
      default: () => [],
    },
    label: {
      type: String,
    },
    name: {
      type: String,
      validator: (inputName) => inputName && inputName.length > 0,
    },
  },
  setup() {},
};
</script>

<style lang="postcss" scoped>
@layer components {
  .input-wrapper {
    @apply border border-gloomy border-solid;
  }

  .invalidinput {
    @apply border-elmo;
  }

  .invalid-feedback {
    display: block;
    font-size: 0.6875rem;
  }

  .invalid-text {
    @apply text-elmo;
  }
}
</style>
