import { make } from "@/utils/request";
import Status from "@/classes/Status";
import Memo from "@/classes/Memo";
import ResponseMemos from "@/classes/ResponseMemos";

const memoresponses = {
  namespaced: true,
  state: {
    status: new Status(),
    celebrations: [],
  },
  mutations: {
    SET_CELEBRATIONS(state, payload) {
      if (payload.length) {
        state.celebrations = payload.map((memo) => new Memo(memo));
      }
    },
  },
  actions: {
    async view({ rootGetters }, id) {
      const req = await make({
        name: "memoView",
        data: {
          company_id: rootGetters["companies/getCompany"].id,
          id,
        },
      });

      return req;
    },
    async responseGetAll({ commit, rootGetters }, location_id = null) {
      const response = await make({
        name: "memoResponseGetAll",
        params: {
          company_id: rootGetters["companies/getCompany"].id,
          location_id,
          ignore_admin: true,
        },
      });

      const responseMemo = new ResponseMemos(response);

      commit("SET_CELEBRATIONS", responseMemo.celebrations || []);

      return responseMemo;
    },
    async responseAcknowledge({ rootGetters }, id) {
      const req = await make({
        name: "acknowledgeMessage",
        data: {
          company_id: rootGetters["companies/getCompany"].id,
          id,
        },
      });

      return req;
    },
    async responseAcknowledgeSurvey({ rootGetters }, data) {
      const req = await make({
        name: "acknowledgeSurvey",
        data: {
          company_id: rootGetters["companies/getCompany"].id,
          ...data,
        },
      });

      return req;
    },
    async responseAcknowledgeCelebration({ rootGetters }, data) {
      const req = await make({
        name: "memoResponseCelebration",
        data: {
          company_id: rootGetters["companies/getCompany"].id,
          ...data,
        },
      });

      return req;
    },
    async responseAcknowledgeCelebrationSelf({ rootGetters }, id) {
      const req = await make({
        name: "acknowledgeCelebrationSelf",
        data: {
          company_id: rootGetters["companies/getCompany"].id,
          id,
        },
      });

      return req;
    },
    async responseAcknowledgeCheckin({ rootGetters }, data) {
      const req = await make({
        name: "acknowledgeCheckin",
        data: {
          company_id: rootGetters["companies/getCompany"].id,
          ...data,
        },
      });

      return req;
    },
    async responseFlagMemo({ rootGetters }, data) {
      const req = await make({
        name: "flagMessage",
        data: {
          company_id: rootGetters["companies/getCompany"].id,
          ...data,
        },
      });

      return req;
    },
  },
  getters: {
    isLoading(state) {
      return state.status.isLoading || state.status.isUpdating;
    },
    celebrations(state) {
      return state.celebrations;
    },
  },
};

export default memoresponses;
