import Echo from "laravel-echo";
import Pusher from "pusher-js";

const echoConnection = (token) => {
  const client = new Pusher(process.env.VUE_APP_WS_KEY, {
    cluster: process.env.VUE_APP_WS_CLUSTER,
    wsHost: process.env.VUE_APP_WS_HOST,
    wsPort: process.env.VUE_APP_WS_PORT ?? 6002,
    wssPort: process.env.VUE_APP_WS_PORT ?? 6002,
    forceTLS: process.env.VUE_APP_WS_DISABLE_TLS ? false : true,
    encrypted: process.env.VUE_APP_WS_ENCRYPTED ?? false,
    disableStats: true,
    enabledTransports: ["ws", "wss"], //process.env.VUE_APP_WS_DISABLE_TLS ? ["ws", "wss"] : ["wss"],
    authEndpoint: process.env.VUE_APP_WS_AUTH_URL ?? "/broadcasting/auth",
    auth: {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    },
  });

  return new Echo({
    broadcaster: "pusher",
    client,
  });
};

const websockets = {
  namespaced: true,
  state: {
    echo: null,
    channels: {},
  },
  mutations: {
    SET_ECHO(state, payload) {
      state.echo = payload;
    },
    ADD_CHANNEL(state, payload) {
      state.channels = { ...state.channels, [payload.name]: payload.data };
    },
    REMOVE_CHANNEL(state, payload) {
      let channels = { ...state.channels };
      delete channels[payload];
      state.channels = { ...channels };
    },
  },
  actions: {
    clear({ commit }) {
      commit("SET_ECHO", null);
    },
    async initialize({ commit, state, rootState }) {
      if (!state.echo) {
        const ec = echoConnection(rootState.auth.token);
        commit("SET_ECHO", ec);
      }
      return state.echo;
    },
    async connect({ dispatch, state }, { channel, event, listen }) {
      await dispatch("initialize")
        .then(() => {
          console.info(`connecting: ${channel} on ${event}`);
          state.echo.channel(channel).listen(event, listen);
        })
        .catch(console.error);

      return true;
    },
    async connectPrivate({ dispatch, state }, { channel, event, listen }) {
      await dispatch("initialize")
        .then(() => {
          console.info(`connecting privately: ${channel} on ${event}`);
          state.echo.private(channel).listen(event, listen);
        })
        .catch(console.error);

      return true;
    },
    async disconnect({ dispatch, state }, { channel }) {
      await dispatch("initialize")
        .then(() => {
          console.info(`disconnecting: ${channel}`);
          state.echo.leave(channel);
        })
        .catch(console.error);

      return true;
    },
  },
  getters: {},
};

export default websockets;
