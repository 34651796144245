class Location {
  constructor({
    id = null,
    name = null,
    created_at = null,
    timezone = "America/Chicago",
    deleted_at = null,
  } = {}) {
    this.name = name;
    this.timezone = timezone;

    // from server, not editable
    this.id = id;
    this.created_at = created_at;
    this.deleted_at = deleted_at;
  }
}

export default Location;
