import { get as lsGet, remove as lsRemove, set as lsSet } from "local-storage";
import { compareVersions } from "compare-versions";
import { clearAll } from "@/utils/cache";
import constants from "@/utils/constants";
import router from "@/router";
import Status from "@/classes/Status";

import { setAuthRequestHeader, ejectInterceptor, make } from "@/utils/request";

const tokenName = constants.AUTH_TOKEN_KEY;
const initToken = lsGet(tokenName);

const auth = {
  namespaced: true,
  state: {
    authInterceptor: initToken ? setAuthRequestHeader(initToken) : null,
    status: new Status(),
    token: lsGet(tokenName),
    user: null,
    currentEmployee: null,
    showAppRefreshBanner: false,
  },
  mutations: {
    SET_USER(state, payload) {
      state.user = payload;
    },
    SET_APP_REFRESH_BANNER(state, minAppVersion) {
      const appVersion = process?.env?.VUE_APP_PACKAGE_VERSION;

      if (!appVersion || !minAppVersion?.web) {
        // We can't compare versions if there is not one
        return null;
      }

      const versionComparison = compareVersions(appVersion, minAppVersion.web);

      // if app version is an older version than
      // the min app version, show the app refresh banner
      if (versionComparison < 0) {
        state.showAppRefreshBanner = true;
      }
    },
    SET_TOKEN(state, payload) {
      ejectInterceptor(state.authInterceptor);
      lsSet(tokenName, payload);
      state.authInterceptor = setAuthRequestHeader(payload);
      state.token = payload;
    },
    CLEAR_TOKEN(state) {
      ejectInterceptor(state.authInterceptor);
      lsRemove(tokenName);
      state.token = null;
    },
    CLEAR_USER(state) {
      state.user = null;
    },
    SET_STATUS(state, payload) {
      state.status.value = payload;
    },
  },
  actions: {
    setToken({ commit }, token) {
      commit("SET_TOKEN", token);
    },
    async preloadUserData({ dispatch, rootGetters, state }) {
      await dispatch("getUser");
      await dispatch("companies/getTeamInvites", state.user, { root: true });
      await dispatch("companies/fetch", null, { root: true });
      // await dispatch("companies/getSelfEmployeeData", state.user, { root: true });

      const currentCompany = rootGetters["companies/getCompany"];

      if (currentCompany?.value) {
        await dispatch(
          "websockets/connectPrivate",
          {
            channel: `chat.${currentCompany.id}`,
            event: "ChatMessageSent",
            listen: (evt) => dispatch("chat/websocketUpdate", evt),
          },
          { root: true }
        );

        await dispatch(
          "websockets/connectPrivate",
          {
            channel: `chat.${currentCompany.id}`,
            event: "TaskUpdated",
            listen: (evt) => dispatch("tasks/updateWebsocket", evt),
          },
          { root: true }
        );

        await dispatch(
          "tasks/list",
          {
            page: null,
            terms: null,
            filters: {
              completed_at: "default",
              locations: null,
              audiences: null,
            },
            sort_by: null,
            sort_direction: null,
          },
          { root: true }
        );
      }
    },
    async login({ commit }, { email, password, token }) {
      commit("SET_STATUS", Status.LOADING);
      const loginAttempt = await make({
        name: "login",
        data: {
          email,
          password,
          token,
        },
      });

      // if login successful, set the state of the token
      commit("SET_TOKEN", loginAttempt);
      commit("SET_STATUS", Status.LOADED);

      // don't return any response data - the state is handling that data
      return Promise.resolve("Successful Login");
    },

    async getAuthCode({ commit }, { phone }) {
      commit("SET_STATUS", Status.LOADING);
      await make({
        name: "getAuthToken",
        data: {
          phone,
        },
      });

      // don't return any response data - the state is handling that data
      return Promise.resolve("Successfully Sent Auth Code");
    },

    async loginCode({ commit }, { authCode, phone }) {
      commit("SET_STATUS", Status.LOADING);
      const loginAttempt = await make({
        name: "loginCode",
        data: {
          token: authCode,
          phone: phone,
        },
      });

      // if login successful, set the state of the token
      commit("SET_TOKEN", loginAttempt);
      commit("SET_STATUS", Status.LOADED);

      // don't return any response data - the state is handling that data
      return Promise.resolve("Successfully Sent Auth Code");
    },
    async getUser({ commit }) {
      const { userDetails, minAppVersion } = await make({ name: "getUserDetails" });

      commit("SET_USER", userDetails);
      commit("SET_APP_REFRESH_BANNER", minAppVersion);

      return userDetails;
    },
    async updateUser({ commit }, data) {
      const userDetails = await make({ name: "updateUserDetails", data });
      commit("SET_USER", userDetails);
      return userDetails;
    },
    async updateLocale({ commit }, default_locale) {
      const req = await make({
        name: "updateLocale",
        data: {
          default_locale,
        },
      });
      commit("SET_USER", req);
      return req;
    },
    async logout({ commit, dispatch }, { redirect, reason } = {}) {
      try {
        await make({ name: "logout" });
      } catch (e) {
        console.error("error logging out: ", e);
      }

      commit("CLEAR_TOKEN");
      await router.push({
        name: constants.LOGIN_ROUTE,
        query: { redirect, reason },
      });
      commit("CLEAR_USER");
      clearAll();

      dispatch("companies/clearCompanies", null, { root: true });

      return Promise.resolve("Logged out");
    },
    async signup(_context, data) {
      return await make({ name: "signup", data });
    },
    async verify(_context, data) {
      return await make({ name: "verify", data });
    },
  },
  getters: {
    isLoggedIn(state) {
      return !!state.token;
    },
    isLoading(state) {
      return state.status.isLoading || state.status.isUpdating;
    },
    getUser(state) {
      return state.user;
    },
    getDefaultEmployee(state) {
      return state.user?.default_employee || null;
    },
    getToken(state) {
      return state.token;
    },
    getLocale(state) {
      return state.user?.default_locale ?? "en";
    },
    getCompanyRole: (state) => {
      return state?.user?.default_employee?.role ? state.user.default_employee.role : null;
    },
    getCompanyIsAdmin: (_state, _getters, rootState) => {
      return rootState?.companies?.item?.pivot?.role === "admin";
    },
    getCompanyIsManagement: (_state, _getters, rootState) => {
      if (rootState?.companies?.item?.id) {
        return rootState?.companies?.item?.pivot?.role !== "employee";
      }

      return false;
    },
    allowed: (_state, getters) => (permission) => {
      if (permission === "tasks:assign_any") {
        return getters.getCompanyIsManagement;
      }
      return true;
    },
  },
};

export default auth;
