class CheckListItem {
  // name used to add/remove correctly
  name;

  // should return false by default, but true to stop navigation
  shouldBlock;

  constructor(name, shouldBlock) {
    this.name = name;
    this.shouldBlock = shouldBlock;
  }
}

const router = {
  namespaced: true,
  state: {
    checklist: [],
  },
  mutations: {
    ADD_CHECKLIST_ITEM(state, payload) {
      state.checklist = [...state.checklist, payload];
    },
    REMOVE_CHECKLIST_ITEM(state, payload) {
      state.checklist = [
        ...state.checklist.filter((c) => c.name !== payload && c.name !== payload?.name),
      ];
    },
  },
  actions: {
    addCheck({ commit }, { name, shouldBlock }) {
      commit("ADD_CHECKLIST_ITEM", new CheckListItem(name, shouldBlock));
    },
    removeCheck({ commit }, data) {
      commit("REMOVE_CHECKLIST_ITEM", data);
    },
  },
  getters: {
    getMessage(state) {
      return state.item;
    },
    shouldBlock: (state) => (to, from, next) => {
      let shouldBlockNav = false;

      state.checklist.forEach((ch) => {
        if (ch.shouldBlock(to, from, next)) {
          shouldBlockNav = true;
        }
      });

      return shouldBlockNav;
    },
  },
};

export default router;
