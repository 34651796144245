import Employee from "./Employee";
import { DateTime } from "luxon";
import { replaceDynamicMessageContent } from "@/utils/helpers";
class ChatMessage {
  constructor(data = {}) {
    this.id = parseInt(data?.id, 10);
    this.chat_group_id = data?.chat_group_id;
    this.employee_id = data?.employee_id;
    this.message_id = data?.message_id;
    this.survey_id = data?.survey_id;
    this.chat_message_id = data?.chat_message_id;
    this.image = data?.image ?? null;
    this.celebration_id = data?.celebration_id;
    this.created_at = data?.created_at ? DateTime.fromISO(data?.created_at) : null;
    this.updated_at = data?.updated_at;
    this.content = replaceDynamicMessageContent(data?.content);
    this.employee = data?.employee ? new Employee(data?.employee) : null;
    this.reactions = data?.reactions ?? [];
    this.receipts = data?.receipts ?? [];
    this.primary = data?.primary ? new ChatMessage(data.primary) : null;
    this.deleted_at = data?.deleted_at;
    this.createdAt = data?.created_at;
    this.pending = data.pending ?? false; // will be false when message is submitted, then true after
    this.sent = !!data?.created_at; // is true when the record is created in the DB
    this.received = false; // will use this with checking read receipts eventually
    this.user = this.employee;
  }
}

export default ChatMessage;
