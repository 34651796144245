import auth from "@/store/modules/auth";
import celebrations from "@/store/modules/celebrations";
import chat from "@/store/modules/chat";
import companies from "@/store/modules/companies";
import media from "@/store/modules/media";
import memos from "@/store/modules/memos";
import memoresponses from "@/store/modules/memo-responses";
import messages from "@/store/modules/messages";
import notifications from "@/store/modules/notifications";
import router from "@/store/modules/router";
import settings from "@/store/modules/settings";
import surveys from "@/store/modules/surveys";
import reports from "@/store/modules/reports";
import tags from "@/store/modules/tags";
import tasks from "@/store/modules/tasks";
import toasts from "@/store/modules/toasts";
import websockets from "@/store/modules/websockets";

export default {
  auth,
  celebrations,
  chat,
  companies,
  media,
  memos,
  memoresponses,
  messages,
  notifications,
  router,
  settings,
  surveys,
  reports,
  tags,
  tasks,
  toasts,
  websockets,
};
