import Memo from "./Memo";
import RaisedHand from "./RaisedHand";

class ResponseMemos {
  constructor({
    messages = [],
    surveys = [],
    memos = [],
    celebrations = [],
    checkin = null,
    raised_hand = null,

    // FOR MANAGERS
    raised_hands = [],
    checkins = [],
    flagged_memos = [],
  } = {}) {
    // 'memos' => $memos,
    // 'celebrations' => $celebrations,
    // 'checkin' => $checkin,
    // 'raised_hand' => $raised_hand,

    // // FOR MANAGERS
    // 'raised_hands' => $raised_hands,
    // 'checkins' => $checkins,
    // 'flagged_memos' => $flagged_memos,
    if (messages?.length > 0 || surveys?.length > 0) {
      // API IS FUBAR sending back different response bodys for acknowledgment and from initial fetch of memos to respond to
      memos = messages.concat(surveys);
    }

    this.memo_count = memos?.length;
    this.memos = memos?.length ? memos.map((m) => new Memo(m)) : [];
    this.celebrations = celebrations?.length ? celebrations.map((m) => new Memo(m)) : [];
    this.raised_hand = raised_hand?.id ? new RaisedHand(raised_hand) : null;
    this.checkin = checkin;

    // FOR MANAGERS
    this.checkins = checkins;
    this.raised_hands = raised_hands?.length ? raised_hands.map((m) => new RaisedHand(m)) : [];
    this.flagged_memos = flagged_memos?.length ? flagged_memos.map((m) => new Memo(m)) : [];
  }
}

export default ResponseMemos;
