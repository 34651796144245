import router from "@/router";

class Employee {
  constructor({
    id = null,
    name = null,
    name_first = null,
    name_last = null,
    name_short = null,
    created_at = null,
    has_logged_in_today = false,
    time_employed = null,
    flagged_messages = [],
    flagged_messages_active = [],
    checkins = [],
    checkins_active = [],
    raised_hands = [],
    raised_hands_active = [],
    locations = [],
    managers = [],
    employees = [],
    audiences = [],
    metrics = [],
    birth_date_day = null,
    birth_date_month = null,
    birth_date_year = null,
    work_anniversary = null,
    pin = null,
    company_ext_id = null,
    cfa_person_id = null,
    last_synced_at=null,
    is_active = true,
    default_locale = "en",
    default_locale_label = "English",
    phone = null,
    phone_country_code = 1,
    email = null,
    role = "employee",
    manager_requests = [],
    manager_requests_today = [],
    manager_request_active = null,
    is_awaiting_manager = false,
    is_awaiting_employee = false,
    is_urgent = false,
    due_next = false,
    type = null,
    features = {},
    navTarget = null,
    backTarget = null,
    current_checkin = null,
    last_checkin = null,
    user_id = null,
    last_invited_at = null,
    invite_accepted_at = null,
    is_management = false,
    display_name = null,
    unread_memo_count = 0,
    unread_memo_message_count = 0,
    unread_memo_survey_count = 0,
    unread_memo_celebration_count = 0,
    unread_memo_non_celebration_count = 0,
    unread_memo_non_celebration_past_due_count = 0,
    unread_memos_list = [],
    read_memos_list = [],
  } = {}) {
    this.name_first = name_first;
    this.name_last = name_last;
    this.name_short = name_short;
    this.birth_date_day = birth_date_day;
    this.birth_date_month = birth_date_month;
    this.birth_date_year = birth_date_year;
    this.work_anniversary = work_anniversary;
    this.pin = pin;
    this.company_ext_id = company_ext_id;
    this.cfa_person_id = cfa_person_id;
    this.last_synced_at = last_synced_at;
    this.is_active = is_active;
    this.default_locale = default_locale;
    this.phone = phone;
    this.phone_country_code = phone_country_code;
    this.email = email;
    this.password = null;
    this.role = role;
    this.is_management = !!is_management;
    this.type = type;
    this.features = features;

    // from server, not editable
    this.id = id;
    this.name = name;
    this.created_at = created_at;
    this.time_employed = time_employed;
    this.flagged_messages = flagged_messages;
    this.flagged_messages_active = flagged_messages_active;
    this.checkins = checkins;
    this.checkins_active = checkins_active;
    this.employees = employees;
    this.managers = managers;
    this.locations = locations;
    this.audiences = audiences;
    this.manager_requests = manager_requests;
    this.manager_requests_today = manager_requests_today;
    this.manager_request_active = manager_request_active;
    this.raised_hands = raised_hands;
    this.raised_hands_active = raised_hands_active;
    this.has_logged_in_today = has_logged_in_today;
    this.is_awaiting_manager = is_awaiting_manager;
    this.is_awaiting_employee = is_awaiting_employee;
    this.is_urgent = is_urgent;
    this.due_next = due_next;
    this.default_locale_label = default_locale_label;
    this.current_checkin = current_checkin;
    this.last_checkin = last_checkin;
    this.user_id = user_id;
    this.last_invited_at = last_invited_at;
    this.invite_accepted_at = invite_accepted_at;
    this.display_name = display_name;
    this.metrics = metrics;

    this.unread_memo_count = unread_memo_count;
    this.unread_memo_message_count = unread_memo_message_count;
    this.unread_memo_survey_count = unread_memo_survey_count;
    this.unread_memo_celebration_count = unread_memo_celebration_count;
    this.unread_memo_non_celebration_count = unread_memo_non_celebration_count;
    this.unread_memo_non_celebration_past_due_count = unread_memo_non_celebration_past_due_count;

    this.unread_memos_list = unread_memos_list;
    this.read_memos_list = read_memos_list;

    // parsed from data passed in
    this.audience_ids = this.audiences.map((a) => a.id);
    this.location_ids = this.locations.map((a) => a.id);
    this.manager_ids = this.managers.map((a) => a.id);
    this.employee_ids = this.employees.map((a) => a.id);
    this.navigation = () => {
      if (navTarget?.name) {
        router.push(navTarget);
      } else {
        router.push({ name: "EmployeeDetail", params: { id: this.id } });
      }
    };
    this.backTarget = backTarget;
    this.navigationBack = () => {
      if (backTarget?.name) {
        router.push(backTarget);
      } else {
        router.push({ name: "FlaggedMessageList", params: { id: this.id } });
      }
    };
    this.has_flag = !!this.flagged_messages_active?.length;
    this.has_checkin = !!this.checkins_active?.length;
    this.has_raised_hand = !!this.raised_hands_active?.length;
  }
}

export default Employee;
