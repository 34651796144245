import { make } from "@/utils/request";
import Status from "@/classes/Status";

const tags = {
  namespaced: true,
  state: {
    status: new Status(),
    list: [],
  },
  mutations: {
    SET_TAGS(state, payload) {
      state.list = payload;
    },
    SET_STATUS(state, payload) {
      state.status.value = payload;
    },
  },
  actions: {
    async list({ commit, rootGetters }, data) {
      commit("SET_STATUS", Status.LOADING);

      const req = await make({
        name: "tagList",
        params: {
          company_id: rootGetters["companies/getCompany"].id,
          ...data,
        },
      });

      commit("SET_TAGS", req);
      commit("SET_STATUS", Status.LOADED);

      return req;
    },
    async get({ rootGetters }, id) {
      const req = await make({
        name: "tagGet",
        params: {
          company_id: rootGetters["companies/getCompany"].id,
          id,
        },
      });

      return req;
    },
    async create({ rootGetters, dispatch }, data) {
      const req = await make({
        name: "tagCreate",
        data: {
          company_id: rootGetters["companies/getCompany"].id,
          ...data,
        },
      });

      await dispatch("list");

      return req;
    },
    async update({ rootGetters }, data) {
      const req = await make({
        name: "tagUpdate",
        data: {
          company_id: rootGetters["companies/getCompany"].id,
          ...data,
        },
      });

      return req;
    },
    async delete({ rootGetters }, id) {
      const req = await make({
        name: "tagDelete",
        data: {
          company_id: rootGetters["companies/getCompany"].id,
          id,
        },
      });

      return req;
    },
  },
  getters: {
    isLoading(state) {
      return state.status.isLoading || state.status.isUpdating;
    },
    optionsList(state) {
      return state.list?.length
        ? state.list.map((t) => {
            return {
              value: t.id,
              label: t.title,
            };
          })
        : [];
    },
  },
};

export default tags;
