class Audience {
  constructor({ id = null, name = null, created_at = null, deleted_at = null } = {}) {
    this.name = name;

    // from server, not editable
    this.id = id;
    this.created_at = created_at;
    this.deleted_at = deleted_at;
  }
}

export default Audience;
