export const req = (value) => {
  if (Array.isArray(value)) return !!value.length;
  if (value === undefined || value === null) {
    return false;
  }

  if (value === false) {
    return true;
  }

  if (value instanceof Date) {
    // invalid date won't pass
    return !Number.isNaN(value.getTime());
  }

  return !!String(value).length;
};

/**
 * Returns the length of an arbitrary value
 * @param {Array|Object|String} value
 * @return {number}
 */
export const len = (value) => {
  if (Array.isArray(value)) return value.length;
  if (typeof value === "object") {
    return Object.keys(value).length;
  }
  return String(value).length;
};

/**
 * Regex based validator template
 * @param {RegExp} expr
 * @return {function(*=): boolean}
 */
export const regex = (expr) => (value) => !req(value) || expr.test(value);

export function isFunction(val) {
  return typeof val === "function";
}

export function isObject(o) {
  return o !== null && typeof o === "object" && !Array.isArray(o);
}

/**
 * Returns whether a value is truthy ot not.
 * @param {Function|*} prop
 * @return {boolean}
 */
export function isTruthy(prop) {
  return Boolean(isFunction(prop) ? prop() : prop);
}

export function isPromise(object) {
  return isObject(object) && isFunction(object.then);
}
