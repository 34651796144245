<template>
  <DashboardLayout>
    <div class="container pt-8 pb-24 mx-auto px-4">
      <div class="flex-col md:flex">
        <div class="w-full md:w-1/2">
          <template v-if="companies.length">
            <div class="font-bold mb-8">Select a Team</div>
            <div class="grid grid-cols-2 gap-4">
              <div v-for="(company, c) in companies" :key="c" class="mb-4">
                <router-link
                  :to="{ name: 'Home', params: { company_id: company.id } }"
                  class="btn btn-primary"
                >
                  {{ company.name }}
                </router-link>
              </div>
            </div>
          </template>
          <template v-else>
            <div class="font-bold mb-8">You don't belong to any teams yet</div>
          </template>
        </div>
        <div class="w-full md:w-1/2" v-if="invites?.length">
          <div class="font-bold mb-8">Pending Team Invitations</div>

          <pending-invitations :team-invites="invites" @accept="refresh" @ignore="refresh" />
        </div>
      </div>
      <div class="mt-6 text-center">
        <router-link :to="{ name: 'CreateTeam' }" class="link">Create Team</router-link>
      </div>
      <div class="mt-6 text-center">
        <router-link :to="{ name: 'JoinTeam' }" class="link">Join a Team by Code</router-link>
      </div>
    </div>
  </DashboardLayout>
  <Loader v-if="loading" />
  <RedirectIfNotAuthedContext />
</template>

<script>
import companies from "@/store/modules/companies";
import { useAuth } from "@/stores/auth";
import { useCompanies } from "@/stores/companies";
import { onMounted, ref } from "vue";
import Loader from "@/components/Loader";
import PendingInvitations from "@/components/PendingInvitations.vue";
import { useRouter } from "vue-router";
import PushkinLogo from "@/components/PushkinLogo.vue";
import DashboardLayout from "@/components/Layouts/DashboardLayout.vue";
import RedirectIfNotAuthedContext from "@/components/Auth/RedirectIfNotAuthedContext.vue";

export default {
  name: "Dashboard",
  components: {
    Loader,
    PendingInvitations,
    PushkinLogo,
    DashboardLayout,
    RedirectIfNotAuthedContext,
  },
  props: {
    show: {
      type: [Boolean, String],
      default: false,
    },
  },
  setup(props) {
    const authStore = useAuth();
    const companyStore = useCompanies();
    const router = useRouter();

    const version = process?.env?.VUE_APP_PACKAGE_VERSION
      ? `v${process.env.VUE_APP_PACKAGE_VERSION}`
      : "UNKNOWN VERSION";

    const companies = ref([]);
    const invites = ref([]);

    const loading = ref(false);

    const refresh = async () => {
      loading.value = true;
      let userData = await authStore.fetchUser();
      companies.value = userData.companies;

      // also check user invitations
      let companyInvites = await authStore.fetchCompanyInvites();
      invites.value = companyInvites;

      // if there is only 1 team and there are no invitations, redirect to that team
      if (!props.show && companies.value?.length === 1 && invites.value?.length === 0) {
        router.push({ name: "Home", params: { company_id: companies.value[0].id } });
      }

      loading.value = false;
    };

    onMounted(async () => {
      await companyStore.reset();
      await refresh();
    });

    return {
      companies,
      invites,
      loading,
      refresh,
    };
  },
};
</script>
