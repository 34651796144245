<script setup>
import { onMounted } from "vue";
import { useAuth } from "@/stores/auth";
import { useRouter } from "vue-router";

const router = useRouter();
const authStore = useAuth();

// if user is logged in, redirect to selection screen
onMounted(async () => {
  let userdata = await authStore.fetchUser();

  if (!userdata?.id) {
    router.push({ name: "Login" });
  }
});
</script>

<template>
  <div></div>
</template>
