import { ref, reactive } from "vue";
import { isEqual } from "lodash-es";
import { defineStore, acceptHMRUpdate, storeToRefs } from "pinia";
import { make } from "@/utils/request";
import Form from "@/classes/Form";
import FormTemplate from "@/classes/FormTemplate";
import FormResponse from "@/classes/FormResponse";
import { useCompanies } from "./companies";
import { useCompanyEmployees } from "./companies-employees";

const filterListsHaveChanged = (newList, oldList) => {
  // filters have only changed if both lists have values
  // otherwises refetches are handled elsewhere
  return newList?.length || !isEqual(newList, oldList);
};

export const useForms = defineStore("forms", () => {
  const companyStore = useCompanies();
  const employeesStore = useCompanyEmployees();

  const { selectedCompany } = storeToRefs(companyStore);
  const { selectedEmployee } = storeToRefs(employeesStore);

  const templates = ref([]);

  const fetchPageParams = reactive({
    currentPage: null,
    totalPages: null,
    sortBy: null,
    sortDirection: null,
    terms: null,
  });

  const fetchFilters = reactive({
    audiences: [],
    locations: [],
  });

  const fetchForm = async (formId) => {
    const refreshedForm = await make({
      name: "formGet",
      params: {
        company_id: selectedCompany.value.id,
        form_id: formId,
      },
    });

    const form = new Form(refreshedForm);

    return form;
  };

  const fetchFormTemplates = async () => {
    const response = await make({
      name: "formTemplateList",
      params: {
        company_id: selectedCompany.value.id,
      },
    });

    if (response?.form_templates) {
      templates.value = response.form_templates.map((template) => new FormTemplate(template));

      fetchPageParams.currentPage = response.form_templates.current_page;
      fetchPageParams.totalPages = response.form_templates.total_pages;
      fetchPageParams.sortBy = response.form_templates.sort_by;
      fetchPageParams.sortDirection = response.form_templates.sort_direction;

      // Setting these retrigger another fetch, that doesn't maintain the filter
      // fetchFilters.completedAt = response.filters?.completed_at;
      // fetchFilters.locations = response.filters?.locations.map((location) => location.id);
      // fetchFilters.audiences = response.filters?.audiences.map((audience) => audience.id);
    }

    return response.data;
  };

  const createForm = async (formData) => {
    const createdForm = await make({
      name: "formCreate",
      data: {
        company_id: selectedCompany.value.id,
        ...formData,
      },
    });

    const form = new Form(createdForm);

    return form;
  };

  const getFormResponse = async (formResponseData) => {
    const formRes = await make({
      name: "formResponseGet",
      data: {
        company_id: selectedCompany.value.id,
        ...formResponseData,
      },
    });

    const formResponse = new FormResponse(formRes);

    return formResponse;
  };

  const createFormResponse = async (formResponseData) => {
    const createdFormResponse = await make({
      name: "formResponseCreate",
      data: {
        company_id: selectedCompany.value.id,
        ...formResponseData,
      },
    });

    const formResponse = new FormResponse(createdFormResponse);

    return formResponse;
  };

  const updateFormResponse = async (formResponseId, formResponseData) => {
    const updatedFormResponse = await make({
      name: "formResponseUpdate",
      data: {
        company_id: selectedCompany.value.id,
        id: formResponseId,
        ...formResponseData,
      },
    });

    const formResponse = new FormResponse(updatedFormResponse);

    return formResponse;
  };

  const uploadFileFormResponse = async (file, formResponseId) => {
    const uploadedFile = await make({
      name: "formResponseUpload",
      data: {
        company_id: selectedCompany.value.id,
        id: formResponseId,
        file: file,
      },
    });

    return uploadedFile;
  };

  const emailFormResponse = async (email, formResponseId) => {
    const emailSentResponse = await make({
      name: "formResponseEmail",
      data: {
        company_id: selectedCompany.value.id,
        form_response_id: formResponseId,
        email: email,
      },
    });

    return emailSentResponse;
  };

  const resetFetchParams = () => {
    fetchPageParams.currentPage = null;
    fetchPageParams.totalPages = null;
    fetchPageParams.sortBy = null;
    fetchPageParams.sortDirection = null;
    fetchPageParams.terms = null;
  };

  const resetFetchFilters = () => {
    fetchFilters.completedAt = "default";
    fetchFilters.locations = [];
    fetchFilters.audiences = [];
  };

  const reset = () => {
    templates.value = [];
    resetFetchParams();
    resetFetchFilters();
  };

  return {
    emailFormResponse,
    getFormResponse,
    fetchPageParams,
    fetchFilters,
    fetchForm,
    fetchFormTemplates,
    createForm,
    createFormResponse,
    updateFormResponse,
    templates,
    uploadFileFormResponse,
    reset,
  };
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useForms, import.meta.hot));
}
