import { make } from "@/utils/request";

const reports = {
  namespaced: true,
  state: {},
  mutations: {},
  actions: {
    async getReport({ rootGetters }, { name, data }) {
      const req = await make({
        name,
        data: {
          company_id: rootGetters["companies/getCompany"].id,
          ...data,
        },
        options: {
          responseType: "blob",
        },
      });

      return req;
    },
  },
  getters: {},
};

export default reports;
