<template>
  <div class="w-screen h-screen bg-gray overflow-auto flex flex-col items-center justify-center">
    <div class="flex flex-col w-full justify-center items-center p-4">
      <div class="login-box p-8 shadow bg-white block h-auto">
        <div class="flex justify-center items-center mb-4 text-rocks">
          <PushkinLogo />
          <div class="ml-3 font-bold text-2xl text-center text-gray-500 border-l-2 pl-3">Login</div>
        </div>
        <div class="flex justify-center items-center flex-cols relative">
          <span
            class="tab p-2"
            :class="{
              'selected-tab': tab === 'phone',
              'unselected-tab underline': tab !== 'phone',
            }"
            @click.stop="toggleTab"
          >
            Phone
          </span>
          <span
            class="tab p-2"
            :class="{
              'selected-tab': tab !== 'phone',
              'unselected-tab underline': tab === 'phone',
            }"
            @click.stop="toggleTab"
          >
            Email / Password
          </span>
        </div>
        <form v-if="showVerifyAuthCode" class="form py-4" @submit.prevent="loginCode">
          <text-input
            v-model="v$.authCode.$model"
            label="6-digit Auth Code"
            name="authCode"
            type="auth-code"
            :errors="v$.authCode.$errors"
            :disabled="isAuthenticating"
          />
          <div class="flex flex-row justify-center mt-8">
            <Btn
              type="submit"
              :disabled="isAuthenticating"
              :loading="isAuthenticating"
              text="Submit"
              variant="primary"
            />
          </div>
        </form>
        <form v-else-if="tab === 'phone'" class="form py-4" @submit.prevent="sendAuthCodeSMS">
          <text-input
            v-model="v$.phone.$model"
            label="Phone Number"
            name="phone"
            placeholder="(555)-123-4567"
            type="tel"
            :errors="v$.phone.$errors"
            :disabled="awaitingAuthCode"
          />
          <div class="flex flex-row justify-center mt-8">
            <Btn
              type="submit"
              :disabled="awaitingAuthCode"
              :loading="awaitingAuthCode"
              text="Submit"
              variant="primary"
            />
          </div>
        </form>
        <form v-else class="form py-4" @submit.prevent="loginPassword">
          <text-input
            v-model="v$.email.$model"
            label="Email"
            name="email"
            placeholder="jane@pushkin.com"
            type="text"
            class="mb-3"
            :errors="v$.email.$errors"
            :disabled="isAuthenticating"
          />
          <text-input
            v-model="v$.password.$model"
            label="Password"
            name="password"
            type="password"
            :errors="v$.password.$errors"
            :disabled="isAuthenticating"
          />
          <div class="flex flex-row justify-center mt-8">
            <Btn
              type="submit"
              :disabled="isAuthenticating"
              :loading="isAuthenticating"
              text="Submit"
              variant="primary"
            />
          </div>
        </form>
        <div class="mt-4 text-center text-gray-400 italic">
          Forgot your password?<br />
          <router-link :to="{ name: 'RequestPasswordReset' }" class="text-blue underline">
            Click here to reset your password
          </router-link>
        </div>
        <div class="mt-4 text-center text-gray-400 italic">
          Don't have an account yet?<br />
          <router-link :to="{ name: 'Signup' }" class="text-blue underline">
            Click here to get started!
          </router-link>
        </div>
      </div>
    </div>
  </div>
  <RedirectIfAuthedContext />
</template>

<script>
import { reactive, ref, computed } from "vue";
import {
  phoneValidation,
  emailValidation,
  authCodeValidation,
} from "@/utils/validation/forms/login";
import { useVuelidate } from "@vuelidate/core";
import Btn from "@/components/Btn";
import PushkinLogo from "@/components/PushkinLogo";
import TextInput from "@/components/Form/TextInput";
import { useAuth } from "@/stores/auth";
import { storeToRefs } from "pinia";
import RedirectIfAuthedContext from "@/components/Auth/RedirectIfAuthedContext.vue";
import { useToasts } from "@/stores/toasts";

export default {
  name: "Login",
  components: {
    Btn,
    PushkinLogo,
    TextInput,
    RedirectIfAuthedContext,
  },
  props: {
    redirect: String,
    query: String,
    reason: String,
    token: String,
  },
  setup(props) {
    const toastsStore = useToasts();
    const { addToast } = toastsStore;
    const authStore = useAuth();

    const { isAuthenticating } = storeToRefs(authStore);

    const { sendAuthCode, loginWithAuthCode, loginWithUsername } = authStore;

    const tab = ref("phone");
    const showVerifyAuthCode = ref(false);
    const awaitingAuthCode = ref(false);

    const fields = reactive({
      email: null,
      password: null,
      phone: null,
      authCode: null,
    });

    const validationRules = computed(() => {
      if (tab.value === "phone") {
        return showVerifyAuthCode.value ? authCodeValidation : phoneValidation;
      }

      return emailValidation;
    });

    const v$ = useVuelidate(validationRules, fields);

    const loginOptions = {
      redirectURI: props.redirect || null,
      query: props.query || null,
      fieldValidation: async () => await v$.value.$validate(),
    };

    const sendAuthCodeSMS = async () => {
      awaitingAuthCode.value = true;
      const formIsValid = await v$.value.$validate();

      if (!formIsValid) {
        // There's an issue with field validation,
        // so don't try to login
        return null;
      }

      await sendAuthCode(fields.phone);

      awaitingAuthCode.value = false;
      showVerifyAuthCode.value = true;
    };

    const toggleTab = () => {
      showVerifyAuthCode.value = false;

      if (tab.value === "phone") {
        tab.value = "email";
      } else {
        tab.value = "phone";
      }
    };

    const loginCode = () => {
      loginWithAuthCode(
        { authCode: fields.authCode, phoneNumber: fields.phone },
        loginOptions
      ).catch((err) => {
        addToast({
          title: "Error logging in",
          message: err,
          variant: "error",
          timeout: 7000,
        });
      });
    };

    const loginPassword = () => {
      loginWithUsername({ email: fields.email, password: fields.password }, loginOptions).catch(
        (err) => {
          addToast({
            title: "Error logging in",
            message: err,
            variant: "error",
            timeout: 7000,
          });
        }
      );
    };

    return {
      awaitingAuthCode,
      fields,
      isAuthenticating,
      loginOptions,
      loginWithAuthCode,
      loginWithUsername,
      sendAuthCodeSMS,
      showVerifyAuthCode,
      tab,
      toggleTab,
      v$,
      loginPassword,
      loginCode,
    };
  },
};
</script>

<style lang="postcss">
.login-box {
  width: 100%;
  max-width: 400px;
}

.form {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  border-top-style: solid;
  border-top-width: 1px;
  border-top-color: gray-400;
  width: 100%;
}

.tab {
  top: 1px;
  position: relative;
}

.unselected-tab {
  background-color: white;
  border-bottom-width: 1px;
  border-bottom-color: gray-400;
  color: gray;
}

.selected-tab {
  background-color: white;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  border-color: gray-400;
  border-style: solid;
  border-width: 1px;
  border-bottom: none;
  color: gray;
}
</style>
