import { DateTime } from "luxon";
import Employee from "./Employee";

class TaskComment {
  constructor({
    content = null,
    created_at = null,
    created_by = null,
    creator = null,
    deleted_at = null,
    employee = null,
    employee_id = null,
    id = null,
    image = null,
    image_path = null,
    replied_to = null,
    task_comment_id = null,
    task_id = null,
    updated_at = null,
    updated_by = null,
    updater = null,
  } = {}) {
    this.content = content;
    this.created_at = created_at ? DateTime.fromISO(created_at) : null;
    this.created_by = created_by;
    this.creator = creator?.id ? new Employee(creator) : null;
    this.deleted_at = deleted_at ? DateTime.fromISO(deleted_at) : null;
    this.employee = employee?.id ? new Employee(employee) : null;
    this.employee_id = employee_id;
    this.id = id;
    this.image = image;
    this.image_path = image_path;
    this.replied_to = replied_to?.id ? new TaskComment(replied_to) : null;
    this.task_comment_id = task_comment_id;
    this.task_id = task_id;
    this.updated_at = updated_at ? DateTime.fromISO(updated_at) : null;
    this.updated_by = updated_by;
    this.updater = updater?.id ? new Employee(updater) : null;
  }
}

export default TaskComment;
