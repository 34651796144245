import { get as lsGet, remove as lsRemove, set as lsSet } from "local-storage";

const cacheName = "pushkin-admin-cache";

const defaultExpiration = 120000; // 2 minutes

export const createKey = (prefix, params = {}) => `${prefix}:${JSON.stringify(params)}`;

export const getListKeys = (key) => {
  return Object.keys(lsGet(cacheName) || {}).filter((cacheKey) => cacheKey.startsWith(key));
};

export const clearListCache = (key) => {
  getListKeys(key).forEach((cacheKey) => clear(cacheKey));
};

export const set = (key, data, expiration = defaultExpiration) => {
  const cache = lsGet(cacheName) || {};
  cache[key] = {
    data,
    timestamp: new Date().getTime(),
    expiration,
  };
  lsSet(cacheName, cache);
};

export const clear = (key) => {
  const cache = lsGet(cacheName);
  if (cache[key] !== undefined) {
    delete cache[key];
  }
  lsSet(cacheName, cache);
};

export const clearAll = () => lsRemove(cacheName);

export const get = async (
  key,
  fetchingFunction,
  params,
  expiration = defaultExpiration,
  forceUpdate = false
) => {
  const cache = lsGet(cacheName);
  if (cache && cache[key] && !forceUpdate) {
    const prop = cache[key];
    const now = new Date();
    const dif = Math.abs(now.getTime() - prop.timestamp);

    if (prop.data && dif < prop.expiration) {
      return prop.data;
    }
  }

  const res = await fetchingFunction(...params);
  set(key, res, expiration);
  return res;
};
