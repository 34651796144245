import { ref, reactive } from "vue";
import { defineStore, acceptHMRUpdate, storeToRefs } from "pinia";
import { make } from "@/utils/request";
import ResponseMemos from "@/classes/ResponseMemos";
import Memo from "@/classes/Memo";
import { useCompanies } from "./companies";

const createCelebrationOptions = (companyOptions, celebration) => {
  if (!companyOptions) {
    return [];
  }

  const emojiOptions = Object.keys(celebration?.celebration_options ?? [])
    .concat(companyOptions)
    .sort()
    .filter((a) => a);
  const uniqueEmoji = new Set(emojiOptions);

  return Array.from(uniqueEmoji).reduce(
    (options, option) => ({
      ...options,
      [option]: celebration?.celebration_options[option] ?? 0,
    }),
    {}
  );
};

export const useMemosResponse = defineStore("memos-response", () => {
  const companyStore = useCompanies();

  const { selectedCompany } = storeToRefs(companyStore);

  const memos = ref([]);

  // TO DO: re-enable this so that there can be an indicator dot in nav
  const unreadMemos = ref(0);

  // RESPONDING TO MEMOS
  const fetchMemoResponses = async (locationId = null) => {
    const responses = await make({
      name: "memoResponseGetAll",
      params: {
        company_id: selectedCompany?.value?.id,
        location_id: locationId,
        ignore_admin: true,
      },
    });
    const formattedResponses = new ResponseMemos(responses);

    formattedResponses.celebrations = formattedResponses?.celebrations?.map((celebration) => {
      celebration.celebration_options = createCelebrationOptions(
        selectedCompany.value?.celebration_options,
        celebration
      );
      return celebration;
    });

    return formattedResponses;
  };

  const fetchMemoResponsesWithPage = async (locationId = null) => {
    const responses = await make({
      name: "memoResponseGetAll",
      params: {
        company_id: selectedCompany?.value?.id,
        location_id: locationId,
        ignore_admin: true,
        per_page: 1,
      },
    });
    const formattedResponses = new ResponseMemos(responses);

    formattedResponses.celebrations = formattedResponses?.celebrations?.map((celebration) => {
      celebration.celebration_options = createCelebrationOptions(
        selectedCompany.value?.celebration_options,
        celebration
      );
      return celebration;
    });

    return formattedResponses;
  };

  const acknowledgeMemo = async (memoId) => {
    const updatedMemos = await make({
      name: "acknowledgeMessage",
      data: {
        company_id: selectedCompany?.value?.id,
        id: memoId,
      },
    });
    const formattedResponses = new ResponseMemos(updatedMemos);

    return formattedResponses.memos;
  };

  const flagMemo = async (memoDetails) => {
    const updatedMemos = await make({
      name: "flagMessage",
      data: {
        company_id: selectedCompany.value.id,
        ...memoDetails,
      },
    });

    const formattedResponses = new ResponseMemos(updatedMemos);

    return formattedResponses.memos;
  };

  const acknowledgeSurvey = async (ackDetails) => {
    const updatedMemos = await make({
      name: "acknowledgeSurvey",
      data: {
        company_id: selectedCompany.value.id,
        ...ackDetails,
      },
    });
    const formattedResponses = new ResponseMemos(updatedMemos);

    return formattedResponses.memos;
  };

  const acknowledgeCelebration = async (ackDetails) => {
    const updatedCelebration = await make({
      name: "memoResponseCelebration",
      data: {
        company_id: selectedCompany.value.id,
        ...ackDetails,
      },
    });

    return updatedCelebration;
  };

  const acknowledgeOwnCelebration = async (celebrationId) => {
    const updatedCelebration = await make({
      name: "acknowledgeCelebrationSelf",
      data: {
        company_id: selectedCompany.value.id,
        id: celebrationId,
      },
    });

    return updatedCelebration;
  };

  const uploadSignature = async (signInfo) => {
    const signature = await make({
      name: "uploadSignature",
      data: {
        company_id: selectedCompany.value.id,
        ...signInfo,
      },
    });

    return signature;
  };



  return {
    acknowledgeCelebration,
    acknowledgeMemo,
    acknowledgeOwnCelebration,
    acknowledgeSurvey,
    fetchMemoResponses,
    fetchMemoResponsesWithPage,
    flagMemo,
    unreadMemos,
    uploadSignature,
  };
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useMemosResponse, import.meta.hot));
}
