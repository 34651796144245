import Echo from "laravel-echo";
import Pusher from "pusher-js";
import { ref, watch, onBeforeUnmount } from "vue";
import { defineStore, acceptHMRUpdate, storeToRefs } from "pinia";

import { useCompanies } from "./companies";
import { useChat } from "./chat";
import { useTasks } from "./tasks";
import { useAuth } from "@/stores/auth";

const createClient = (token) => {
  const client = new Pusher(process.env.VUE_APP_WS_KEY, {
    cluster: process.env.VUE_APP_WS_CLUSTER,
    wsHost: process.env.VUE_APP_WS_HOST,
    wsPort: process.env.VUE_APP_WS_PORT ?? 443,
    wssPort: process.env.VUE_APP_WS_PORT ?? 443,
    forceTLS: process.env.VUE_APP_WS_DISABLE_TLS ? false : true,
    encrypted: process.env.VUE_APP_WS_ENCRYPTED ?? false,
    disableStats: true,
    enabledTransports: ["ws", "wss"], //process.env.VUE_APP_WS_DISABLE_TLS ? ["ws", "wss"] : ["wss"],
    authEndpoint: process.env.VUE_APP_WS_AUTH_URL ?? "/broadcasting/auth",
    auth: {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    },
  });

  return new Echo({
    broadcaster: "pusher",
    client,
  });
};

export const useWebsockets = defineStore("websockets", () => {
  const companyStore = useCompanies();
  const chatStore = useChat();
  const tasksStore = useTasks();
  const authStore = useAuth();

  const { selectedCompany } = storeToRefs(companyStore);
  const { authToken } = storeToRefs(authStore);

  const client = ref(null);

  const initializeClient = async (token) => {
    if (!client.value) {
      client.value = await createClient(token);
    }
  };

  const connectWebsocket = async (channel, event, listen) => {
    if (client.value) {
      console.info(`connecting: ${channel} on ${event}`);
      client.value.private(channel).listen(event, listen);
    }
  };

  const disconnectWebsocket = async (channel) => {
    if (client.value) {
      console.info(`disconnecting: ${channel}`);
      client.value.leave(channel);
    }
  };

  const disconnectClient = async () => {
    if (client.value) {
      await client.value.disconnect();
      client.value = null;
    }
  };

  const initializeWebsockets = async (companyId) => {
    if (!client.value) {
      await initializeClient(authToken.value);
    }

    const chatMessageListener = (evt) => chatStore.sendUpdateNotifications(evt);
    const tasksListener = (evt) => tasksStore.sendUpdateNotifications(evt);

    await connectWebsocket(`chat.${companyId}`, "ChatMessageSent", chatMessageListener);
    await connectWebsocket(`chat.${companyId}`, "TaskUpdated", tasksListener);
  };

  // watch(selectedCompany, async (newCompany, oldCompany) => {
  //   if (oldCompany && oldCompany.id) {
  //     await disconnectWebsocket(oldCompany.id);
  //   }

  //   if (newCompany && newCompany?.id) {
  //     await initializeWebsockets(newCompany.id);
  //   }
  // });

  // onBeforeUnmount(async () => {
  //   if (client.value) {
  //     await disconnectClient();
  //   }
  // });

  return { connectWebsocket, disconnectWebsocket, initializeWebsockets };
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useWebsockets, import.meta.hot));
}
