import { ref, reactive } from "vue";
import { isEqual } from "lodash-es";
import { defineStore, acceptHMRUpdate, storeToRefs } from "pinia";
import { make, makeDownload } from "@/utils/request";
import { replaceItemInStore } from "@/utils/helpers";
import File from "@/classes/File";
import { useCompanies } from "./companies";
import { useCompanyEmployees } from "./companies-employees";

const filterListsHaveChanged = (newList, oldList) => {
  // filters have only changed if both lists have values
  // otherwises refetches are handled elsewhere
  return newList?.length || !isEqual(newList, oldList);
};

export const useFiles = defineStore("files", () => {
  const companyStore = useCompanies();
  const employeesStore = useCompanyEmployees();
  // const notificationsStore = useNotifications();
  // const toastsStore = useToasts();

  const { selectedCompany } = storeToRefs(companyStore);
  const { selectedEmployee } = storeToRefs(employeesStore);

  // const { addNotification } = notificationsStore;
  // const { addToast } = toastsStore;

  const files = ref([]);
  // const websocketTask = ref(null);

  const fetchPageParams = reactive({
    currentPage: null,
    totalPages: null,
    sortBy: null,
    sortDirection: null,
    terms: null,
  });

  const fetchFilters = reactive({
    audiences: [],
    locations: [],
  });

  const fetchFile = async (fileId) => {
    const refreshedFile = await make({
      name: "getMediaFile",
      params: {
        company_id: selectedCompany.value.id,
        media_id: fileId,
      },
    });

    const file = new File(refreshedFile.data);

    replaceItemInStore(file, files.value);

    return file;
  };

  const uploadFile = async (fileData) => {
    const newFile = await make({
      name: "uploadMedia",
      data: {
        company_id: selectedCompany.value.id,
        ...fileData,
      },
    });
    const file = new File(newFile.data);
    files.value = [file, ...files.value]; // push new item to the top of the list

    return file;
  };

  const downloadFile = async (file) => {
    const response = await makeDownload({
      name: "downloadMedia",
      data: {
        company_id: selectedCompany.value.id,
      },
      file: file,
    });
    return response;
  };

  const fetchFiles = async (page) => {
    // const pageParams = {
    //   filters: {
    //     audiences:
    //       fetchFilters.audiences.length === 0
    //         ? fetchFilters.audiences
    //         : fetchFilters.audiences.map((audiencedId) => ({ id: audiencedId, selected: true })),
    //     completed_at: fetchFilters.completedAt,
    //     locations:
    //       fetchFilters.locations.length === 0
    //         ? fetchFilters.locations
    //         : fetchFilters.locations.map((locationId) => ({ id: locationId, selected: true })),
    //   },
    //   page: fetchPageParams.currentPage,
    //   sort_by: fetchPageParams.sortBy,
    //   sort_direction: fetchPageParams.sortDirection,
    //   term: fetchPageParams.terms,
    // };

    const response = await make({
      name: "getMediaList",
      data: {
        company_id: selectedCompany.value.id,
        page: page,
      },
    });

    // if files comes back and is empty the filtered list should be empty
    if (response?.data) {
      files.value = response.data.media.map((file) => new File(file));

      fetchPageParams.currentPage = response.data.current_page;
      fetchPageParams.totalPages = response.data.total_pages;
      fetchPageParams.sortBy = response.data.sort_by;
      fetchPageParams.sortDirection = response.data.sort_direction;

      // Setting these retrigger another fetch, that doesn't maintain the filter
      // fetchFilters.completedAt = response.filters?.completed_at;
      // fetchFilters.locations = response.filters?.locations.map((location) => location.id);
      // fetchFilters.audiences = response.filters?.audiences.map((audience) => audience.id);
    }

    return response.data;
  };

  const updateFile = async (fileData) => {
    const updatedFile = await make({
      name: "updateMedia",
      data: {
        company_id: selectedCompany.value.id,
        ...fileData,
      },
    });

    const file = new File(updatedFile.data);

    replaceItemInStore(file, files.value);

    return file;
  };

  const deleteFile = async (fileData) => {
    const response = await make({
      name: "deleteMedia",
      data: {
        company_id: selectedCompany.value.id,
        ...fileData,
      },
    });

    files.value = files.value.filter((file) => file.id !== fileData.id);

    return response;
  };

  const resetFetchParams = () => {
    fetchPageParams.currentPage = null;
    fetchPageParams.totalPages = null;
    fetchPageParams.sortBy = null;
    fetchPageParams.sortDirection = null;
    fetchPageParams.terms = null;
  };

  const resetFetchFilters = () => {
    fetchFilters.completedAt = "default";
    fetchFilters.locations = [];
    fetchFilters.audiences = [];
  };

  const reset = () => {
    files.value = [];
    resetFetchParams();
    resetFetchFilters();
  };

  return {
    deleteFile,
    downloadFile,
    fetchPageParams,
    fetchFilters,
    fetchFile,
    fetchFiles,
    files,
    updateFile,
    uploadFile,
    reset,
  };
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useFiles, import.meta.hot));
}
