import { DateTime } from "luxon";
import Employee from "./Employee";
// import Memo from "./Memo";

class EmployeeMemo {
  constructor({
    acknowledged_at = null,
    acknowledgement = null,
    acknowledgement_data = [],
    admin_notify_path = null,
    created_at = null,
    employee = null,
    employee_id = null,
    flag_data = null,
    flag_meta = null,
    flagged_at = null,
    flagged_count = null,
    id = null,
    is_awaiting_employee = false,
    is_awaiting_manager = false,
    is_urgent = false,
    manager = null,
    manager_acknowledged_at = null,
    manager_id = null,
    manager_note = null,
    memo_id,
    message_data = null,
    survey_data = null,
    time_ago = null,
    updated_at = null,
  } = {}) {
    this.acknowledged_at = acknowledged_at ? DateTime.fromISO(acknowledged_at) : null;
    this.acknowledgement = acknowledgement;
    this.acknowledgement_data = acknowledgement_data;
    this.admin_notify_path = admin_notify_path;
    this.created_at = created_at ? DateTime.fromISO(created_at) : null;
    this.employee = employee?.id ? new Employee(employee) : null;
    this.employee_id = employee_id;
    this.flag_data = flag_data;
    this.flag_meta = flag_meta;
    this.flagged_at = flagged_at ? DateTime.fromISO(flagged_at) : null;
    this.flagged_count = flagged_count;
    this.id = id;
    this.is_awaiting_employee = is_awaiting_employee;
    this.is_awaiting_manager = is_awaiting_manager;
    this.is_urgent = is_urgent;
    this.manager = manager?.id ? new Employee(manager) : null;
    this.manager_acknowledged_at = manager_acknowledged_at
      ? DateTime.fromISO(manager_acknowledged_at)
      : null;
    this.manager_id = manager_id;
    this.manager_note = manager_note;
    // this.memo =
    //   memo?.id ?? message?.id ?? survey?.id ?? celebration?.id
    //     ? new Memo(memo ?? message ?? survey ?? celebration)
    //     : null;
    this.memo_id = memo_id;
    this.message_data = message_data;
    this.survey_data = survey_data;
    this.time_ago = time_ago;
    this.updated_at = updated_at ? DateTime.fromISO(updated_at) : null;
  }
}

export default EmployeeMemo;
